<template>
  <div>
    <Navbar />
    <main>
      <section class="container pb-5">
        <h1 class="mt-5 mb-3">Enable Banking brand guideline</h1>
        <h2>Colors</h2>
        <div class="brand-elements-colors-table">
          <div class="brand-color-black text-white">Black #000</div>
          <div class="brand-color-content text-white">Content #333</div>
          <div class="brand-color-background text-white">Background #132821</div>
          <div class="brand-color-primary text-white">Primary #18503b</div>
          <div class="brand-color-brown text-white">Brown #59574b</div>
          <div class="brand-color-muted text-white">Muted #666</div>

          <div class="brand-color-teal">Teal #6acdb9</div>
          <div class="brand-color-pale">Pale #c6dfd9</div>
          <div class="brand-color-gold">Gold #baa566</div>
          <div class="brand-color-success">Green #8ac340</div>
          <div class="brand-color-highlight">Purple #b73fbb</div>
          <div class="brand-color-white">White #fff</div>
        </div>
        <iframe
          style="border: 1px solid rgba(0, 0, 0, 0.1)"
          width="100%"
          height="450"
          src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2Fo4fPA7ChMaACnCnwzG4HRJ%2FAssets%3Fnode-id%3D5%253A143"
          allowfullscreen
          class="mt-3"
        ></iframe>
        <h2 class="mt-5">Logo</h2>
        <iframe
          style="border: 1px solid rgba(0, 0, 0, 0.1)"
          width="100%"
          height="450"
          src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2Fo4fPA7ChMaACnCnwzG4HRJ%2FAssets%3Fnode-id%3D0%253A1"
          allowfullscreen
        ></iframe>
        <h1 class="mt-5 mb-3">Web elements</h1>
        <h2>Buttons</h2>
        <div class="brand-elements-table">
          <div class="brand-elements list p-4">
            <h3>Light background</h3>
            <button class="btn btn-primary">Primary button</button>
            <button class="btn btn-outline-primary">Secondary button</button>
            <button class="btn btn-light">Secondary button</button>
            <button class="btn btn-link text-primary">Secondary button</button>
            <button class="btn btn-danger">Danger button</button>
            <button class="btn btn-outline-danger">Danger button</button>
          </div>
          <div class="brand-elements list bg-dark p-4">
            <h3 class="text-white">Dark background</h3>
            <button class="btn btn-light">Primary button</button>
            <button class="btn btn-outline-light">Secondary button</button>
            <button class="btn btn-link text-white">Secondary button</button>
            <button class="btn btn-danger">Danger button</button>
            <button class="btn btn-outline-danger">Danger button</button>
          </div>
          <div class="brand-elements list brand-colors--green with-bg p-4">
            <h3 class="text-white">Brand background</h3>
            <button class="btn btn-light">Primary button</button>
            <button class="btn btn-outline-light">Secondary button</button>
            <button class="btn btn-link text-white">Secondary button</button>
          </div>
        </div>
        <h2 class="mt-5">Forms</h2>
        <div class="brand-elements-table">
          <form class="brand-elements p-4">
            <h3>Light background</h3>
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">Email address</label>
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
              <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
            </div>
            <div class="mb-3">
              <label for="exampleInputPassword1" class="form-label">Password</label>
              <input type="password" class="form-control" id="exampleInputPassword1" />
            </div>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="exampleCheck1" />
              <label class="form-check-label" for="exampleCheck1">Check me out</label>
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
            <button class="btn btn-link">Cancel</button>
          </form>
          <form class="brand-elements bg-dark text-white p-4">
            <h3 class="text-white">Dark background</h3>
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">Email address</label>
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
              <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
            </div>
            <div class="mb-3">
              <label for="exampleInputPassword1" class="form-label">Password</label>
              <input type="password" class="form-control" id="exampleInputPassword1" />
            </div>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="exampleCheck1" />
              <label class="form-check-label" for="exampleCheck1">Check me out</label>
            </div>
            <button type="submit" class="btn btn-outline-light">Submit</button>
            <button class="btn btn-link text-white">Cancel</button>
          </form>
          <form class="brand-elements brand-colors--green with-bg p-4">
            <h3 class="text-white">Brand background</h3>
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">Email address</label>
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
              <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
            </div>
            <div class="mb-3">
              <label for="exampleInputPassword1" class="form-label">Password</label>
              <input type="password" class="form-control" id="exampleInputPassword1" />
            </div>
            <div class="mb-3 form-check">
              <input type="checkbox" class="form-check-input" id="exampleCheck1" />
              <label class="form-check-label" for="exampleCheck1">Check me out</label>
            </div>
            <button type="submit" class="btn btn-outline-light">Submit</button>
            <button class="btn btn-link text-white">Cancel</button>
          </form>
        </div>
      </section>
    </main>
    <AsideFooter />
  </div>
</template>

<script>
import AsideFooter from '../components/AsideFooter.vue'
import Navbar from '../components/Navbar.vue'
import metadata from '../mixins/metadata.js'

export default {
  name: 'brand-guidelines',
  mixins: [metadata],
  components: {
    AsideFooter,
    Navbar
  }
}
</script>
