<template>
  <div>
    <Navbar getStarted="#get-started" />
    <main>
      <div class="bg-light">
        <article class="container main-section">
          <div class="row align-items-center mb-4">
            <div class="col-12 col-lg-5 col-xl-5">
              <h1 class="pt-4">Enable Banking Accounts API</h1>
              <p>Get instant access to bank account transactions and balances &ndash; we deliver account information where you need it.</p>
              <a class="btn btn-primary mr-1" v-if="!user" href="#get-started">Get started</a>
              <button class="btn btn-outline-dark" @click="showContactForm">Contact us</button>
            </div>
            <div class="col-12 col-lg-7 col-xl-7 text-right">
              <img
                src="../assets/accounts-api.png"
                class="main-section__image"
                alt
                height="430"
              />
            </div>
          </div>
        </article>
      </div>

      <section class="container py-3 py-md-5">
        <div class="row">
          <div class="col-sm-6 col-md-3">
            <div class="feature">
              <img src="../assets/circle.svg" alt class="feature__pic mb-3" />
              <h6 class="mb-3">Unified authentication and harmonized data</h6>
              <p v-if="false">One simple flow regardless of a bank</p>
            </div>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="feature">
              <img src="../assets/cloud.svg" alt class="feature__pic mb-3" />
              <h6 class="mb-3">Scalable cloud<br>infrastructure</h6>
              <p v-if="false">No matter how many users you are going to have, we are ready</p>
            </div>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="feature">
              <img src="../assets/security.svg" alt class="feature__pic mb-3" />
              <h6 class="mb-3">Secure and compliant<br>solution</h6>
              <p v-if="false">ISO/IEC 27001 certified and regulated by FIN-FSA</p>
            </div>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="feature">
              <img src="../assets/piggy-bank.svg" alt class="feature__pic mb-3" />
              <h6 class="mb-3">Simple pay-as-you-go<br>pricing</h6>
              <p v-if="false">Start as low as 100 connected accounts</p>
            </div>
          </div>
        </div>
      </section>

      <section class="container py-3 py-md-5" id="open-banking-apis">
        <div>
          <h4 class="mb-3 title-pic">Open Banking APIs</h4>
          <p>
            We have integrated Open Banking APIs of all major banks and other payment account providers (EMIs and PIs) across EEA region.
            We constantly monitor the integrated APIs and test interaction process. Also we follow announced changes, so we can timely update integrations.
          </p>
          <MainApis />
        </div>
      </section>

      <div class="bg-light"><section class="container py-3 py-md-5" id="linked-accounts">
        <div>
          <h4 class="mb-3 title-pic">Free access to linked bank accounts</h4>
          <div class="row">
            <div class="col-12 col-md-7 col-lg-7 align-self-center">
              <p>
                Register your application and link bank accounts it. For each linked account we calculate identification hash and allow free access to these accounts.
              </p>
              <router-link to="/sign-in" class="btn btn-primary mr-1 mb-3">Sign up →</router-link>
            </div>
            <div class="col-12 col-md-5 col-lg-5">
              <a href="#get-started"><img src="../assets/linked-accounts.png" class="linked-accounts-pic float-left" /></a>
            </div>
          </div>
        </div>
      </section></div>

      <section class="container py-3 py-md-5" id="examples">
        <h4 class="mb-3 title-pic">Examples</h4>
        <p>
          We believe that integrating Open Banking should be easy and painless, so we put a lot of effort into making
          our APIs developer-friendly. You don’t need to worry about difference of bank APIs, we automatically
          convert all data and interaction logic to one simple interface.
        </p>
        <ApiSamples />
        <a href="/docs/api/latest/" class="lead">continue to the API docs →</a>
      </section>

      <section class="container pt-4 mb-5" id="get-started" v-if="!user">
        <h4 class="mb-3 title-pic">Getting started</h4>
        <div class="rounded p-4 bg-light">
          <h5 class="mb-4">Create an account</h5>
          <MainGetStarted />
        </div>
      </section>

      <section class="container pt-4 pb-5 mb-5" id="production">
        <h4 class="mb-3 title-pic">
          Got questions?
          <span class="gradient-text">Contact us</span> →
          <a href="mailto:info@enablebanking.com">info@enablebanking.com</a>
        </h4>
        <div>
          <p>Should you have any questions regarding testing or operating integration with live APIs?</p>
          <p class="mb-4">
            We are happy to assist whether you have a legal question, are looking for a business case or concerned about
            a security matter.
          </p>
          <button class="btn btn-primary" @click="showContactForm">Contact us</button>
          <b-modal
            ref="contactModalRef"
            size="lg"
            hide-footer
            title="Get in touch with us!"
            id="contact"
            v-on:is-form-submitted="isFormSubmitted = $event"
          >
            <p class="mb-5" v-if="!isFormSubmitted">
              Please fill in the form with details about yourself, your business and your needs.
              <br />We're happy to hear from you and we'll get back to you soon.
            </p>
            <MainContact ref="contactFormRef" />
          </b-modal>
        </div>
      </section>
    </main>
    <!-- <MainChat />  -->
    <AsideFooter />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BModal } from 'bootstrap-vue/src/components/modal'
import ApiSamples from '../components/ApiSamples.vue'
import AsideFooter from '../components/AsideFooter.vue'
import MainApis from '../components/Main/MainApis.vue'
import MainContact from '../components/Main/MainContact.vue'
import MainGetStarted from '../components/Main/MainGetStarted.vue'
import Navbar from '../components/Navbar.vue'
import metadata from '../mixins/metadata.js'

export default {
  name: 'tilisy-api',
  data() {
    return {
      isFormSubmitted: false
    }
  },
  mixins: [metadata],
  methods: {
    showContactForm() {
      this.isFormSubmitted = false
      this.$refs.contactModalRef.show()
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  components: {
    ApiSamples,
    AsideFooter,
    BModal,
    MainApis,
    MainContact,
    MainGetStarted,
    Navbar
  }
}
</script>

<style lang="scss" scoped>
  .linked-accounts-pic {
    width: 100%;
    -webkit-box-shadow: 0 3px 6px rgba(14, 98, 102, 0.3), 0 -1px 0px rgba(14, 98, 102, 0.05);
    box-shadow: 0 3px 6px rgba(14, 98, 102, 0.3), 0 -1px 0px rgba(14, 98, 102, 0.05);
  }
</style>
