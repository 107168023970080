<template>
  <div>
    <span class="badge badge-info mr-1 mb-1">ASPSP requests</span>
    <span v-if="fetching" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    <div v-else class="overflow-auto parent-container">
      <div v-if="logs.length > 0">
        <table class="table table-hover small">
          <tbody>
            <template v-for="(item, index) in logs">
              <tr :class="{ 'pointer-cursor': item.response_body }" @click='if (item.response_body) { toggle(index) }'>
                <td :class="{ 'text-danger': item.response_code >= 400 }" style="width: 10%;">
                  {{ item.response_code || reponseKindsMap(item.kind) }}
                </td>
                <td style="width: 7.5%;">{{ item.method || '' }}</td>
                <td style="width: 15%;">{{ item.request_timestamp ? item.request_timestamp.toLocaleString() :
                  item.timestamp.toLocaleString() }}</td>
                <td style="width: 7.5%;">{{ item.latency ? `${item.latency} ms` : 'n/a' }}</td>
                <td :colspan="item.response_body ? 1 : 2" style="word-break: break-all; max-width: 200px;">
                  {{ item.url || item.app_name }}
                </td>
                <td v-if="item.response_body" style="width: 5%;">
                  <font-awesome-icon
                    :icon="expanded.includes(index) ? 'fa-solid fa-angle-down' : 'fa-solid fa-angle-right'"
                    class="expand-icon" />
                </td>
              </tr>
              <tr v-if="expanded.includes(index) && item.response_body" class="no-hover">
                <td colspan="6">
                  <div v-if="item.response_body">{{ item.response_body }}</div>
                  <div v-else>Empty response body</div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  data() {
    return {
      fetching: false,
      logs: [],
      expanded: [],
    }
  },
  computed: {
    ...mapGetters(['idToken'])
  },
  props: {
    requestId: {
      type: String
    },
    requestTimestamp: {
      type: String
    }
  },
  components: {},
  async mounted() {
    await this.fetchLogs()
  },
  methods: {
    ...mapActions(['pushAlertMessage']),
    toggle(index) {
      const i = this.expanded.indexOf(index)
      if (i >= 0) {
        this.expanded.splice(i, 1)
      } else {
        this.expanded.push(index)
      }
    },
    reponseKindsMap(reponseKind) {
      switch (reponseKind) {
        case 'redirect_to_application':
          return 'Redirect to Application';

        // Add other cases as needed
        case 'redirect_from_application':
          return 'Redirect from Application';

        case 'redirect_to_aspsp':
          return 'Redirect to ASPSP';

        case 'redirect_from_aspsp':
          return 'Redirect from ASPSP';

        default:
          return 'Unknown'; // Handle the case where reponseKind doesn't match any of the defined cases.
      }
    },
    async fetchLogs() {
      try {
        this.fetching = true;
        const res = await axios({
          method: 'get',
          url: `/api/requestLogs?requestId=${this.requestId}&timestamp=${this.requestTimestamp}`,
          headers: {
            Authorization: 'Bearer ' + this.idToken.token
          }
        });
        let data = res.data.logs;
        let obj;

        for (let i = 0; i < data.length; i++) {
          const logEntry = data[i];

          if (logEntry.kind == 'request') {   // combine request and response into one object
            obj = {
              method: logEntry.method,
              url: logEntry.url,
              request_timestamp: new Date(logEntry.timestamp)
            };
          } else if (logEntry.kind == 'response') {
            if (!obj) {
              // Skip processing response if there's no associated request
              continue;
            }
            Object.assign(obj, {
              latency: new Date(logEntry.timestamp) - obj.request_timestamp,
              kind: 'request_response',
              response_code: logEntry.code,
              response_body: logEntry.body
            });
            this.logs.push(obj);
            obj = null;
          } else {  // other kinds
            this.logs.push({
              kind: logEntry.kind,
              timestamp: new Date(logEntry.timestamp),
              url: logEntry.url
            });
          }
        }
      } catch (e) {
        this.pushAlertMessage({ message: 'Unable to fetch ASPSP logs' });
      } finally {
        this.fetching = false;
      }
    }

  }

}
</script>
<style>
.no-hover {
  background-color: transparent !important;
}

.expand-icon {
  color: red;
}

.pointer-cursor {
  cursor: pointer;
}
</style>
