<template>
  <div class="overflow-auto">
    <table class="table table-hover">
      <thead>
        <tr>
          <th style="width: 7.5%">Code</th>
          <th style="width: 17.5%">Date/time</th>
          <th style="width: 7.5%">Kind</th>
          <th style="width: 32.5%">Endpoint</th>
          <th style="width: 7.5%">Country</th>
          <th style="width: 27.5%">ASPSP</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(r, index) in requests">
          <tr :key="`${r.requestId}-${r.logs_id}-${index}-request`" @click="toggle(`${r.requestId}-${r.logs_id}-${index}`)" class="table-row" style="cursor: pointer;">
            <td v-if=r.request_id :class="{ 'text-danger': parseInt(r.response_code) >= 400 }">{{ r.response_code }}</td>
            <td v-else></td>
            <td>{{ `${new Date(r.timestamp.value).toLocaleString()}` }}</td>
            <td>{{ r.endpoint | endpoint('kind') }}</td>
            <td>{{ r.endpoint | endpoint }}</td>
            <td>{{ r.country }}</td>
            <td>{{ r.brand }}</td>
          </tr>
          <tr
            class="no-hover"
            :key="`${r.requestId}-${r.logs_id}-${index}-expanded`"
            v-if="expanded.includes(`${r.requestId}-${r.logs_id}-${index}`)"
          >
            <td colspan="6">
              <dl class="row" v-if=r.request_id>
                <dt class="col-2"><span class="badge badge-info mr-1 mb-1">Request ID</span></dt>
                <dd class="col-4">{{ r.request_id }}</dd>
                <dt class="col-2"><span class="badge badge-info mr-1 mb-1">Timestamp</span></dt>
                <dd class="col-4">{{ r.timestamp.value }}</dd>
              </dl>
              <dl class="row" v-if="r.brand">
                <dt class="col-2"><span class="badge badge-info mr-1 mb-1">ASPSP country and brand</span></dt>
                <dd class="col-4">{{ r.country }} {{ r.brand }}</dd>
                <dt class="col-2"><span class="badge badge-info mr-1 mb-1">PSU type</span></dt>
                <dd class="col-4">{{ r.psu_type }}</dd>
              </dl>
              <dl class="row" v-if="r.payment_id">
                  <dt class="col-2"><span class="badge badge-info mr-1 mb-1">Payment ID</span></dt>
                  <dd class="col-4">{{ r.payment_id }}</dd>
                  <dt class="col-2"><span class="badge badge-info mr-1 mb-1">Payment status</span></dt>
                  <dd class="col-4">{{ r.payment_status }}</dd>
              </dl>
              <dl class="row" v-if="r.payment_type">
                <dt class="col-2"><span class="badge badge-info mr-1 mb-1">Payment Type</span></dt>
                <dd class="col-4">{{ r.payment_type }}</dd>
              </dl>
              <dl class="row" v-if="r.session_id">
                  <dt class="col-2"><span class="badge badge-info mr-1 mb-1">Session ID</span></dt>
                  <dd class="col-4">{{ r.session_id }}</dd>
                  <dt class="col-2"><span class="badge badge-info mr-1 mb-1">Session status</span></dt>
                  <dd class="col-4">{{ r.session_status }}</dd>
              </dl>
              <dl class="row" v-if="r.authorization_id">
                <dt class="col-2"><span class="badge badge-info mr-1 mb-1">Authorization ID</span></dt>
                <dd class="col-4">{{ r.authorization_id }}</dd>
              </dl>
              <dl v-if="r.account_id" class="row">
                <dt class="col-2" ><span class="badge badge-info mr-1 mb-1">Account ID</span></dt>
                <dd class="col-4" >{{ r.account_id }}</dd>

                <dt class="col-2" ><span class="badge badge-info mr-1 mb-1">Account Hash</span></dt>
                <dd class="col-4 text-break" >{{ r.account_hash }}</dd>
              </dl>
              <dl v-for="(account_id, index) in r.account_ids" :key="account_id" class="row">
                <dt class="col-2" ><span class="badge badge-info mr-1 mb-1">Account ID</span></dt>
                <dd class="col-4" >{{ r.account_ids[index] }}</dd>

                <dt class="col-2" ><span class="badge badge-info mr-1 mb-1">Account Hash</span></dt>
                <dd class="col-4 text-break" >{{ r.account_hashes[index] }}</dd>
              </dl>
              <RequestsDetail v-if="r.logs_id" :requestId=r.logs_id :requestTimestamp=r.timestamp.value> </RequestsDetail>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import RequestsDetail from './RequestsDetail.vue'

const endpoints = {
  psu_actions: {
    kind: 'PSU',
    method: null,
    path: null,
    title: 'Authorisation flow'
  },
  application: {
    kind: 'API',
    method: 'GET',
    path: '/application',
    title: 'Get application'
  },
  aspsps: {
    kind: 'API',
    method: 'GET',
    path: '/aspsps',
    title: 'Get list of ASPSPs'
  },
  auth: {
    kind: 'API',
    method: 'POST',
    path: '/auth',
    title: 'Start user authorization'
  },
  auth_redirect: {
    kind: 'PSU',
    method: 'GET',
    path: null,
    title: 'Returning to auth callback'
  },
  details: {
    kind: 'API',
    method: 'GET',
    path: '/account/{}/details',
    title: 'Get account details'
  },
  balances: {
    kind: 'API',
    method: 'GET',
    path: '/account/{}/balances',
    title: 'Get account balances'
  },
  create_payment: {
    kind: 'API',
    method: 'POST',
    path: '/payments',
    title: 'Create payment'
  },
  delete_session: {
    kind: 'API',
    method: 'DELETE',
    path: '/sessions/{}',
    title: 'Delete session'
  },
  get_payment: {
    kind: 'API',
    method: 'GET',
    path: '/payments/{}',
    title: 'Get payment'
  },
  get_payment_background: {
    kind: 'APP',
    method: 'GET',
    path: null,
    title: 'Background payment fetching'
  },
  get_session: {
    kind: 'API',
    method: 'GET',
    path: '/sessions/{}',
    title: 'Get session data'
  },
  payment_redirect: {
    kind: 'PSU',
    method: 'GET',
    path: null,
    title: 'Returning to payment callback'
  },
  payment_webhook: {
    kind: 'APP',
    method: 'POST',
    path: null,
    title: 'Payment status webhook'
  },
  sessions: {
    kind: 'API',
    method: 'POST',
    path: '/sessions',
    title: 'Authorize user session'
  },
  transaction: {
    kind: 'API',
    method: 'GET',
    path: '/account/{}/transactions/{}',
    title: 'Get transaction details'
  },
  transactions: {
    kind: 'API',
    method: 'GET',
    path: '/account/{}/transactions',
    title: 'Get account transactions'
  }
}

export default {
  data() {
    return {
      expanded: []
    }
  },
  props: {
    requests: {
      type: Array
    }
  },
  components: {RequestsDetail},
  methods: {
    ...mapActions(['pushAlertMessage']),
    toggle(index) {
      console.log(this.expanded)
      const i = this.expanded.indexOf(index)
      if (i >= 0) {
        this.expanded.splice(i, 1)
      } else {
        this.expanded.push(index)
      }
    }
  },
  filters: {
    endpoint(e, prop) {
      const ed = endpoints[e]
      if (!ed) {
        return e
      }
      if (prop) {
        return ed ? ed[prop] : undefined
      }
      return ed.method && ed.path ? `${ed.method} ${ed.path}` : ed.title
    }
  }
}
</script>

<style lang="scss">
.table-hover tbody tr.no-hover:hover {
  background-color: transparent;
}
</style>


