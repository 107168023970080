<template>
  <div>
    <form class="form-inline flex-nowrap mb-5" @submit.prevent="submit" @reset.prevent="reset">
      <select class="form-control form-control-sm mr-1" v-model="dateSelection"  style="width: 13%">
        <option value="currentMonth">Current Month</option>
        <option value="lastMonth">Last Month</option>
        <option value="custom">Custom</option>
      </select>
      <flat-pickr
        v-model="i.dateFrom"
        :config="dateFromFlatpickrConfig"
        class="form-control form-control-sm mr-1 custom-flatpickr"
        placeholder="Date from"
        :disabled="dateSelection !== 'custom'"
      />
      <flat-pickr
        v-model="i.dateTo"
        :config="dateToFlatpickrConfig"
        class="form-control form-control-sm mr-1 custom-flatpickr"
        placeholder="Date to"
        :disabled="dateSelection !== 'custom'"
      />
      <select class="form-control form-control-sm mr-1" v-model="i.psuType" style="width: 10%">
        <option value="">PSU Type</option>
        <option value="personal">Personal</option>
        <option value="business">Business</option>
      </select>
      <select class="form-control form-control-sm mr-1" v-model="i.country"
            style="width: 10%"
            v-on:change="i.brand = ''"
            >
            <option value disabled selected>Country</option>
            <option
              v-for="country in Object.keys(countries).sort((a, b) => countriesList[a].localeCompare(countriesList[b]))"
              :value="country" :key="`country-${country}`">{{ countriesList[country] }}</option>
      </select>
      <select class="form-control form-control-sm mr-1" :disabled="!i.country" v-model="i.brand"
            style="width: 10%"
            >
            <option value disabled selected>ASPSP</option>
            <option v-for="brand in countries[i.country]" :value="brand.name"
              :key="`aspsp-${i.country}-${brand.name.replace(/\s+/g, '-')}`">{{ brand.name }}</option>
      </select>
      <input type="submit" class="btn btn-sm btn-primary form-control form-control-sm mr-1" value="Apply" :disabled="!canSubmit" />
      <input type="reset" class="btn btn-sm btn-link flex-shrink-1 form-control form-control-sm" value="Clear all" :disabled="!canReset" />
    </form>
  </div>
</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  data() {
    return {
      dateTo: null,
      dateFrom: null,
      dateFromFlatpickrConfig: {
        maxDate: 'today',
        enableTime: false,
        enableSeconds: false,
        time_24hr: true
      },
      dateSelection: 'currentMonth',
      i: {
        dateTo: '',
        dateFrom: '',
        country: '',
        brand: '',
        psuType: ''
      },
      d: {
        dateTo: '',
        dateFrom: '',
        country: '',
        brand: '',
        psuType: ''
      }
    }
  },
  props: ['countries'],
  components: {
    flatPickr
  },
  computed: {
    canSubmit() {
      if (this.isInitialState) {
        return false
      }
      if ((this.i.dateFrom === null || this.i.dateTo === null) && this.dateSelection === 'custom') {
        return false
      }
      for (const [k, v] of Object.entries(this.i)) {
        if (this.d[k] !== v) {
          return true
        }
      }
      return false
    },
    canReset() {
      if (this.isInitialState) {
        return false
      }
      for (const [k, v] of Object.entries(this.i)) {
        if (this.d[k] !== v || v !== '') {
          return true
        }
      }
      return false
    },
    isInitialState() {
      for (const [k, v] of Object.entries(this.i)) {
        if (v !== '') {
          if (k === 'dateFrom' || k === 'dateTo') {
            if (v === this.getCurrentMonth[k]) {
              continue
            }
          }
          return false
        }
      }
      return true
    },
    dateToFlatpickrConfig() {
      return {
        maxDate: 'today',
        minDate: this.dateFrom,
        enableTime: false,
        enableSeconds: false,
        time_24hr: true
      }
    },
    getCurrentMonth() {
      const now = new Date()
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1)
      const currentDate = new Date()
      currentDate.setHours(0, 0, 0, 0)
      return {
        dateFrom: startOfMonth.toISOString(),
        dateTo: currentDate.toISOString()
      }
    },
    ...mapState({
      countriesList: state => state.countriesList
    })
  },
  async mounted() {
    this.i.dateFrom = this.getCurrentMonth.dateFrom
    this.i.dateTo = this.getCurrentMonth.dateTo
    this.dateFrom = this.getCurrentMonth.dateFrom
    this.dateTo = this.getCurrentMonth.dateTo
  },
  watch: {
    dateSelection(newVal) {
      const now = new Date()
      const startOfLastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1)
      const endOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0)

      if (newVal === 'currentMonth') {
        this.i.dateFrom = this.getCurrentMonth.dateFrom
        this.i.dateTo = this.getCurrentMonth.dateTo
      } else if (newVal === 'lastMonth') {
        this.i.dateFrom = startOfLastMonth.toISOString()
        this.i.dateTo = endOfLastMonth.toISOString()
      } else {
        this.i.dateFrom = null
        this.i.dateTo = null
      }
      this.dateFrom = this.i.dateFrom
      this.dateTo = this.i.dateTo
    },
    dateTo(v) {
      if (v && this.dateSelection === 'custom') {
        this.i.dateTo = new Date(v).toISOString()
      }
    },
    'i.dateTo'(v) {
      if (!v) {
        this.dateTo = null
      } else if (v === this.d.dateTo && this.dateSelection === 'custom') {
        this.dateTo = new Date(v).toISOString()
      }
    },
    dateFrom(v) {
      if (v && this.dateSelection === 'custom') {
        this.i.dateFrom = new Date(v).toISOString()
      }
    },
    'i.dateFrom'(v) {
      if (!v) {
        this.dateFrom = null
      } else if (v === this.d.dateFrom) {
        this.dateFrom = new Date(v).toISOString()
      }
    }
  },
  methods: {
    ...mapActions(['pushAlertMessage']),
    submit() {
      for (const [k, v] of Object.entries(this.i)) {
        this.d[k] = v
      }
      this.$emit('submit', this.i)
    },
    reset() {
      let d = false
      this.dateSelection = 'currentMonth'
      for (const [k, v] of Object.entries(this.i)) {
        if (this.d[k] !== v) {
          this.i[k] = this.d[k]
          d = true
        }
      }
      if (d) {
        return
      }
      for (const k of Object.keys(this.i)) {
        this.i[k] = this.d[k] = ''
        if (k === 'dateTo' || k === 'dateFrom') {
          this.i[k] = this.d[k] = this.getCurrentMonth[k]
        }
      }
      this.$emit('reset', this.i)
    }
  }
}
</script>

<style lang="scss">
.custom-flatpickr:not([disabled]) {
  background-color: white;
}
</style>
