<template>
  <div>
    <Navbar ref="navbarRef" />
    <main>
      <div class="multilayer hero brand-colors--black with-bg">
        <div class="texture--rectangles mask-animation">
          <!-- Keep first, so that the texture stays behind following elements -->
        </div>
        <div class="multilayer-top">
          <article class="container main-section">
            <div class="row align-items-center mb-4">
              <div class="col-12 col-lg-5 col-xl-5">
                <h1 class="pt-4 type-mono">In Charge.</h1>
                <p class="text-white mb-5">
                  We are your open banking partner that enables scalable, secure, & transparent solutions that put you
                  in charge of your service offering.
                </p>
                <button class="btn btn-light mr-1" v-if="!user" @click="showGetStarted">Get started today</button>
                <a class="btn btn-outline-light" href="#contact">Contact us</a>
              </div>
            </div>
          </article>
        </div>
      </div>

      <section class="container py-5" id="playing-field">
        <h2 class="mb-3 type-mono">Leveling the Playing Field</h2>
        <p>
          Open Banking is a worldwide movement, and in Europe it is run by a regulation called PSD2 (the Revised Payment
          Services Directive). Open Banking is about giving innovators the possibility to build new solutions for their
          customers in a way that doesn’t interfere with their business model or disrupt the customer experience.
        </p>
        <p>
          That’s where Enable Banking comes in – we offer non-intrusive connectivity to ASPSPs` official APIs across
          Europe, without touching your data or interfering with your business model.
        </p>
        <p>We make sure you can focus on what matters most – building great products for customers!</p>
        <div>
          <MainApis />
        </div>
      </section>

      <div class="brand-colors--green with-bg bg-guy">
        <article class="container py-5" id="be-in-charge">
          <h2 class="mb-5 type-mono">What It Means to Be in Charge</h2>
          <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
              <h3 class="type-mono text-white">In Charge of<br />What You Create</h3>
              <p class="text-white mt-4 mb-5">
                We provide state-of-the-art connectivity solutions across Europe, without ever interfering with our
                clients' data or business model.
              </p>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <h3 class="type-mono text-white">In Charge of<br />Your Data</h3>
              <p class="text-white mt-4 mb-5">
                We will never make use of any of your data or interfere with how you run things day-to-day. With us, you
                get all the benefits of being connected, without ever losing control.
              </p>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <h3 class="type-mono text-white">In Charge of<br />Your Service</h3>
              <p class="text-white mt-4 mb-5">
                We are transparent and proactive about any connectivity issues that may occur, providing you with data
                on reasons for failed connectivity.
              </p>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <h3 class="type-mono text-white">In Charge of<br />Your Expansion</h3>
              <p class="text-white mt-4">
                We are the perfect partner when you wish to expand into new services and/or geographical markets. Always
                ready to quickly expand connectivity, we will grow with you.
              </p>
            </div>
          </div>
        </article>
      </div>

      <section class="container py-5" id="features">
        <h2 class="my-5 type-mono small">The Connectivity Engine that Puts You in Charge</h2>
        <p class="mb-5">
          Our services are based on PSD2 (The Revised Payment Service Directive) APIs from Account Servicing Payment
          Service Providers. It means that when you are using our services, you can be sure that they are official APIs.
          We are offering our connectivity engine for licensed and non-licensed companies depending your business
          models. We operate under Finnish Financial Supervisor Authority and our services are ISO/IEC 27001 certified.
        </p>

        <div class="eb-table">
          <div class="eb-desktop-header"></div>
          <div class="eb-desktop-header">Cloud</div>
          <div class="eb-desktop-header">In-house</div>

          <div class="eb-cell"></div>
          <div class="eb-cell eb-multiline" data-mobile-title="Cloud">
            Plug-n-play with our cloud-based connectivity engine.
          </div>
          <div class="eb-cell eb-multiline" data-mobile-title="In-house">
            Integrate our connectivity engine into your own environment.
          </div>

          <div class="eb-row-title">Account information</div>
          <div class="eb-cell" data-mobile-title="Cloud">✔</div>
          <div class="eb-cell" data-mobile-title="In-house">✔</div>

          <div class="eb-row-title eb-indent">Strong customer authentication (SCA)</div>
          <div class="eb-cell eb-multiline" data-mobile-title="Cloud">
            Redirect flow<br />
            Decoupled flow<br />
            Embedded flow
          </div>
          <div class="eb-cell eb-multiline" data-mobile-title="In-house">
            Redirect flow<br />
            Decoupled flow<br />
            Embedded flow
          </div>

          <div class="eb-row-title eb-indent">Consumer data</div>
          <div class="eb-cell eb-multiline" data-mobile-title="Cloud">
            Account numbers<br />
            Balances<br />
            Transactions
          </div>
          <div class="eb-cell eb-multiline" data-mobile-title="In-house">
            Account numbers<br />
            Balances<br />
            Transactions
          </div>

          <div class="eb-row-title eb-indent">Business data</div>
          <div class="eb-cell eb-multiline" data-mobile-title="Cloud">
            Account numbers<br />
            Balances<br />
            Transactions
          </div>
          <div class="eb-cell eb-multiline" data-mobile-title="In-house">
            Account numbers<br />
            Balances<br />
            Transactions
          </div>

          <div class="eb-row-title eb-indent">90 days consent</div>
          <div class="eb-cell" data-mobile-title="Cloud">✔</div>
          <div class="eb-cell" data-mobile-title="In-house">✔</div>

          <div class="eb-row-title eb-indent">UI Widget</div>
          <div class="eb-cell" data-mobile-title="Cloud">✔</div>
          <div class="eb-cell" data-mobile-title="In-house">✔</div>

          <div class="eb-row-title eb-indent">ASPSP information</div>
          <div class="eb-cell" data-mobile-title="Cloud">✔</div>
          <div class="eb-cell" data-mobile-title="In-house">✔</div>

          <div class="eb-row-title">Payment initiation</div>
          <div class="eb-cell" data-mobile-title="Cloud">Coming Q1/2023</div>
          <div class="eb-cell" data-mobile-title="In-house">✔</div>

          <div class="eb-row-title eb-indent">Domestic</div>
          <div class="eb-cell" data-mobile-title="Cloud">Coming Q1/2023</div>
          <div class="eb-cell" data-mobile-title="In-house">✔</div>

          <div class="eb-row-title eb-indent">SEPA</div>
          <div class="eb-cell" data-mobile-title="Cloud">Coming Q1/2023</div>
          <div class="eb-cell" data-mobile-title="In-house">✔</div>

          <div class="eb-row-title eb-indent">SEPA Instant Credit Transfer</div>
          <div class="eb-cell" data-mobile-title="Cloud">Coming Q1/2023</div>
          <div class="eb-cell" data-mobile-title="In-house">✔</div>

          <div class="eb-row-title eb-indent">Payment/transfer request</div>
          <div class="eb-cell" data-mobile-title="Cloud">Coming Q1/2023</div>
          <div class="eb-cell" data-mobile-title="In-house">✔</div>

          <div class="eb-row-title eb-indent">Standing order request</div>
          <div class="eb-cell" data-mobile-title="Cloud">Coming Q1/2023</div>
          <div class="eb-cell" data-mobile-title="In-house">✔</div>

          <div class="eb-row-title eb-indent">Recurring payment request</div>
          <div class="eb-cell" data-mobile-title="Cloud">Coming Q3/2023</div>
          <div class="eb-cell" data-mobile-title="In-house">Coming Q3/2023</div>

          <div class="eb-row-title eb-indent">ASPSP information</div>
          <div class="eb-cell" data-mobile-title="Cloud">Coming Q1/2023</div>
          <div class="eb-cell" data-mobile-title="In-house">✔</div>

          <div class="eb-row-title">Funds confirmation</div>
          <div class="eb-cell" data-mobile-title="Cloud"></div>
          <div class="eb-cell" data-mobile-title="In-house">Aggregation core only</div>
        </div>

        <h2 class="mt-5 mb-3 type-mono small">Enable Your Vision, with Effortless Accessibility</h2>
        <p class="mb-4">
          Our focus lies on delivering intuitive integrations that are simple to understand and easy to implement,
          bringing effortless accessibility into the world of Open Banking.
        </p>
        <ApiSamples />
      </section>

      <div class="bg-dark">
        <article class="container py-5 bg-dark" id="help-you-succeed">
          <h2 class="mb-5 type-mono golden">We’re Here to Help You Succeed</h2>
          <div class="row">
            <div class="col-12 col-md-4">
              <h3 class="type-mono text-white">
                <small class="d-block">1</small>
                Try It Out for Free
              </h3>
              <p class="text-white mt-4">
                Try out our available API’s and experience effortless connectivity, free of charge for you to test in
                your own environment.
              </p>
            </div>
            <div class="col-12 col-md-4">
              <h3 class="type-mono text-white">
                <small class="d-block">2</small>
                Launch Effortlessly
              </h3>
              <p class="text-white mt-4">
                Once you have established your needs, we’ll work with you to cater the service and effectivize your
                production.
              </p>
            </div>
            <div class="col-12 col-md-4">
              <h3 class="type-mono text-white">
                <small class="d-block">3</small>
                Scale Infinitely
              </h3>
              <p class="text-white mt-4">
                Having finalized production, launch your product and have the security of support for every step along
                the way!
              </p>
            </div>
          </div>
        </article>
      </div>

      <section class="container py-5" id="accessibility">
        <h2 class="mb-5 type-mono small">They are In Charge with Us</h2>
        <div class="row">
          <div class="col-6 col-md-3 text-center auto-margin p-4">
            <a href="https://www.crosskey.fi/" target="_blank">
              <img src="../assets/crosskey.svg" alt="Crosskey" class="reference-pic" />
            </a>
          </div>
          <div class="col-6 col-md-3 text-center auto-margin p-4">
            <a href="https://www.riskrate.io/" target="_blank">
              <img src="../assets/riskrate.png" alt="Riskrate" class="reference-pic" />
            </a>
          </div>
          <div class="col-6 col-md-3 text-center auto-margin p-4">
            <a href="https://kreditz.com/" target="_blank">
              <img src="../assets/kreditz.png" alt="Kreditz" class="reference-pic" />
            </a>
          </div>
          <div class="col-6 col-md-3 text-center auto-margin p-4">
            <a href="https://www.auracloud.com/" target="_blank">
              <img src="../assets/auracloud.png" alt="Aura Cloud" class="reference-pic" />
            </a>
          </div>
        </div>
      </section>

      <div class="bg-dark">
        <article class="container py-4 bg-dark" id="help-you-succeed">
          <div class="row">
            <div class="col-12 col-md-6 py-3">
              <h3 class="type-mono golden">Simplifying Your Connectivity Across Borders</h3>
              <p class="text-white mt-4">
                Integrating with new providers, testing interactions, and monitoring APIs in multiple countries is a
                full-time job.
              </p>
              <p class="text-white mt-4">Enable Banking has you covered.</p>
              <p class="text-white mt-4">
                We have integrated with all the major banks and other payment providers in Europe, so you can focus on
                your business, instead of struggling with legacy systems.
              </p>
            </div>
            <div class="col-12 col-md-6 py-3">
              <h3 class="type-mono golden">Security That You Can Actually Trust</h3>
              <p class="text-white mt-4">
                Enable Banking is a secure and reliable way to link your data with third-party providers.
              </p>
              <p class="text-white mt-4">
                We are ISO/IEC 27001 certified, meaning we have been independently audited to ensure that our products
                meet the highest security standards.
              </p>
              <p class="text-white mt-4">
                We are regulated by Finnish Financial Supervisory Authority, and we are using only official ASPSPs` APIs
                to provide our services. With us you can be sure that you are compliant now and in future.
              </p>
            </div>
          </div>
        </article>
      </div>

      <section class="container py-5 mb-5" id="contact">
        <h4 class="mt-5 mb-3 type-mono">
          Got questions?
          <span class="gradient-text">Contact us</span> →
          <a href="mailto:info@enablebanking.com">info@enablebanking.com</a>
        </h4>
        <div class="bg-light p-3 p-lg-5 rounded">
          <MainContact ref="contactFormRef" />
        </div>
      </section>

      <section class="container mb-5" v-if="false">
        <MainSubscribe />
      </section>
    </main>
    <!-- <MainChat />  -->
    <AsideFooter />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ApiSamples from '../components/ApiSamples.vue'
import AsideFooter from '../components/AsideFooter.vue'
import MainApis from '../components/Main/MainApis.vue'
import MainContact from '../components/Main/MainContact.vue'
import MainSubscribe from '../components/Main/MainSubscribe.vue'
import Navbar from '../components/Navbar.vue'
// import MainChat from '../components/Main/MainChat/MainChat.vue'
import metadata from '../mixins/metadata.js'

export default {
  name: 'home',
  data() {
    return {}
  },
  mixins: [metadata],
  computed: {
    ...mapGetters(['user'])
  },
  components: {
    ApiSamples,
    AsideFooter,
    MainApis,
    MainContact,
    MainSubscribe,
    Navbar
  },
  methods: {
    showGetStarted() {
      this.$refs.navbarRef.showGetStarted()
    }
  }
}
</script>

<style lang="scss" scoped>
.reference-pic {
  width: 8rem;
}
.product-icon {
  width: 80px;
  height: 80px;
  img {
    width: 100%;
    height: auto;
  }
}

.multilayer.hero {
  background-image: url('../assets/img/new-hero.jpg');
}
.bg-guy {
  background-image: url('../assets/img/dude.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right top;
}
.auto-margin {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
