<template>
  <form
    class="form-inline bg-gradient rounded px-3 pt-3 pb-2"
    method="post"
    name="subscription"
    netlify
    @submit.prevent="handleSubmit"
    v-if="!isFormSubmitted"
  >
    <div class="form-group lead mr-3 mb-2 text-white">Get Open Banking updates:</div>
    <div class="flex-fill d-flex flex-nowrap">
      <div class="form-group mr-3 mb-2 flex-fill">
        <input
          type="email"
          class="form-control w-100"
          placeholder="E-mail"
          size="28"
          required
          v-model="email"
        />
      </div>
      <button type="submit" class="btn btn-light mb-2">Subscribe</button>
    </div>
    <span class="ml-2 mb-2 text-white">
      By submitting the form, you accept
      <br />
      <router-link class="text-white mr-1" to="/privacy-policy">
        <u>Privacy Policy</u>
      </router-link>of this website.
    </span>
  </form>
  <div v-else class="d-flex align-items-center">
    <Success class="mr-2" />
    <h4 class="mb-0">Thank you! You have been subscribed</h4>
  </div>
</template>
<script>
import axios from 'axios'
import Success from '../elements/Success'

export default {
  name: 'MainSubscribe',
  data() {
    return {
      email: '',
      isFormSubmitted: false
    }
  },
  components: {
    Success
  },
  methods: {
    encode(data) {
      return Object.keys(data)
        .map(
          key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join('&')
    },
    handleSubmit() {
      const comp = this
      axios.post(
        '/',
        this.encode({
          'form-name': 'subscription',
          email: this.email
        }),
        { header: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      ).then(response => {
        this.isFormSubmitted = true
        comp.email = ''
      }).catch(err => {
        alert('Something went wrong. Please try again', err)
      })
    }
  }
}
</script>
