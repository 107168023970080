<template>
  <div>
    <label class="small d-block mt-3">Integrated APIs</label>
    <AisRefinementList attribute="scopes" :sort-by="['name:asc']" class="list-unstyled" :isRefined="true">
      <div slot-scope="{ items, refine }">
        <ChipsItem
          v-for="(scope, index) in items"
          :key="`scope-${index}`"
          class="mr-2 mb-2 text-uppercase"
          :text="scope.label"
          v-on:change="refine(scope.label)"
          :checked="scope.isRefined"
          v-on:loaded="refine(scope.label)"
        />
        <span v-if="!items.length">No results</span>
      </div>
    </AisRefinementList>
    <label class="small d-block mt-3">Integrated environments</label>
    <AisRefinementList attribute="environments" :sort-by="['name:asc']" class="list-unstyled" :isRefined="true">
      <div slot-scope="{ items, refine }">
        <ChipsItem
          v-for="(environment, index) in items"
          :key="`environment-${index}`"
          class="mr-2 mb-2 text-capitalize"
          :text="environment.label"
          v-on:change="refine(environment.label)"
          :checked="environment.isRefined"
        />
        <span v-if="!items.length">No results</span>
      </div>
    </AisRefinementList>
  </div>
</template>

<script>
import ChipsItem from '../../elements/Chips/ChipsItem.vue'

export default {
  components: {
    ChipsItem
  }
}
</script>
