<template>
  <div>
    <Navbar />
    <main>
      <section class="brand-colors--green with-bg">
        <div class="container bg-guy">
          <div class="row align-items-center">
            <div class="col-12 col-md-6 py-4 py-md-7">
              <h1 class="type-mono">We Are Hiring</h1>
            </div>
          </div>
        </div>
      </section>
      <section class="container py-5">
        <vue-markdown class="job-description">
        <!-- eslint-disable -->
# Technical Support Specialist

## Job description

As part of our team you will be supporting business partners using our integrations towards Open Banking APIs of European banks, as well as monitoring performance of the integrations and helping our sales team with technical onboarding of new business partners. You will be in constant communication (primerility over email) with companies using our technology, engineers building it and banks, which we are integrated with. Through day-to-day work you will gain deep knowledge about Open Banking, financial infrastructure and payments. Your work will make an essential contribution to the company’s success and this position provides great opportunities for growth.

We expect you to:

- be able to demonstrate problem analysis skills and possibility to identify an issue;
- be customer oriented and willing to help;
- have full proficiency in English;
- have basic knowledge of SQL;
- learn new technologies and gain domain expertise.

The following will be considered as an advantage:

- experience in logs analysis or system monitoring;
- experience in helpdesk or customer support;
- basic knowledge of APIs and web technologies;
- basic knowledge of a scripting language such as Python or Javascript.

## About Enable Banking

Enable Banking Oy provides to its clients state of art Open Banking aggregation technology, connecting to over 2500 banks in 20+ countries.

We develop high quality, secure software at a very fast pace. To be able to achieve this we rely on continuous integration and delivery, server less cloud infrastructure, as well as automated testing and monitoring. We are ISO 27001 certified and are regulated by the Finnish supervisory authority (Finanssivalvonta).

Our customers are forerunners of Open Banking ranging from accounting software to neobanks core technology providers. Public references include Kreditz, Smart Valor, Aura Cloud, Riskrate, Zervant, eTasku and Crosskey.

We are venture funded, and fast growing fintech, operating in Espoo Finland, Vilnius Lithuania, and in Stockholm Sweden.

## Got interested? Apply now → [hr@enablebanking.com](mailto:hr@enablebanking.com)
        </vue-markdown>
      </section>
    </main>
    <AsideFooter />
  </div>
</template>

<script>
import VueMarkdown from '@adapttive/vue-markdown'
import AsideFooter from '../components/AsideFooter.vue'
import Navbar from '../components/Navbar.vue'
import metadata from '../mixins/metadata.js'

export default {
  name: 'careers-technical-support-specialist',
  mixins: [metadata],
  components: {
    AsideFooter,
    Navbar,
    VueMarkdown
  }
}
</script>
<style lang="scss">
.job-description h2 {
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.bg-guy {
  background-image: url('../assets/img/dude.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right center;
}
</style>
