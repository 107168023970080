<template>
  <div>
    <Navbar />
    <main>
      <section class="container pb-5">
        <h1 class="text-center py-3 py-md-5">In order to proceed please accept terms of service</h1>
        <iframe
          src="https://drive.google.com/file/d/1cXHk5mC4JOQvg6Izt_y5VcGYgTJsOsLP/preview"
          width="100%"
          height="960"
        ></iframe>
        <form
          class="form-inline bg-gradient rounded px-3 pt-3 pb-2"
          method="post"
          name="terms"
          @submit.prevent="handleSubmit"
          v-if="!isFormSubmitted"
        >
          <div class="flex-fill d-flex flex-nowrap">
            <div class="form-group mr-3 mb-2 flex-fill">
              <input
                type="email"
                class="form-control w-100"
                placeholder="Your e-mail address"
                size="28"
                required
                v-model="email"
              />
            </div>
            <button type="submit" class="btn btn-light mb-2">Accept terms & proceed</button>
          </div>
        </form>
        <div v-if="curl">
          <pre v-html="curl"></pre>
        </div>
      </section>

      <AsideFooter />
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { firebaseAuth } from '../firebase.js'
import AsideFooter from '../components/AsideFooter.vue'
import Navbar from '../components/Navbar.vue'
import metadata from '../mixins/metadata.js'

export default {
  name: 'connectors-terms',
  data() {
    return {
      email: '',
      isFormSubmitted: false,
      curl: ''
    }
  },
  mixins: [metadata],
  components: {
    AsideFooter,
    Navbar
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    handleSubmit() {
      const comp = this
      firebaseAuth
        .signInWithEmailLink(this.email, window.location.href)
        .then((result) => {
          console.log(result) // eslint-disable-next-line
          this.isFormSubmitted = true
          comp.email = ''
          const user = result.user
          user
            .getIdToken()
            .then((idToken) => {
              window.location.href = '/cp/packages'
            })
            .catch((error) => {
              alert(error.message)
              window.location.href = '/sign-in/'
            })
        })
        .catch((error) => {
          alert(error.message)
          window.location.href = '/sign-in/'
        })
    }
  },
  mounted() {
    this.user &&
      this.user
        .getIdToken()
        .then((idToken) => {
          window.location.href = '/cp/packages'
        })
        .catch(() => {
          window.location.href = '/sign-in/'
        })
  }
}
</script>
