<template>
  <div :style="nodeMargin">
    <div v-if="node.value.values" @click="toggleChildren" :style="hasChildren ? 'cursor: pointer;' : ''">
      <b-alert show class="d-flex justify-content-between mb-1" variant="secondary" v-b-toggle="toggleId">
        <div>{{ getLabel }}&emsp;   Type: {{node.value.type}}.&emsp;    Values: {{sortedValues}}.&emsp;  Present: {{ formattedPercentage }} </div>
        <font-awesome-icon v-if="hasChildren"
        :icon="toggleChildrenIcon" />
      </b-alert>
    </div>
    <div v-else>
      <div @click="toggleChildren" :style="hasChildren ? 'cursor: pointer;' : ''">
        <b-alert show class="d-flex justify-content-between mb-1" variant="secondary" v-b-toggle="toggleId">
          <div>{{ getLabel }}&emsp;   Type: {{node.value.type}}.&emsp;  Present: {{ formattedPercentage }} </div>
          <font-awesome-icon v-if="hasChildren"
          :icon="toggleChildrenIcon"/>
        </b-alert>
      </div>
      <b-collapse :id="toggleId">
        <TreeNode
          v-for="child in node.children"
          :key="child.id"
          :node="child"
          :spacing="spacing + 10"
        />
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'TreeNode',
  props: {
    node: {
      type: Object,
      required: true
    },
    spacing: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      showChildren: false,
      label: this.node.label,
      toggleId: `collapse-${uuidv4()}`
    }
  },
  computed: {
    nodeMargin() {
      return {
        'margin-left': `${this.spacing}px`
      }
    },
    getLabel() {
      if (this.label === '[]') { return 'Elements' } else { return this.label }
    },
    sortedValues() {
      return this.node.value.values.slice().sort()
    },
    hasChildren() {
      const { children } = this.node
      return children && children.length > 0
    },
    toggleChildrenIcon() {
      return this.showChildren ? 'fa-angle-down' : 'fa-angle-right'
    },
    formattedPercentage() {
      const roundedPercentage = Math.round(this.node.value.share * 100)
      return roundedPercentage === 0 ? '<1%' : `${roundedPercentage}%`
    }
  },
  methods: {
    toggleChildren() {
      if (this.hasChildren) {
        this.showChildren = !this.showChildren
        this.$bvModal.toggle(this.toggleId)
      }
    }
  }
}
</script>
