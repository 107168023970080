<template>
  <div>
    <div v-if="!linking">
      <button class="btn btn-primary" :disabled="loadingASPSPs" @click="linkAccounts()">
        <span v-if="loadingASPSPs" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Link accounts
      </button>
    </div>
    <div v-else>
      <div class="form align-items-center">
        <div class="row my-2">
          <div class="col-2">
            <select class="form-control" required :disabled="linkingStarted" v-model="country"
              v-on:change="aspsp = ''; usage = ''; accountType = ''">
              <option value disabled selected>Country</option>
              <option
                v-for="country in Object.keys(countries).sort((a, b) => countriesList[a].localeCompare(countriesList[b]))"
                :value="country" :key="`${app.kid}-country-${country}`">{{ countriesList[country] }}</option>
            </select>
          </div>

          <div class="col-2">
            <select class="form-control" required :disabled="!country || linkingStarted" v-model="aspsp"
              v-on:change="usage = ''; accountType = ''">
              <option value disabled selected>ASPSP</option>
              <option v-for="aspsp in countries[country]" :value="aspsp"
                :key="`${app.kid}-aspsp-${country}-${aspsp.name.replace(/\s+/g, '-')}`">{{ aspsp.name }}</option>
            </select>
          </div>
          <div class="col-2">
            <select class="form-control" required :disabled="!aspsp || linkingStarted" v-model="usage"  v-on:change="accountType = ''">
              <option value disabled selected>Usage type</option>
              <option v-for="usage in aspsp ? aspsp.psu_types : []" :value="usage"
                :key="`${app.kid}-aspsp-${country}-${aspsp.name.replace(/\s+/g, '-')}-${usage}`">{{ usage }}</option>
            </select>
          </div>
          <div class="col-2">
            <select class="form-control" required :disabled="!usage || linkingStarted" v-model="accountType">
              <option value disabled selected>Link as</option>
              <option v-for="accountType in ['Creditor', 'Debtor']" :value="accountType" :key="accountType">{{
                accountType
              }}</option>
            </select>
          </div>
          <div class="col-4 d-flex align-items-center">
            <button class="btn btn-primary"
              :disabled=" !accountType || linkingStarted"
              @click="startAccountsLinking()">
              <span v-if="linkingStarted" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
              Link
            </button>
          </div>
        </div>

        <!-- Second Column -->
        <div class="row my-2">
          <div v-if="isSandbox" class="col-2">
            <input type="text" class="form-control" placeholder="Name" v-model="accountName" required :disabled="!accountType || linkingStarted"
               />
          </div>
          <div v-if="isSandbox" class="col-2">
            <input type="text" class="form-control" placeholder="Currency" v-model="accountCurrency" required :disabled="!accountType || linkingStarted"
               />
          </div>
          <div v-if="isSandbox" class="col-2">
            <select class="form-control" required  v-model="accountScheme" :disabled="!accountType || linkingStarted">
              <option value disabled selected>Account Type</option>
              <option v-for="accountScheme in accountSchemeOptions" :value="accountScheme" :key="accountScheme">{{
                accountScheme
              }}</option>
            </select>
          </div>
          <div v-if="isSandbox" class="col-3">
            <input type="text" class="form-control" v-model="accountId" required
              :disabled="!accountType || linkingStarted" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'PaymentAccountsLinking',
  data() {
    return {
      loadingASPSPs: false, // when fetching ASPSPs list
      linking: false, // when fetched ASPSPs list and ready to link
      linkingStarted: false, // when linking started
      countries: null,
      country: '',
      aspsp: '',
      usage: '',
      accountType: '',
      accountName: '',
      accountCurrency: '',
      accountScheme: '',
      accountId: ''
    }
  },
  props: {
    app: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(['user', 'idToken']),
    ...mapState({
      countriesList: state => state.countriesList
    }),
    accountSchemeOptions() {
      if(this.country === 'SE') {     // provide account scheme options based on country
        return ['IBAN', 'BBAN', 'BGNR', 'PGNR']
      }
      return ['IBAN', 'BBAN']
    },
    isSandbox() {
      return this.app.environment === 'SANDBOX'
    }
  },
  methods: {
    ...mapActions(['pushAlertMessage']),
    swipeData() {
      this.country = ''
      this.aspsp = ''
      this.usage = ''
      this.accountType = ''
      this.accountCurrency = ''
      this.accountName = ''
      this.accountScheme = ''
      this.accountId = ''
    },
    async linkAccounts() {
      this.loadingASPSPs = true
      try {
        const aspspsResponse = await axios.get(
          '/api/aspsps',
          { headers: { Authorization: `Bearer ${this.idToken.token}` } }
        )
        const countries = {}
        for (const aspsp of aspspsResponse.data.aspsps) {
          const countryASPSPs = countries[aspsp.country] || []
          aspsp.psu_types = aspsp.psu_types.sort()
          countryASPSPs.push(aspsp)
          countries[aspsp.country] = countryASPSPs.sort((a, b) => a.name.localeCompare(b.name))
        }
        this.countries = countries
        this.linking = true
      } catch (e) {
        this.pushAlertMessage({ message: 'Unable to get ASPSPs' })
      } finally {
        this.loadingASPSPs = false
      }
    },
    async sandboxAccountLinking(query) {
      this.linkingStarted = true
      try {
          await axios.post(
            '/api/link_sandbox_payment_accounts',
            query.toString(),
            { headers: { Authorization: `Bearer ${this.idToken.token}` } }
          )
        } catch (e) {
          this.pushAlertMessage({ message: 'Unable to start accounts linking' })
        } finally {
          this.linkingStarted = false
        }
    },
    async accountLinking(query) {
      this.linkingStarted = true
      try {
          const linkAccountsResponse = await axios.post(
            '/api/link_payment_accounts',
            query.toString(),
            { headers: { Authorization: `Bearer ${this.idToken.token}` } }
          )
          window.location.href = linkAccountsResponse.data.url
        } catch (e) {
          this.pushAlertMessage({ message: 'Unable to start accounts linking' })
        } finally {
          this.linkingStarted = false
        }
    },
    async startAccountsLinking() {
      const query = new URLSearchParams()
      query.set('country', this.country)
      query.set('aspsp', this.aspsp.name)
      query.set('appId', this.app.kid)
      query.set('psuType', this.usage)
      query.set('accountType', this.accountType)
      query.set('redirectUrl', `${window.location.origin}/api/payment_auth_redirect`)

      if (this.isSandbox) {
        if (!this.accountName || !this.accountId || !this.accountCurrency || !this.accountScheme) {
          this.pushAlertMessage({ message: 'Please fill in all fields' })
          this.linkingStarted = false
          return
        }
        query.set('accountScheme', this.accountScheme)
        query.set('accountName', this.accountName)
        query.set('accountCurrency', this.accountCurrency)
        query.set('accountId', this.accountId)
        await this.sandboxAccountLinking(query)
        this.swipeData()
        this.$emit('linking-finished')
      } else {
        await this.accountLinking(query)
      }
    }
  }
}
</script>
