<template>
  <form @submit.prevent="handleSubmit" v-if="!formSuccessMessage">
    <div class="row">
      <div class="col-12 col-md-6 mb-3">
        <input
          type="text"
          name="first_name"
          class="form-control"
          required
          placeholder="First name"
          v-model="first_name"
        />
      </div>
      <div class="col-12 col-md-6 mb-3">
        <input type="text" name="last_name" class="form-control" required placeholder="Last name" v-model="last_name" />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 mb-3">
        <input type="text" name="company" class="form-control" required placeholder="Company" v-model="company" />
      </div>
      <div class="col-12 col-md-6 mb-3">
        <input type="text" name="title" class="form-control" required placeholder="Title" v-model="title" />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 mb-3">
        <input type="email" name="email" class="form-control" required placeholder="E-mail" v-model="email" />
      </div>
      <div class="col-12 col-md-6 mb-3">
        <input type="tel" name="phone" class="form-control" placeholder="Phone (optional)" v-model="phone" />
      </div>
    </div>
    <div class="mb-3">
      <textarea
        name="comment"
        class="form-control"
        placeholder="Please leave your message here (optional)"
        row="1"
        v-model="comment"
      ></textarea>
    </div>
    <button class="btn btn-primary">Send</button>
    <span class="ml-2">
      By submitting the form, you accept
      <router-link to="/privacy-policy">Privacy Policy</router-link>
      of this website.
    </span>
  </form>
  <div v-else class="d-flex align-items-center">
    <Success class="mr-2" />
    <h4 class="mb-0">{{ formSuccessMessage }}</h4>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import Success from '../elements/Success'

export default {
  name: 'MainContact',
  data() {
    return {
      first_name: '',
      last_name: '',
      company: '',
      title: '',
      email: '',
      phone: '',
      comment: '',
      isFormSubmitted: false,
      formSuccessMessage: ''
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  components: {
    Success
  },
  props: {
    subject: {
      type: String,
      default: 'sales'
    }
  },
  mounted() {
    this.email = (this.user && this.user.email) || ''
  },
  methods: {
    ...mapActions(['pushAlertMessage']),
    encode(data) {
      return Object.keys(data)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
        .join('&')
    },
    handleSubmit() {
      this.isFormSubmitted = true
      axios
        .post(
          '/',
          this.encode({
            'form-name': 'contact',
            subject: this.subject,
            first_name: this.first_name,
            last_name: this.last_name,
            company: this.company,
            title: this.title,
            email: this.email,
            phone: this.phone,
            comment: this.comment
          }),
          { header: { 'Content-Type': 'application/x-www-form-urlencoded' } }
        )
        .then((response) => {
          this.formSuccessMessage = 'Thank you for reaching out! We will get back to you soon'
          this.isFormSubmitted = false
          this.first_name = ''
          this.last_name = ''
          this.company = ''
          this.title = ''
          this.email = ''
          this.phone = ''
          this.comment = ''
          this.$emit('is-form-submitted', true)
        })
        .catch(() => {
          this.isFormSubmitted = false
          this.pushAlertMessage({ message: 'Something went wrong. Please try again' })
        })
    }
  }
}
</script>
