<template>
  <span
    class="chips-item d-inline-block"
    v-on:click="checkSelection"
    :class="[
      {'chips-item--checked': checked},
      {'chips-item--disabled': disabled},
      {'chips-item--unselectable': unselectable}
    ]"
  >
    <span class="chips-item__text">
      <slot />
      {{text}}
      <span class="chips-item__tooltip" v-if="tooltip">{{tooltip}}</span>
    </span>
  </span>
</template>

<script>
export default {
  name: 'chips-item',
  props: {
    type: {
      type: String,
      default: 'radio'
    },
    text: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    unselectable: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$emit('loaded', this.text)
  },
  methods: {
    checkSelection() {
      if (this.disabled) {
        return
      }
      if (!this.unselectable && this.checked) {
        this.$emit('change', '')
      } else {
        this.$emit('change', this.value)
      }
    }
  }
}
</script>
