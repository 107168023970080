<template>
  <div>
    <Navbar />
    <section class="container py-5">
      <div v-if="error">
        <p class="mb-3">Failed to authorize</p>
      </div>
      <div v-else>
        <div v-if="sessions.length === 0">
          <p class="mb-3">{{ $t('no_shared_accounts', { aspsps }) }}</p>
          <p class="mb-3">{{ $t('no_closed_consents') }} {{ closedSessionsCount }}</p>
          <p class="mb-3">{{ $t('latest_closed_session') }} {{ latestClosedSession }}</p>
        </div>
        <div v-else>
          <p class="mb-5" style="font-size: 20px">{{ $t('shared_accounts', { aspsps }) }}</p>
          <div v-for="session in sessions" :key="session.session_id" class="mb-5">
            <h4 class="mb-3">{{ session.application_name }}</h4>
            <div v-if="session.disabled">
              <div v-if="session.aspspRevoked">
                <p class="mb-3">{{ $t('consent_revoked_message') }}</p>
              </div>
              <div v-else>
                <p class="mb-3">{{ $t('enforced_consent_revocation_message', {
                  aspsps: aspsps, application_name:
                    session.application_name
                }) }}</p>
              </div>
            </div>
            <div v-else>
              <p class="mb-3">{{ $t('authorized_on') }} {{ formatDate(session.authorized) }}</p>
              <p class="mb-3">{{ $t('valid_until') }} {{ formatDate(session.access.valid_until) }}</p>
              <p class="mb-3">{{ $t('shared_accounts_count') }} {{ session.count }}</p>
              <p class="mb-3">{{ $t('access_to_data') }} {{ access(session) }}</p>
              <p v-if="session.application_description" class="mb-3">{{ $t('application_desciption') }} {{ session.application_description }}</p>
              <p v-if="session.application_gdpr_email" class="mb-3">{{ $t('application_gdpr_email') }} {{ session.application_gdpr_email }}</p>
              <p v-if="session.application_privacy_url" class="mb-3">{{ $t('application_privacy_url') }} {{ session.application_privacy_url }}</p>
              <p v-if="session.application_terms_url" class="mb-3">{{ $t('application_terms_url') }} {{ session.application_terms_url }}</p>
              <button class="btn btn-primary btn-sm" @click="revokeAccess(session)" :disabled="session.disabled"> {{
              $t('revoke_access') }}</button>
            </div>
          </div>
        </div>
        <div class="text-right">
          <button class="btn btn-primary-light" @click="closeDataSharingReviewSession()">{{ $t('end_session_button')
          }}</button>
        </div>
      </div>
    </section>
    <AsideFooter />
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import Vue from 'vue'
import VueConfirmDialog from 'vue-confirm-dialog'
import VueI18n from 'vue-i18n'
import AsideFooter from '../components/AsideFooter.vue'
import Navbar from '../components/Navbar.vue'
import VueCookies from 'vue-cookies'
import en from '../locales/en.json'
import fr from '../locales/fr.json'

import { mapActions } from 'vuex'

Vue.use(VueI18n)
Vue.use(VueConfirmDialog)
Vue.use(VueCookies, {
  path: '/'
})
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

let locale = 'en'
locale = moment.locale(navigator.language)

export default {
  name: 'ActiveSessionsAccounts',
  i18n: new VueI18n({
    locale: locale,
    messages: {
      en,
      fr
    }
  }),
  data() {
    return {
      aspsps: localStorage.aspsp,
      error: false,
      closedSessionsCount: null,
      latestClosedSession: null,
      review_session_id: null,
      sessions: [],
      isLoading: true
    }
  },
  components: {
    AsideFooter,
    Navbar
  },
  async mounted() {
    if (window.location.hash) {
      this.error = true
      const hashParams = new URLSearchParams(window.location.hash.substring(1))
      if (hashParams && hashParams.has('error')) {
        const error = hashParams.get('error')
        let message = 'Unknown error occurred'
        if (error === 'authorization_failure') {
          message = 'Unable to authorize'
        }
        this.pushAlertMessage({ message: message })
        hashParams.delete('error')
        window.location.hash = hashParams.toString()
      }
    }
    else if (!this.sessions.length) {
      this.getSessions()
      const cookies = document.cookie.split(';')
      for (let i = 0; i < cookies.length; i++) {
        if (cookies[i].includes('reviewSessionId')) {
          this.review_session_id = cookies[i].split('=')[1]
        }
        if (cookies[i].includes('closedSessionsCount')) {
          this.closedSessionsCount = cookies[i].split('=')[1]
          console.log('closedSessionsCount ' + this.closedSessionsCount)
        }
        if (cookies[i].includes('latestClosedSession')) {
          const latestClosedSession = JSON.parse(decodeURIComponent(cookies[i].split('=')[1]))
          const formattedClosedDate = (latestClosedSession.closedDate).match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)[0].replace('T', ' ')
          this.latestClosedSession = formattedClosedDate
          console.log('latestClosedSession ' + this.latestClosedSession)
        }
      }
      if (this.review_session_id === null) {
        console.error('Review session id not found in cookies')
      }
      if (this.closedSessionsCount == null) {
        console.error('Session data not found in cookies') // return null value
      }
      if (this.latestClosedSession == null) {
        console.error('Session data not found in cookies')
      }
    }
  },
  methods: {
    ...mapActions(['pushAlertMessage']),
    getSessions() {
      const cookies = document.cookie.split(';')
      let data = null
      for (let i = 0; i < cookies.length; i++) {
        if (cookies[i].includes('authorizedSessions')) {
          data = cookies[i].split('=')[1]
        }
      }
      if (data === null) {
        console.error('authorizedSessions data not found in cookies')
        this.pushAlertMessage({
          message: 'No active session'
        })
        this.$router.push('/data-sharing-consents')
        return null
      }
      this.sessions = JSON.parse(decodeURIComponent(data)).map(session => {
        return {
          ...session,
          disabled: false,
          aspspRevoked: true
        }
      })
    },
    access(session) {
      var access = session.access
      var sharedData = 'list of accounts'
      if (access.balances) {
        sharedData = sharedData.concat(', ', 'balances on the accounts')
      }
      if (access.transactions) {
        sharedData = sharedData.concat(', ', 'transactions on the accounts')
      }
      return sharedData
    },
    formatDate(date) {
      try {
        const options = {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: false,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        return new Date(date).toLocaleString([], options)
      } catch (error) {
        console.error('Error formatting date: ', error)
        return date
      }
    },
    async revokeAccess(session) {
      if (confirm('Do you really want to terminate this access?')) {
        const query = new URLSearchParams()
        query.set('sessionId', session.session_id)
        query.set('applicationOwnerEmail', session.application_owner_email)
        try {
          const revocationResponse = await axios.post(
            '/api/revoke_consent',
            query.toString(),
            {}
          )
          var status = revocationResponse.status
          var responseMessage = revocationResponse.data.message
          console.log(responseMessage)
          if (status === 200) {
            session.disabled = true
            if (responseMessage === 'OK') {
              const updatedSessionsList = this.sessions.filter(item => item.session_id !== session.session_id)
              const sessionsJSON = JSON.stringify(updatedSessionsList)
              document.cookie = `authorizedSessions=${sessionsJSON}; Path=/;`
              session.aspspRevoked = true
              this.pushAlertMessage({
                message: 'Access revoked', type: 'info'
              })
            } else {
              const updatedSessionsList = this.sessions.filter(item => item.session_id !== session.session_id)
              const sessionsJSON = JSON.stringify(updatedSessionsList)
              document.cookie = `authorizedSessions=${sessionsJSON}; Path=/;`
              session.aspspRevoked = false
            }
          } else {
            session.disabled = false
          }
        } catch (e) {
          session.disabled = false
          console.log(e)
          this.pushAlertMessage({
            message: 'Unable to revoke access'
          })
        }
      }
    },
    clearCookie(name) {
      document.cookie = `${name}=; expires=Thu, 01 Jan 2000 00:00:00 UTC; path=/;`
    },
    async closeDataSharingReviewSession() {
      if (confirm('End data sharing consents review?')) {
        const query = new URLSearchParams()
        query.set('sessionId', this.review_session_id)
        try {
          const revocationResponse = await axios.post(
            '/api/revoke_consent',
            query.toString(),
            {}
          )
          var status = revocationResponse.status
          if (revocationResponse.status === 200) {
            this.clearCookie('authorizedSessions')
            this.$router.push('/data-sharing-consents')
          } else if (status === 401) {
            this.pushAlertMessage({
              message: 'Session is expired'
            })
          }
        } catch (e) {
          console.log(e)
          this.pushAlertMessage({
            message: 'Unable to end session'
          })
        }
      }
    }
  }
}
</script>
