<template>
  <div>
    <div class="mb-4">
      <h5>
        <span>Statistics by endpoint</span>
      </h5>
    </div>
    <div class="overflow-auto">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Endpoint name</th>
            <th>Total calls</th>
            <th>Successful calls</th>
            <th>Success rate</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(s, index) in statistics">
            <tr v-if="s.endpoint !== 'account_list' && s.endpoint !== 'payment_redirect'" :key="`${index}-row`" class="table-row">
              <td>{{ mapEndpoint(s.endpoint) }}</td>
              <td>{{ s.total_calls }}</td>
              <td>{{ s.successful_calls }}</td>
              <td>{{ (s.success_rate * 100).toFixed(2) }}%</td>
            </tr>
          </template>
          <tr v-if="statistics.length !== 0" class="table-row">
              <td style="font-weight: bold;">Total</td>
              <td>{{ totalCalls }}</td>
              <td>{{ totalSuccessfulCalls }}</td>
              <td>{{ totalSuccessRate }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    statistics: {
      type: Array
    }
  },
  methods: {
    mapEndpoint(endpoint) {
      const map = {
        auth: 'POST /auth',
        balances: 'GET /account/{}/balances',
        details: 'GET /account/{}/details',
        sessions: 'POST /sessions',
        transactions: 'GET /account/{}/transactions',
        transaction: 'GET /account/{}/transactions/{}',
        auth_redirect: 'Returning to auth callback',
        get_session: 'GET /sessions/{}',
        create_payment: 'POST /payments',
        get_payment: 'GET /payments/{}',
        delete_session: 'DELETE /sessions/{}',
        aspsps: 'GET /aspsps',
        application: 'GET /application'
      }
      return map[endpoint] || endpoint
    }
  },
  computed: {
    totalCalls() {
      return this.statistics.reduce((total, s) => {
        if (s.endpoint === 'account_list' || s.endpoint === 'payment_redirect') {
          return total
        }
        return total + s.successful_calls
      }, 0)
    },
    totalSuccessfulCalls() {
      return this.statistics.reduce((total, s) => {
        if (s.endpoint === 'account_list' || s.endpoint === 'payment_redirect') {
          return total
        }
        return total + s.successful_calls
      }, 0)
    },
    totalSuccessRate() {
      return ((this.totalSuccessfulCalls / this.totalCalls) * 100).toFixed(2)
    }
  }
}
</script>

<style lang="scss">
.table-hover tbody tr.no-hover:hover {
  background-color: transparent;
}
</style>
