<template>
<pre ref="code-block" class="px-5" :class="language"><code><slot /></code></pre>
</template>

<script>
import hljs from 'highlight.js'

export default {
  data() {
    return {}
  },
  props: {
    language: {
      type: String,
      default: 'javascript'
    }
  },
  mounted() {
    const block = this.$refs['code-block']
    hljs.highlightBlock(block)
  }
}
</script>

<style lang="scss">
@import "~highlight.js/styles/github.css";
</style>
