<template>
  <div>
    <Navbar />
    <main>
      <div class="meetings-iframe-container mt-md-4 mt-sm-0" data-src="https://meetings-eu1.hubspot.com/meetings/fadi-pola?embed=true"></div>
      <component
        src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
        :is="'script'"
      ></component>
      <AsideFooter />
    </main>
  </div>
</template>

<script>
import AsideFooter from '../components/AsideFooter.vue'
import Navbar from '../components/Navbar.vue'
import metadata from '../mixins/metadata.js'

export default {
  name: 'meetings',
  mixins: [metadata],
  components: {
    AsideFooter,
    Navbar
  }
}
</script>
