<template>
    <div class="enablebanking-cookie">
        <CookiesAlert></CookiesAlert>
    </div>
</template>

<script>
import CookiesAlert from '../components/CookiesAlert.vue'
export default {
  name: 'enablebanking-cookie',
  components: {
    CookiesAlert
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  @import '../styles/app.scss';
}
.alert-dismissible.cookie-alert {
  font-size: 16px;
  z-index: 10;
  padding: 0.75rem 4.5rem 0.75rem 0.75rem;
  position: fixed !important;
  left: 50%;
  line-height: 24px;
  transform: translateX(-50%);
  bottom: 0.5rem;
  opacity: 0.95;
}
</style>
