<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-8 col-lg-9 order-2 order-md-1">
        <ul class="nav nav-pills mb-1">
          <a
            class="nav-link"
            v-for="(language, index) in Object.keys(supportedLanguages)"
            :key="`lang-${index}`"
            :class="{'active': selectedLanguage === language}"
            href="#"
            v-on:click.prevent="setSelectedItem('selectedLanguage', language)"
          >{{supportedLanguages[language]}}</a>
        </ul>
        <!-- eslint-disable -->
        <MainCodeBlock
          :language="selectedLanguage"
          v-if="selectedLanguage === 'java' && selectedType === 'auth' && renderComponent"
        >
          <span v-pre>
import com.enablebanking.ApiClient;
import com.enablebanking.api.AuthApi;
import com.enablebanking.model.ConnectorSettings;
import com.enablebanking.model.NordeaConnectorSettings;

// Initialize settings.
ConnectorSettings settings = new NordeaConnectorSettings()
    .country("FI")
    .clientId("client-id")
    .clientSecret("client-secret")
    .signKeyPath("/path/to/qseal/key/or/its/uri")
    .sandbox(true);
    .redirectUri("https://your-redirect-uri");

// Create client instance.
ApiClient apiClient = new ApiClient(settings);

// Create authentication interface.
AuthApi authApi = new AuthApi(apiClient);

// Get authentication URL.
String authUrl = authApi.getAuth(
    "test", // state to pass to redirect URL
    null // no access parameter (requesting consent for default AISP scope)
).getUrl();
          </span>
        </MainCodeBlock>
        <MainCodeBlock
          :language="selectedLanguage"
          v-if="selectedLanguage === 'java' && selectedType === 'account' && renderComponent"
        >
          <span v-pre>
import com.enablebanking.ApiClient;
import com.enablebanking.api.AispApi;
import com.enablebanking.model.ConnectorSettings;
import com.enablebanking.model.HalAccounts;
import com.enablebanking.model.NordeaConnectorSettings;

// Initialize settings.
ConnectorSettings settings = new NordeaConnectorSettings()
    .country("FI")
    .clientId("client-id")
    .clientSecret("client-secret")
    .signKeyPath("/path/to/qseal/key/or/its/uri")
    .sandbox(true)
    .accessToken("access-token-of-an-authenticated-user")
    .consentId("id-of-consent-given-by-the-user")
    .redirectUri("https://your-redirect-uri");

// Create client instance.
ApiClient apiClient = new ApiClient(settings);

// Create account information interface.
AispApi aispApi = new AispApi(apiClient);

// Get list of accounts.
HalAccounts accounts = aispApi.getAccounts();
          </span>
        </MainCodeBlock>
        <MainCodeBlock
          :language="selectedLanguage"
          v-if="selectedLanguage === 'java' && selectedType === 'payment' && renderComponent"
        >
          <span v-pre>
import com.enablebanking.ApiClient;
import com.enablebanking.api.PispApi;
import com.enablebanking.model.ConnectorSettings;
import com.enablebanking.model.HalPaymentRequestCreation;
import com.enablebanking.model.NordeaConnectorSettings;
import com.enablebanking.model.PaymentRequestResource;

// Initialize settings.
ConnectorSettings settings = new NordeaConnectorSettings()
    .country("FI")
    .clientId("client-id")
    .clientSecret("client-secret")
    .signKeyPath("/path/to/qseal/key/or/its/uri")
    .sandbox(true)
    .redirectUri("https://your-redirect-uri");

// Create client instance.
ApiClient apiClient = new ApiClient(settings);

// Create payment initiation interface.
PispApi pispApi = new PispApi(apiClient);

// Create payment request.
PaymentRequestResource prr = new PaymentRequestResource()
    .creditTransferTransaction(Arrays.asList(
        new CreditTransferTransaction()
            .paymentId(
                new PaymentIdentification()
                    .endToEndId("payment-id-visible-to-end-user"))
            .instructedAmount(
                new AmountType()
                    .currency("EUR")
                    .amount(new BigDecimal("13.50")))
            .beneficiary(
                new Beneficiary()
                    .creditor(
                        new PartyIdentification()
                            .name("Recipient Name"))
                    .creditorAccount(
                        new AccountIdentification()
                            .iban("FI2112345600000785")))
            .remittanceInformation(
                new UnstructuredRemittanceInformation() {{
                    add("PSD2 money transfer") }})));

// Initiate payment request
HalPaymentRequestCreation c = pispApi.makePaymentRequest(prr);
          </span>
        </MainCodeBlock>
        <MainCodeBlock
          :language="selectedLanguage"
          v-if="selectedLanguage === 'javascript' && selectedType === 'auth' && renderComponent"
        >
          <span v-pre>
// Require the Enable Banking library.
const enablebanking = require('enablebanking');

// Create client instance.
const client = new enablebanking.ApiClient('Nordea', {
  sandbox: true,
  consentId: null,
  accessToken: null,
  redirectUri: 'https://your-redirect-uri',
  country: 'FI',
  clientId: 'client-id',
  clientSecret: 'client-secret',
  signKeyPath: '/path/to/qseal/key/or/its/uri'
});

// Create authentication interface.
const authApi = new enablebanking.AuthApi(client);

// Get authentication URL.
const { url } = await authApi.getAuth({
  state: 'test' // state to pass to redirect URL
});
          </span>
        </MainCodeBlock>
        <MainCodeBlock
          :language="selectedLanguage"
          v-if="selectedLanguage === 'javascript' && selectedType === 'account' && renderComponent"
        >
          <span v-pre>
// Require the Enable Banking library.
const enablebanking = require('enablebanking');

// Create client instance.
const client = new enablebanking.ApiClient('Nordea', {
  sandbox: true,
  consentId: 'consent (if receive during authorization)',
  accessToken: 'token retrieved during authorization',
  redirectUri: 'https://your-redirect-uri',
  country: 'FI',
  clientId: 'client-id',
  clientSecret: 'client-secret',
  signKeyPath: '/path/to/qseal/key/or/its/uri'
});

// Create account information interface.
const aispApi = new enablebanking.AISPApi(client);

// Get list of accounts.
const accounts = await aispApi.getAccounts({});
          </span>
        </MainCodeBlock>
        <MainCodeBlock
          :language="selectedLanguage"
          v-if="selectedLanguage === 'javascript' && selectedType === 'payment' && renderComponent"
        >
          <span v-pre>
// Require the Enable Banking library.
const enablebanking = require('enablebanking');

// Create client instance.
const client = new enablebanking.ApiClient('Nordea', {
  sandbox: true,
  redirectUri: 'https://your-redirect-uri',
  country: 'FI',
  clientId: 'client-id',
  clientSecret: 'client-secret',
  signKeyPath: '/path/to/qseal/key/or/its/uri'
});

// Create payment initiation interface.
const pispApi = new enablebanking.PISPApi(client);

// Create payment request.
const requestCreation = await pispApi.makePaymentRequest({
  creditTransferTransaction: [{
    instructedAmount: {
      amount: '12.3', // amount
      currency: 'EUR' // currency code
    },
    beneficiary: {
      creditor: {
        name: 'Creditor Name' // payee (merchant) name
      },
      creditorAccount: {
        iban: 'FI3420301544117544' // payee account number
      }
    }
  }],
  debtor: {
    name: 'Debtor Name' // payer (account holder) name
  },
  debtorAccount: {
    iban: 'FI6120301544118028' // payer account number
  }
});
          </span>
        </MainCodeBlock>
        <MainCodeBlock
          :language="selectedLanguage"
          v-if="selectedLanguage === 'python' && selectedType === 'auth' && renderComponent"
        >
          <span v-pre>
# Import the Enable Banking library
import enablebanking

# Create client instance.
client = enablebanking.ApiClient(
    'Nordea',
    {
        'country': 'FI',
        'clientId': 'client-id',
        'clientSecret': 'client-secret',
        'signKeyPath': '/path/to/qseal/key/or/its/uri',
        'sandbox': True,
        'redirectUri': 'https://your-redirect-uri',
    })

# Create authentication interface.
auth_api = enablebanking.AuthApi(client)

# Get authentication URL.
url = auth_api.get_auth(
     state='test', # state to pass to redirect URL
).url
          </span>
        </MainCodeBlock>
        <MainCodeBlock
          :language="selectedLanguage"
          v-if="selectedLanguage === 'python' && selectedType === 'account' && renderComponent"
        >
          <span v-pre>
# Import the Enable Banking library
import enablebanking

# Create client instance.
client = enablebanking.ApiClient(
    'Nordea',
    {
        'country': 'FI',
        'clientId': 'client-id',
        'clientSecret': 'client-secret',
        'signKeyPath': '/path/to/qseal/key/or/its/uri',
        'sandbox': True,
        'redirectUri': 'https://your-redirect-uri',
        'accessToken': 'access-token-of-an-authenticated-user',
        'consentId': 'id-of-consent-given-by-the-user',
    })

# Create authentication interface.
aisp_api = enablebanking.AISPApi(client)

# Get list of accounts.
accounts = aisp_api.get_accounts()
          </span>
        </MainCodeBlock>
        <MainCodeBlock
          :language="selectedLanguage"
          v-if="selectedLanguage === 'python' && selectedType === 'payment' && renderComponent"
        >
          <span v-pre>
# Import the Enable Banking library
import enablebanking

# Create client instance.
client = enablebanking.ApiClient(
    'Nordea',
    {
        'country': 'FI',
        'clientId': 'client-id',
        'clientSecret': 'client-secret',
        'signKeyPath': '/path/to/qseal/key/or/its/uri',
        'sandbox': True,
        'redirectUri': 'https://your-redirect-uri',
    })

# Create payment initation interface.
pisp_api = enablebanking.PISPApi(client)

# Create payment request.
request_creation = pisp_api.make_payment_request(
    enablebanking.PaymentRequestResource(
        credit_transfer_transaction=[
            enablebanking.CreditTransferTransaction(
                instructed_amount=enablebanking.AmountType(
                    amount='12.3', # amount
                    currency='EUR'), # currency code
                beneficiary=enablebanking.Beneficiary(
                    creditor=enablebanking.PartyIdentification(
                        name='Creditor Name'), # payee (merchant) name
                    creditor_account=enablebanking.AccountIdentification(
                        iban='FI3420301544117544'), # payee account number
                ),
            ),
        ],
        debtor=enablebanking.PartyIdentification(
            name='Debtor Name'), # payer (account holder) name
        debtor_account=enablebanking.AccountIdentification(
            iban='FI6120301544118028'), # payer account number
    ))
          </span>
        </MainCodeBlock>
        <!-- eslint-enable -->
      </div>
      <div class="col-12 col-md-4 col-lg-3 order-1 order-md-2">
        <nav class="nav flex-column nav-pills w-auto mb-2">
          <a
            class="nav-link"
            v-for="(codeType, index) in Object.keys(types)"
            :key="`codeType-${index}`"
            :class="{'active': selectedType === codeType}"
            href="#"
            v-on:click.prevent="setSelectedItem('selectedType', codeType)"
          >{{types[codeType]}}</a>
        </nav>
        <hr />
        <a href="/docs/sdk/latest/" class="nav-link text-primary font-weight-bold">Full SDK reference &rarr;</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MainCodeBlock from './MainCodeBlock'

export default {
  data() {
    return {
      selectedLanguage: 'java',
      selectedType: 'auth',
      types: {
        auth: 'Authorization',
        account: 'Account information',
        payment: 'Payment initiation'
      },
      renderComponent: true
    }
  },
  computed: {
    ...mapState({
      supportedLanguages: state => state.supportedLanguages
    })
  },
  components: {
    MainCodeBlock
  },
  methods: {
    setSelectedItem(ref, value) {
      this[ref] = value
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    }
  }
}
</script>
