<template>
    <div class="form-group">
        <form class="bg-light rounded p-3 " @submit.prevent="formSubmit">
            <textarea type="text" class="form-control mb-3" rows="8" placeholder="Refresh to get a new template" required :value="playload"
                @input="$emit('playload', $event.target.value)"></textarea>
            <p class="text-center small mb-1 mt-1">For more optional feilds, please check <a class="text-success" :href="instuctionUrl" target="_blank">here</a></p>

            <button class="btn btn-primary" :disabled="formLoading" type="submit">
              <span
                v-if="formLoading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            Add</button>
        </form>
    </div>
</template>
<script>
export default {
  name: 'MockForm',
  data() {
    return {
      formLoading: false
    }
  },
  props: {
    playload: {
      type: String
    },
    handleSubmit: {
      type: Function,
      required: true
    },
    instuctionUrl: {
      type: String,
      default: 'https://enablebanking.com/docs/api/reference/'
    }
  },
  methods: {
    async formSubmit() {
      this.formLoading = true
      await this.handleSubmit()
      this.formLoading = false
    }
  }
}
</script>
