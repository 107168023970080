<template>
  <section class="container py-5">
    <h3 class="mb-3">Accounts</h3>
    <div v-if="accounts === null">
      <LoaderPic />Loading...
    </div>
    <div v-else>
      <div v-if="accounts.length === 0">
        <p class="mb-3"> No accounts </p>
      </div>
      <div v-else>
        <table class="table mb-3 table-hover table-fixed">
          <thead>
            <tr>
              <th scope="col" style="width: 30%;">Name</th>
              <th scope="col" style="width: 20%;">Cash Account Type</th>
              <th scope="col" style="width: 20%;">Currency</th>
              <th scope="col" style="width: 20%;">Details</th>
              <th scope="col" style="width: 10%;"></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(account, i) in accounts">
              <tr>
                <td style="cursor: pointer;" @click="toggleRow(i)">{{ account.title }}</td>
                <td style="cursor: pointer;" @click="toggleRow(i)">{{ account.playload.cashAccountType }}</td>
                <td style="cursor: pointer;" @click="toggleRow(i)">{{ account.playload.currency }}</td>
                <td>
                  <router-link :to="{ name: 'mockAccountData' , params: { id: account.playload.resourceId }}">
                    <button class="btn btn-primary btn-sm"> Balances & Transactions </button>
                  </router-link>
                </td>
                <td>
                  <button class="btn btn-link btn-sm float-right" :disabled="loading"
                    @click="removeAccount(account.playload.resourceId)">
                    <font-awesome-icon icon='fa-solid fa-x' />
                  </button>
                </td>
              </tr>
              <tr v-if="selectedRows.includes(i)" class="no-hover">
                <td :colspan="5" class="small">
                  <MainCodeBlock language="JSON">{{ account.playload }}</MainCodeBlock>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <h4>Add a new account</h4>
      <div class="form-group mb-3">
        <form class="bg-light rounded p-3 " @submit.prevent="createAccount">
          <input type="text" class="form-control mb-3" rows="1" placeholder="Name" required v-model="title" />
          <textarea type="text" class="form-control mb-3" rows="8" placeholder="Refresh to get a new template" required
            v-model="playload"></textarea>
          <p class="text-center small mb-1 mt-1">For more optional feilds, please check <a class="text-success"
              href="https://enablebanking.com/docs/api/reference/#accountresource" target="_blank">here</a></p>

          <button class="btn btn-primary" :disabled="formLoading" type="submit">
            <span v-if="formLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Add</button>
        </form>
      </div>
      <h4>Upload accounts data</h4>
      <div class="bg-light rounded p-3">
        <label class="drop-area mb-3"><input type="file" @change="previewFiles" accept=".json" />{{uploadNote}}</label>
        <p class="text-center small mb-3 mt-1">
          You can export data from a real bank account using <a class="text-success"
            href="https://tilisy.com/" target="_blank">our account information demo UI</a>.
          You need to choose JSON format and enter FREEEXPORT code before exporting data.<br>
          Please note that data uploaded using this form will be stored in our systems until it is removed by you.
          Sample data file can be downloaded <a class="text-success"
              href="https://enablebanking.com/sample-data/DK-Danske_Bank-synthetic-1.json" target="_blank">here</a></p>
        <button class="btn btn-primary" :disabled="uploading" @click="uploadJson">
          <span v-if="uploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Upload</button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import metadata from '../mixins/metadata.js'
import MainCodeBlock from '../components/Main/MainCode/MainCodeBlock.vue'
import LoaderPic from '../components/elements/Loader/LoaderPic.vue'
import axios from 'axios'

export default {
  name: 'MockASPSP',
  mixins: [metadata],
  data() {
    return {
      title: null,
      accounts: null,
      playload: this.generateAccountJson(),
      formLoading: false,
      jsonFileData: null,
      uploadNote: 'Drop .json file here or click to select',
      uploading: false,
      selectedRows: []
    }
  },
  async mounted() {
    await this.fetchAccounts()
  },
  computed: {
    ...mapGetters(['loading', 'idToken'])
  },
  components: {
    LoaderPic,
    MainCodeBlock
  },
  methods: {
    ...mapActions(['pushAlertMessage', 'setLoading']),
    toggleRow(rowIndex) {
      if (this.selectedRows.includes(rowIndex)) {
        const index = this.selectedRows.indexOf(rowIndex)
        this.selectedRows.splice(index, 1)
      } else {
        this.selectedRows.push(rowIndex)
      }
    },
    async fetchAccounts() {
      try {
        const res = await axios({
          method: 'get',
          url: '/api/mock_accounts',
          headers: {
            Authorization: 'Bearer ' + this.idToken.token
          }
        })
        this.accounts = res.data.accounts
      } catch (e) {
        this.pushAlertMessage({ message: `Something went wrong: ${e}` })
      }
    },
    pickRandom(arr) {
      return arr[(Math.random() * arr.length) | 0]
    },
    generateAccountJson() {
      return JSON.stringify({
        name: this.pickRandom(['Aino', 'Akseli', 'Ella', 'Onni', 'Oliver']) + ' ' + this.pickRandom(['Virtanen', 'Korhonen', 'Mäkinen', 'Nieminen', 'Hämäläinen']),
        cash_account_type: this.pickRandom(['CACC', 'CARD']),
        currency: 'EUR',
        usage: 'PRIV',
        bic_fi: 'BNKAFRPPXXX',
        psu_status: 'Co-account Holder'
      }, null, 2)
    },
    async removeAccount(resourceId) {
      this.setLoading(true)
      try {
        await axios({
          method: 'delete',
          url: `/api/mock_accounts/${resourceId}`,
          headers: {
            Authorization: 'Bearer ' + this.idToken.token
          }
        })
        this.pushAlertMessage({ message: 'Account has been removed', type: 'info' })
        await this.fetchAccounts()
      } catch (e) {
        this.pushAlertMessage({ message: `Something went wrong: ${e}` })
      } finally {
        this.setLoading(false)
      }
    },
    async createAccount() {
      this.formLoading = true
      var bodyFormData = new FormData()
      bodyFormData.append('title', this.title)
      bodyFormData.append('playload', this.playload)
      try {
        await axios({
          method: 'post',
          url: '/api/mock_accounts',
          data: bodyFormData,
          headers: {
            Authorization: 'Bearer ' + this.idToken.token
          }
        })
        this.title = null
        this.playload = this.generateAccountJson()
        this.pushAlertMessage({ message: 'Account has been added', type: 'info' })
      } catch (e) {
        this.pushAlertMessage({ message: e.response.data.error.message })
      }
      await this.fetchAccounts()
      this.formLoading = false
    },
    previewFiles(event) {
      const reader = new FileReader()
      const file = event.target.files[0]
      this.uploadNote = file.name
      reader.onload = (e) => {
        // Read file contents
        const content = e.target.result
        this.jsonFileData = JSON.parse(content)
      }
      reader.readAsText(file)
    },
    async uploadJson() {
      if (this.jsonFileData === null) {
        this.pushAlertMessage({ message: 'No json file is loaded' })
      } else {
        this.uploading = true
        try {
          await axios.post('/api/mock_upload_json', this.jsonFileData,
            {
              headers: {
                Authorization: 'Bearer ' + this.idToken.token
              }
            })
          this.pushAlertMessage({ message: 'Data from the .json file has been added', type: 'info' })
        } catch (e) {
          this.pushAlertMessage({ message: e.response.data.error.message })
        }
      }
      this.uploading = false
      await this.fetchAccounts()
    }
  }
}
</script>

<style>
.drop-area {
  height: 6em;
  line-height: 6em;
  width: 100%;
  background-color: white;
  border: 1px dotted black;
  border-radius: 5px;
  text-align: center
}

input[type=file] {
  display: none;
}

.no-hover {
  background-color: transparent !important;
}

.table-fixed {
  table-layout: fixed;
}
</style>
