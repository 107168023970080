<template>
  <div class="row">
    <form class="col-12 col-lg-9" @submit.prevent="showModal">
      <div class="mb-4">
        <label>Number of developers</label>
        <input
          type="number"
          class="form-control mw-200"
          placeholder="Number of developers"
          min="1"
          :value="numberOfDevelopers"
          v-on:input="setStateValue('numberOfDevelopers', $event.target.value)"
        />
      </div>
      <div class="mb-3">
        <ChipsItem
          v-for="(language, index) in Object.keys(supportedLanguages)"
          :key="`language-${index}`"
          class="mr-2 mb-2"
          :text="supportedLanguages[language]"
          v-on:change="setStateValue('selectedLanguage', language)"
          :checked="selectedLanguage === language"
          :unselectable="true"
        />
      </div>
      <div class="mb-3">
        <ChipsItem
          v-for="(func, index) in Object.keys(functions)"
          :key="`function-${index}`"
          class="mr-2 mb-2"
          :text="functions[func]"
          v-on:change="setSectedFunctions(func)"
          :checked="selectedFunctions.includes(func)"
          :disabled="func === 'PIIS'"
        />
      </div>
    </form>
    <div class="col-12 col-lg-3 text-center p-3" v-if="price && price > 0">
      <p class="h1 mb-0">€{{ price.toFixed() }}</p>
      <p>per month</p>
      <button class="btn btn-primary" @click="showModal">Get license</button>
      <b-modal
        ref="licenseModalRef"
        size="lg"
        hide-footer
        title="Development license request"
        id="license-modal"
      >
        <MainOrder ref="licenseOrderRef" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { BModal } from 'bootstrap-vue/src/components/modal'
import ChipsItem from '../elements/Chips/ChipsItem.vue'
import MainOrder from './MainOrder'

export default {
  name: 'MainCalc',
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      functions: (state) => state.functions,
      supportedLanguages: (state) => state.supportedLanguages,
      selectedFunctions: (state) => state.selectedFunctions,
      selectedLanguage: (state) => state.selectedLanguage,
      numberOfDevelopers: (state) => state.numberOfDevelopers
    }),
    price() {
      return +this.numberOfDevelopers * 50 * this.selectedFunctions.length
    }
  },
  components: {
    ChipsItem,
    BModal,
    MainOrder
  },
  methods: {
    setSectedFunctions(value) {
      const selectedFunctionsSet = new Set(this.selectedFunctions)

      if (selectedFunctionsSet.has(value)) {
        selectedFunctionsSet.delete(value)
      } else {
        selectedFunctionsSet.add(value)
      }
      this.setStateValue('selectedFunctions', [...selectedFunctionsSet])
    },
    showModal() {
      this.$refs.licenseModalRef.show()
    },
    hideModal() {
      this.$refs.licenseModalRef.hide()
    },
    setStateValue(key, value) {
      const options = {
        key,
        value
      }
      this.$store.commit('changeStateValue', options)
    }
  }
}
</script>
