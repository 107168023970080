<template>
  <div>
    <Navbar />
    <main>
      <section class="container pb-5">
        <h1 class="text-center py-3 py-md-5">Test Service Terms and Conditions</h1>

        <p>The test service refered in this Terms and Conditions is only for internal use in Enable Banking Oy, such as development and testing of Open Banking API integrations included into Enable Banking aggregation core.</p>

        <p>We may update this page from time to time for operational, legal or regulatory reasons.</p>

        <p>For more information about our test service, or if you have questions, please contact us by e-mail at <a href="mailto:hello@enablebanking.com">hello@enablebanking.com</a>.</p>
      </section>

      <AsideFooter />

    </main>
  </div>
</template>

<script>
import AsideFooter from '../components/AsideFooter.vue'
import Navbar from '../components/Navbar.vue'
import metadata from '../mixins/metadata.js'

export default {
  name: 'test-service-terms',
  mixins: [metadata],
  components: {
    AsideFooter,
    Navbar
  }
}
</script>
