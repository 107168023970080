<template>
  <div>
    <AisInstantSearch :search-client="searchClient" index-name="banks" class="row pt-4">
      <div class="col-12 col-md-6 col-lg-4">
        <p class="lead">Countries</p>
        <img
          v-for="country in countries"
          class="bank-card__flag mr-2 mb-2"
          :key="country"
          :src="require(`../../assets/countries/${country}.svg`)"
          :alt="countriesList[country]"
        />
      </div>

      <div class="col-12 col-md-6 col-lg-3">
        <p class="lead">APIs integrated</p>
        <span class="lead text-primary"
          ><b>{{ apis }}</b>
        </span>
      </div>

      <div class="col-12 col-md-6 col-lg-5">
        <p class="lead">Check</p>
        <router-link to="/open-banking-apis" class="lead">full list of ASPSPs and integrated APIs →</router-link>
      </div>
    </AisInstantSearch>
  </div>
</template>

<script>
import algoliaInit from '../../algolia.js'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      apis: 0,
      countries: [],
      searchClient: algoliaInit
    }
  },
  computed: {
    ...mapState({
      countriesList: state => state.countriesList
    })
  },
  mounted() {
    this.searchClient
      .initIndex('banks')
      .search({ query: '*', facets: ['country', 'scopes'], responseFields: 'facets' })
      .then((r) => {
        this.countries = Object.keys(r.facets.country)
        this.apis = r.facets.scopes.aisp + r.facets.scopes.pisp
      })
  }
}
</script>