<template>
  <div class="container py-5">
    <div class="row mb-4">
      <!-- View toggle -->
      <div class="col-md-4 pb-2">
        <div class="btn-group btn-group-sm btn-group-toggle h-100 w-100" data-toggle="buttons">
          <label class="btn w-50 d-flex align-items-center justify-content-center text-truncate" :class="{'btn-primary': viewMode === 'integrations', 'btn-outline-secondary': viewMode !== 'integrations'}">
            <input type="radio" name="viewMode" id="integrations" value="integrations" v-model="viewMode" :disabled="loading"> Onboarded integrations
          </label>
          <label class="btn w-50 d-flex align-items-center justify-content-center text-truncate" :class="{'btn-primary': viewMode === 'plans', 'btn-outline-secondary': viewMode !== 'plans'}">
            <input type="radio" name="viewMode" id="plans" value="plans" v-model="viewMode" :disabled="loading"> Onboarding plans
          </label>
        </div>
      </div>
      <!-- Plan/broker selection -->
      <div class="col-md-4 pb-2">
        <select class="form-control" id="plan" v-model="selectedPlan" :disabled="loading" v-if="viewMode === 'plans'">
          <option value="">Select an onboarding plan</option>
          <option v-for="plan in plans" :key="plan.id" :value="plan.id">{{ plan.plan_name }}</option>
        </select>
        <select class="form-control" id="plan" v-model="selectedBroker" :disabled="loading" v-if="viewMode === 'integrations'">
          <option value="">Select a broker</option>
          <option v-for="broker in brokers" :key="broker" :value="broker">{{ brokerNames[broker] || broker }}</option>
        </select>
      </div>
    </div>

    <!-- Broker details - shown when a plan is selected -->
    <div v-if="(selectedPlan || selectedBroker) && brokerDetails" class="row mb-4">
      <div class="col-12">
        <div class="card">
          <div class="card-header bg-light">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-2" v-if="brokerDetails.title">
                  <strong>Broker:</strong> {{ brokerDetails.title }}
                </div>
              </div>
              <div class="col-md-6">
                <div v-if="brokerDetails.environments" class="mb-2">
                  <strong class="mr-2">Environments:</strong>
                  <span v-for="(env, envName) in brokerDetails.environments" :key="envName" class="ms-3 mt-1">
                    <span>{{ envName.toLowerCase() }}</span>
                    <span v-if="env.services && env.services.length">
                      ({{ env.services.join(', ') }})
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-2" v-if="brokerDetails.countries && brokerDetails.countries.length">
                  <strong>Countries:</strong> {{ brokerDetails.countries.join(', ') }}
                </div>
              </div>
            </div>
          </div>
          <div class="card-body" v-if="viewMode === 'plans'">
            <!-- Task Statistics -->
            <div v-if="onboardings.length > 0" class="row">
              <div v-for="(stat, index) in taskStats" :key="index" class="col text-center">
                <div class="mb-2 py-2">
                  <h4>{{ stat.percentage }}%</h4>
                  <span class="badge" :class="getBadgeClass(stat.status)">{{ formatStatus(stat.status) }}</span>
                  <div class="mt-1 small text-muted">{{ stat.count }} {{ stat.count === 1 ? 'task' : 'tasks' }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Filters - only shown when a plan is selected -->
    <div v-if="(selectedPlan || selectedBroker) && !loading" class="row mb-4">
      <div class="col-md-3">
        <label for="countryFilter" class="form-label">Country</label>
        <select class="form-control" id="countryFilter" v-model="selectedCountry">
          <option value="">All countries</option>
          <option v-for="country in countries.sort((a, b) => countriesList[a].localeCompare(countriesList[b]))" :key="country" :value="country">{{ countriesList[country] }}</option>
        </select>
      </div>
      <div class="col-md-3">
        <label for="environmentFilter" class="form-label">Environment</label>
        <select class="form-control" id="environmentFilter" v-model="selectedEnvironment">
          <option value="">All environments</option>
          <option v-for="env in environments" :key="env" :value="env">
            {{ String(env).charAt(0).toUpperCase() + String(env).slice(1) }}
          </option>
        </select>
      </div>
      <div class="col-md-3">
        <label for="serviceFilter" class="form-label">Services</label>
        <select class="form-control" id="serviceFilter" v-model="selectedService">
          <option value="">All services</option>
          <option v-for="service in services" :key="service" :value="service">{{ service }}</option>
        </select>
      </div>
      <div class="col-md-3">
        <label for="statusFilter" class="form-label">Status</label>
        <select class="form-control" id="statusFilter" v-model="selectedStatus">
          <option value="">All statuses</option>
          <option v-for="status in statuses" :key="status" :value="status">{{ formatStatus(status) }}</option>
        </select>
      </div>
    </div>

    <!-- Loading indicator -->
    <div v-if="loading"><LoaderPic />Loading...</div>

    <!-- Onboardings list -->
    <div v-else class="row">
      <div class="col-12">
        <!-- No plan selected message -->
        <div v-if="viewMode === 'plans' && !selectedPlan" class="alert alert-info">
          Please select a plan to view its onboarding tasks.
        </div>

        <!-- Integrations view message -->
        <div v-if="viewMode === 'integrations' && !selectedBroker" class="alert alert-info">
          Please select a broker to view onboarded integrations.
        </div>

        <!-- No tasks found message when plan is selected but no tasks are available -->
        <div v-else-if="selectedPlan  && onboardings.length === 0" class="alert alert-warning">
          No onboarding tasks found for the selected plan.
        </div>

        <!-- No integrations found message when broker is selected but no tasks are available -->
        <div v-else-if="selectedBroker  && onboardings.length === 0" class="alert alert-warning">
          No integrations onboarded for the selected broker.
        </div>

        <!-- Tasks list -->
        <div v-else class="list-group">
          <div v-for="onboarding in filteredOnboardings" :key="onboarding.id" class="list-group-item">
            <div class="d-flex justify-content-between align-items-start">
              <div class="flex-grow-1">
                <h5 class="mb-2">{{ onboarding.connectorName }}</h5>

                <!-- Display additional task information in two columns -->
                <div class="row">
                  <div :class="viewMode === 'plans' ? 'col-md-6' : 'col-md-12'">
                    <div v-if="onboarding.connectorDetails && onboarding.connectorDetails.brands && onboarding.connectorDetails.brands.length" class="small text-muted">
                      <strong>Brands:</strong>
                      <span v-if="expandedBrands.includes(onboarding.id)">
                        {{ onboarding.connectorDetails.brands.map(b => b.name).join(', ') }}
                        <a href="#" @click.prevent="expandedBrands.splice(expandedBrands.indexOf(onboarding.id), 1)" class="ms-1">Collapse</a>
                      </span>
                      <span v-else-if="onboarding.connectorDetails.brands.length <= 3">
                        {{ onboarding.connectorDetails.brands.map(b => b.name).join(', ') }}
                      </span>
                      <span v-else>
                        {{ onboarding.connectorDetails.brands[0].name }}
                        <a href="#"
                           @click.prevent="expandedBrands.push(onboarding.id)"
                           class="ms-1">
                          and {{ onboarding.connectorDetails.brands.length - 1 }} more
                        </a>
                      </span>
                    </div>
                    <div v-if="onboarding.connectorDetails && onboarding.connectorDetails.countries && onboarding.connectorDetails.countries.length" class="small text-muted">
                      <strong>Countries:</strong> {{ onboarding.connectorDetails.countries.join(', ') }}
                    </div>
                    <div v-if="onboarding.environments && onboarding.environments.length" class="small text-muted">
                      <strong>Environments:</strong> {{ onboarding.environments.join(', ') }}
                    </div>
                    <div v-if="onboarding.connectorDetails && onboarding.connectorDetails.scopes && onboarding.connectorDetails.scopes.length" class="small text-muted">
                      <strong>Services:</strong>
                      {{ onboarding.connectorDetails.scopes.map(s => ({aisp: 'AIS', pisp: 'PIS'})[s]).join(', ') }}
                    </div>
                    <div v-if="onboarding.connectorDetails && onboarding.connectorDetails.psu_types && onboarding.connectorDetails.psu_types.length" class="small text-muted">
                      <strong>Usage:</strong> {{ onboarding.connectorDetails.psu_types.join(', ') }}
                    </div>
                  </div>

                  <div class="col-md-6" v-if="viewMode === 'plans'">
                    <div v-if="onboarding.actionTypes && onboarding.actionTypes.length" class="small text-muted">
                      <strong>Action Types:</strong> {{ onboarding.actionTypes.map(t => ({original: 'original onboarding', renewal: 'onboarding renewal'})[t]).join(', ') }}
                    </div>
                    <div v-if="onboarding.assigned != null" class="small text-muted">
                      <strong>Assigned:</strong> {{ onboarding.assigned ? 'yes' : 'no' }}
                    </div>
                    <div v-if="onboarding.createdAt" class="small text-muted">
                      <strong>Created:</strong> {{ new Date(onboarding.createdAt).toLocaleString() }}
                    </div>
                    <div v-if="onboarding.updatedAt" class="small text-muted">
                      <strong>Updated:</strong> {{ new Date(onboarding.updatedAt).toLocaleString() }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="ms-2">
                <span
                  class="badge"
                  :class="getBadgeClass(onboarding.status)"
                >
                  {{ formatStatus(onboarding.status) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import axios from 'axios'
import LoaderPic from '../components/elements/Loader/LoaderPic.vue'

export default {
  name: 'Onboardings',
  components: {
    LoaderPic
  },
  data() {
    return {
      loading: false,
      viewMode: 'integrations', // Default view mode is 'plans'
      plans: [],
      selectedBroker: '',
      selectedPlan: '',
      selectedStatus: '',
      selectedCountry: '',
      selectedEnvironment: '',
      selectedService: '',
      onboardings: [],
      brokerDetails: null,
      expandedBrands: [],
    }
  },
  computed: {
    ...mapGetters(['idToken']),
    ...mapState({
      countriesList: (state) => state.countriesList,
      brokerNames: (state) => state.brokerOriginsNameMap
    }),
    // Get unique list of statuses from onboardings
    statuses() {
      return [...new Set(this.onboardings.map(o => o.status))].sort()
    },
    brokers() {
      const brokerOrigins = this.idToken.claims.brokerOrigins
      if (!brokerOrigins) {
        return []
      }
      return brokerOrigins.split(',')
    },
    // Get unique list of countries from all onboardings
    countries() {
      const countries = new Set()
      this.onboardings.forEach(onboarding => {
        // Check for countries in connectorDetails (new API format)
        if (onboarding.connectorDetails && onboarding.connectorDetails.countries &&
            Array.isArray(onboarding.connectorDetails.countries)) {
          onboarding.connectorDetails.countries.forEach(country => countries.add(country))
        }
      })
      return [...countries].sort()
    },
    // Get unique list of environments from onboardings
    environments() {
      const environments = new Set()
      this.onboardings.forEach(onboarding => {
        if (onboarding.environments && Array.isArray(onboarding.environments)) {
          onboarding.environments.forEach(env => environments.add(env))
        }
      })
      return [...environments].sort()
    },
    // Get unique list of services from onboardings based on scopes
    services() {
      const services = new Set()
      this.onboardings.forEach(onboarding => {
        if (onboarding.connectorDetails && onboarding.connectorDetails.scopes &&
            Array.isArray(onboarding.connectorDetails.scopes)) {
          onboarding.connectorDetails.scopes.forEach(scope => {
            // Map scopes to service names
            const service = {aisp: 'AIS', pisp: 'PIS'}[scope]
            if (service) {
              services.add(service)
            }
          })
        }
      })
      return [...services].sort()
    },
    // Calculate task statistics by status
    taskStats() {
      // Define all possible statuses we want to show
      const allStatuses = ['todo', 'in_progress', 'issue', 'postponed', 'done', 'verified'];
      const totalTasks = this.onboardings.length;

      // Count tasks by status
      const statusCounts = this.onboardings.reduce((counts, task) => {
        counts[task.status] = (counts[task.status] || 0) + 1;
        return counts;
      }, {});

      // Create stats array with percentage and count for each status
      return allStatuses.map(status => {
        const count = statusCounts[status] || 0;
        const percentage = totalTasks > 0 ? Math.round((count / totalTasks) * 100) : 0;

        return {
          status,
          count,
          percentage
        };
      });
    },

    // Filter onboardings based on selected status, country, environment, and service
    filteredOnboardings() {
      return this.onboardings.filter(onboarding => {
        const matchesStatus = !this.selectedStatus || onboarding.status === this.selectedStatus

        // Check if environment matches
        const matchesEnvironment = !this.selectedEnvironment ||
          (onboarding.environments &&
           Array.isArray(onboarding.environments) &&
           onboarding.environments.includes(this.selectedEnvironment))

        // Check if service matches
        let matchesService = !this.selectedService

        if (this.selectedService && onboarding.connectorDetails &&
            onboarding.connectorDetails.scopes &&
            Array.isArray(onboarding.connectorDetails.scopes)) {
          // Check if the onboarding has the selected service based on scope mapping
          matchesService = onboarding.connectorDetails.scopes.includes({AIS: 'aisp', PIS: 'pisp'}[this.selectedService])
        }

        // Check if country matches (new API format)
        let matchesCountry = !this.selectedCountry

        // Check in connectorDetails.countries array
        if (this.selectedCountry && onboarding.connectorDetails &&
            onboarding.connectorDetails.countries &&
            Array.isArray(onboarding.connectorDetails.countries)) {
          if (onboarding.connectorDetails.countries.includes(this.selectedCountry)) {
            matchesCountry = true
          }
        }

        // Also check in aspsps data (legacy format)
        if (this.selectedCountry && !matchesCountry && onboarding.aspsps) {
          matchesCountry = onboarding.aspsps.some(aspsp =>
            aspsp.countries && aspsp.countries.includes(this.selectedCountry)
          )
        }

        return matchesStatus && matchesCountry && matchesEnvironment && matchesService
      })
    }
  },
  created() {
    this.fetchPlans();
  },
  watch: {
    selectedPlan(newValue) {
      if (newValue) {
        this.fetchTasks(newValue);
      } else {
        // Reset data when no plan is selected
        this.onboardings = [];
        this.brokerDetails = null;
        this.selectedStatus = '';
        this.selectedCountry = '';
        this.selectedEnvironment = '';
        this.selectedService = '';
      }
    },
    selectedBroker(newValue) {
      if (newValue) {
        this.fetchIntegrations(newValue);
      } else {
        // Reset data when no plan is selected
        this.onboardings = [];
        this.brokerDetails = null;
        this.selectedStatus = '';
        this.selectedCountry = '';
        this.selectedEnvironment = '';
        this.selectedService = '';
      }
    },
    viewMode(newValue) {
      // Reset selected plan when switching view modes
      this.selectedPlan = '';
      this.selectedBroker = '';
      this.selectedStatus = '';
      this.selectedCountry = '';
      this.selectedEnvironment = '';
      this.selectedService = '';
      this.onboardings = [];
      this.brokerDetails = null;

      // If switching to plans view, make sure plans are loaded
      if (newValue === 'plans' && this.plans.length === 0) {
        this.fetchPlans();
      }
    }
  },
  methods: {
    async fetchPlans() {
      this.loading = true;

      try {
        const response = await axios({
          method: 'get',
          url: '/api/onboarding/plans',
          headers: {
            Authorization: 'Bearer ' + this.idToken.token
          }
        });

        this.plans = response.data.plans || [];
      } catch (error) {
        console.error('Error fetching plans:', error);
        this.$store.dispatch('pushAlertMessage', {
          message: 'Unable to fetch plans'
        });
      } finally {
        this.loading = false;
      }
    },

    async fetchTasks(planId) {
      this.loading = true;
      this.onboardings = [];
      this.brokerDetails = null;
      this.expandedBrands = [];

      try {
        const response = await axios({
          method: 'get',
          url: `/api/onboarding/tasks?plan=${planId}`,
          headers: {
            Authorization: 'Bearer ' + this.idToken.token
          }
        });

        // Extract broker details if available
        if (response.data.broker) {
          this.brokerDetails = response.data.broker;
        }

        // Transform the API response to match our component's data structure
        if (response.data.tasks && response.data.tasks.length > 0) {
          this.onboardings = response.data.tasks.map(task => {
            // Transform onboarding_actions into environments and actionTypes
            const environments = new Set()
            const actionTypes = new Set()

            if (task.onboarding_actions && Array.isArray(task.onboarding_actions)) {
              task.onboarding_actions.forEach(action => {
                if (action === 'production_original') {
                  environments.add('production')
                  actionTypes.add('original')
                } else if (action === 'production_qsealc_renewal' || action === 'production_qwac_renewal') {
                  environments.add('production')
                  actionTypes.add('renewal')
                } else if (action === 'sandbox_original') {
                  environments.add('sandbox')
                  actionTypes.add('original')
                } else if (action === 'sandbox_qsealc_renewal' || action === 'sandbox_qwac_renewal') {
                  environments.add('sandbox')
                  actionTypes.add('renewal')
                }
              })
            }

            return {
              id: task.id,
              connectorName: task.connector_name,
              status: task.status,
              // Store connector_details in connectorDetails property
              connectorDetails: task.connector_details ? {
                brands: task.connector_details.brands ? [...task.connector_details.brands] : [],
                countries: task.connector_details.countries ? [...task.connector_details.countries] : [],
                scopes: task.connector_details.scopes ? [...task.connector_details.scopes] : [],
                psu_types: task.connector_details.psu_types ? [...task.connector_details.psu_types] : []
              } : null,
              // We don't have aspsps data in the API response, so we'll leave it undefined
              // This is handled in the computed properties
              createdAt: task.created_at,
              updatedAt: task.updated_at,
              assigned: task.assigned,
              onboardingActions: task.onboarding_actions,
              environments: [...environments],
              actionTypes: [...actionTypes]
            };
          });
        }
      } catch (error) {
        console.error('Error fetching tasks:', error);
        this.$store.dispatch('pushAlertMessage', {
          message: 'Unable to fetch onboarding tasks'
        });
      } finally {
        this.loading = false;
      }
    },

    async fetchIntegrations(brokerId) {
      this.loading = true;
      this.onboardings = [];
      this.brokerDetails = null;
      this.expandedBrands = [];

      try {
        const response = await axios({
          method: 'get',
          url: `/api/onboarding/integrations?broker=${brokerId}`,
          headers: {
            Authorization: 'Bearer ' + this.idToken.token
          }
        });

        // Extract broker details if available
        if (response.data.broker) {
          this.brokerDetails = response.data.broker;
        }

        // Transform the API response to match our component's data structure
        if (response.data.integrations && response.data.integrations.length > 0) {
          this.onboardings = response.data.integrations.map(integr => {
            return {
              id: integr.id,
              connectorName: integr.connector_name,
              status: integr.status,
              // Store connector_details in connectorDetails property
              connectorDetails: integr.connector_details ? {
                brands: integr.connector_details.brands ? [...integr.connector_details.brands] : [],
                countries: integr.connector_details.countries ? [...integr.connector_details.countries] : [],
                scopes: integr.connector_details.scopes ? [...integr.connector_details.scopes] : [],
                psu_types: integr.connector_details.psu_types ? [...integr.connector_details.psu_types] : []
              } : null,
              // We don't have aspsps data in the API response, so we'll leave it undefined
              // This is handled in the computed properties
              createdAt: null,
              updatedAt: null,
              assigned: null,
              onboardingActions: null,
              environments: integr.connector_details.environments,
              actionTypes: []
            };
          });
        }
      } catch (error) {
        console.error('Error fetching integrations:', error);
        this.$store.dispatch('pushAlertMessage', {
          message: 'Unable to fetch onboarded integrations'
        });
      } finally {
        this.loading = false;
      }
    },

    // Format the status from API to match our UI conventions
    formatStatus(status) {
      if (!status) return 'Unknown';

      // Map API status values to UI-friendly formats
      const statusMap = {
        'todo': 'To-do',
        'in_progress': 'In progress',
        'done': 'Done',
        'verified': 'Verified',
        'issue': 'Issues',
        'postponed': 'Postponed',
      };

      return statusMap[status.toLowerCase()] || String(status).charAt(0).toUpperCase() + String(status).slice(1);
    },

    // Get the appropriate badge class based on status
    getBadgeClass(status) {
      const statusMap = {
        // Onboarding tasks specific statuses
        'todo': 'dark',
        'in_progress': 'info',
        'done': 'primary',
        'verified': 'success',
        'issue': 'warning',
        'postponed': 'secondary',
        // Integrations specific statuses
        'available': 'info',
        'deprecated': 'secondary',
      };

      return 'badge-' + (statusMap[status] || 'light');
    }
  }
}
</script>

<style scoped>
.form-label {
  margin-bottom: 0.5rem;
}

.badge {
  padding: 0.5em 0.75em;
  font-size: 0.875em;
  font-weight: 500;
  white-space: nowrap;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.bg-light {
  background-color: #f8f9fa !important;
}
</style>