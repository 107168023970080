<template>
    <div class="enablebanking-integrations">
      <AisInstantSearch :search-client="searchClient" index-name="banks">
        <div class="countries">
          <p class="countries-title">Countries</p>
          <div class="countries-flags">
            <img
              v-for="country in countries"
              class="countries-flag"
              :key="country"
              :src="require(`../assets/countries/${country}.svg`)"
              :alt="countriesList[country]"
            />
          </div>
        </div>
        <div class="apis">
          <p class="apis-title">APIs integrated</p>
          <span class="apis-count">{{ apis }}</span>
        </div>
      </AisInstantSearch>
    </div>
</template>
  
<script>
  import algoliaInit from '../algolia.js'
  import { mapState } from 'vuex'
  
  export default {
    data() {
      return {
        apis: 0,
        countries: [],
        searchClient: algoliaInit
      }
    },
    computed: {
      ...mapState({
        countriesList: state => state.countriesList
      })
    },
    mounted() {
      this.searchClient
        .initIndex('banks')
        .search({ query: '*', facets: ['country', 'scopes'], responseFields: 'facets' })
        .then((r) => {
          this.countries = Object.keys(r.facets.country)
          this.apis = r.facets.scopes.aisp + r.facets.scopes.pisp
        })
    }
  }
</script>
  
<style lang="scss" scoped>
  .countries {
    .countries-flag {
      width: 1.6rem;
      height: 1.6rem;
      margin-right: 0.8rem;
      margin-bottom: 0.8rem;
    }
  }
</style>
