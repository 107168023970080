<template>
  <div class="mw-400 p-1">
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <span class="my-2 mr-3">{{ file.name }}</span>
      <div class="text-nowrap">
        <button v-on:click="$parent.download(file)" class="btn btn-light btn-sm">Download</button>
        <button
          v-on:click="copyCurl"
          class="btn btn-primary btn-sm ml-2"
        >{{ curlShown ? 'Hide' : 'Copy' }} CURL</button>
      </div>
    </div>
    <div class="my-1">
      <input v-show="curlShown" ref="curl" :value="curl" class="form-control" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'PackageFile',
  data() {
    return {
      curlShown: false
    }
  },
  props: {
    file: {
      type: Object
    }
  },
  computed: {
    curl() {
      // https://firebasestorage.googleapis.com/v0/b/packages.enablebanking.com/o
      return `curl '${window.location.origin}/_packages/${encodeURIComponent(
        this.file.fullPath
      )}?alt=media' -H "Authorization: Bearer ${this.$parent.idToken.token}" -o '${this.file.name}'`
    }
  },
  methods: {
    copyCurl() {
      this.curlShown = !this.curlShown
      setTimeout(() => {
        this.$refs.curl.focus()
        this.$refs.curl.select()
        document.execCommand('copy')
      }, 50)
    }
  }
}
</script>

<style lang="scss" scoped>
.mw-400 {
  max-width: 600px;
}
</style>
