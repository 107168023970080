<template>
  <section class="container py-5">
    <div v-if="loading">
      <LoaderPic />Loading...
    </div>
    <p v-else-if="!packages.length" class="lead">
      You don't have access to any of the packages.
      <br />Please get in touch with us at
      <a href="mailto:hello@enablebanking.com">hello@enablebanking.com</a>, we'll
      be happy to help you getting the access.
    </p>
    <div v-for="pkg in packages" :key="pkg.id">
      <h2>{{ pkg.name }}</h2>
      <div v-for="ver in pkg.versions" :key="ver.name">
        <h3 class="pt-4">
          {{ ver.name }}
          <small>({{ ver.created.toLocaleString() }})</small>
        </h3>
        <PackageFile
          v-for="(file, index) in ver.files"
          :key="file.name"
          :class="{ 'bg-light': index % 2 }"
          :file="file"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { firebaseApp } from '../firebase.js'
import PackageFile from '../components/PackageFile.vue'
import metadata from '../mixins/metadata.js'
import LoaderPic from '../components/elements/Loader/LoaderPic.vue'

export default {
  name: 'packages',
  data() {
    return {
      packagesBucketOrigin: `gs://${process.env.VUE_APP_PACKAGES_BUCKET}`,
      packages: [],
      loading: false
    }
  },
  mixins: [metadata],
  components: {
    PackageFile,
    LoaderPic
  },
  computed: {
    ...mapGetters(['user', 'idToken'])
  },
  methods: {
    download(file) {
      const xhttp = new XMLHttpRequest()
      xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          const downloadUrl = URL.createObjectURL(xhttp.response)
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.style = 'display: none'
          a.href = downloadUrl
          a.download = `${file.name}`
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(downloadUrl)
        }
      }
      xhttp.open('GET', `/_packages/${encodeURIComponent(file.fullPath)}?alt=media`, true)
      xhttp.responseType = 'blob'
      xhttp.setRequestHeader('Authorization', `Bearer ${this.idToken.token}`)
      xhttp.send()
    }
  },
  async mounted() {
    if (!this.idToken.claims.packages) {
      return
    }
    this.loading = true
    const storage = firebaseApp.storage(this.packagesBucketOrigin)
    const res = await storage.ref().child(`${this.idToken.claims.packages}/`).listAll()

    const versions = []
    for (const prefix of res.prefixes) {
      const version = {
        name: prefix.name
      }
      const pres = await storage
        .ref()
        .child(prefix.fullPath + '/')
        .listAll()
      if (pres.items.length) {
        version.files = pres.items
        const meta = await storage.ref().child(version.files[0].fullPath).getMetadata()
        version.created = new Date(meta.timeCreated)
        versions.unshift(version)
      }
    }
    this.loading = false
    versions.sort((a, b) => b.created - a.created)
    this.packages.push({
      id: this.idToken.claims.packages,
      name: this.idToken.claims.packages,
      versions: versions
    })

    // Checking if there is GET parameter "download"
    const params = new URLSearchParams(window.location.search)
    const download = params.get('download')
    if (!download) {
      return
    }
    try {
      const storage = firebaseApp.storage(this.packagesBucketOrigin)
      const file = storage.ref().child(download)
      await file.getMetadata()
      this.download(file)
    } catch (e) {
      alert(`Unable to download ${download}`)
    }
  }
}
</script>
