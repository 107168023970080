<template>
    <div class="enablebanking-footer">
        <AsideFooter :isCustomElement="true"></AsideFooter>
    </div>
</template>

<script>
import AsideFooter from '../components/AsideFooter.vue'
export default {
  name: 'enablebanking-footer',
  components: {
    AsideFooter
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  @import '../styles/app.scss';
}
</style>
