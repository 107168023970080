<template>
    <div class="enablebanking-header">
        <Navbar :isCustomElement="true"></Navbar>
    </div>
</template>

<script>
import Navbar from '../components/Navbar.vue'
export default {
  name: 'enablebanking-header',
  components: {
    Navbar
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  @import '../styles/app.scss';
}
.enablebanking-header {
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  .navbar {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif;
    height: inherit;
    line-height: 1.5;
    .nav-item > a:hover {
      margin-bottom: 0;
      border-bottom: 0;
    }
  }
}
</style>
