<template>
  <div>
    <label class="small d-block mt-3">Supported usage</label>
    <AisRefinementList attribute="open_banking.psu_types" :sort-by="['name:asc']" class="list-unstyled" :isRefined="true">
      <div slot-scope="{ items, refine }">
        <ChipsItem
          v-for="(psuType, index) in items"
          :key="`psuType-${index}`"
          class="mr-2 mb-2 text-capitalize"
          :text="psuType.label"
          v-on:change="refine(psuType.label)"
          :checked="psuType.isRefined"
        />
        <span v-if="!items.length">No results</span>
      </div>
    </AisRefinementList>
  </div>
</template>

<script>
import ChipsItem from '../../elements/Chips/ChipsItem.vue'

export default {
  components: {
    ChipsItem
  }
}
</script>
