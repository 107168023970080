<template>
  <div>
    <Navbar />
    <main>
      <div class="container">
        <div class="d-flex justify-content-between align-items-center flex-wrap pt-4">
          <nav class="nav nav-pills nav-fill d-block d-sm-flex">
            <router-link class="nav-link" active-class="active" :to="{ name: 'packages' }" v-if="packages"
              >Core packages</router-link>
            <router-link class="nav-link" active-class="active" :to="{ name: 'applications' }"
              >API applications</router-link
            >
            <router-link class="nav-link" active-class="active" :to="{ name: 'onboardings' }" v-if="onboardings"
              >Onboardings<sup class="ml-1">β</sup></router-link
            >
            <router-link class="nav-link" active-class="active" :to="{ name: 'aspsps' }"
              >ASPSP status</router-link
            >
            <router-link class="nav-link" active-class="active" :to="{ name: 'data-insights' }">Data insights</router-link>
            <router-link class="nav-link" active-class="active" :to="{ name: 'mock-aspsp' }">Mock ASPSP</router-link>
            <router-link class="nav-link" active-class="active" :to="{ name: 'profile' }">User profile</router-link>
          </nav>
          <a href="#" @click.prevent="handleLogout" class="text-dark py-1">Logout →</a>
        </div>
      </div>

      <router-view></router-view>

      <AsideFooter />
    </main>
  </div>
</template>

<script>
import AsideFooter from '../components/AsideFooter.vue'
import Navbar from '../components/Navbar.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Personal',
  components: {
    AsideFooter,
    Navbar
  },
  computed: {
    ...mapGetters(['idToken']),
    onboardings() {
      return this.idToken && this.idToken.claims && this.idToken.claims.brokerOrigins
    },
    packages() {
      return this.idToken && this.idToken.claims && this.idToken.claims.packages
    },
    dataInsights() {
      return this.idToken && this.idToken.claims && this.idToken.claims.enabledFeatures && this.idToken.claims.enabledFeatures.includes('dataInsights')
    }
  },
  methods: {
    ...mapActions(['logout', 'listenTokenChange']),
    handleLogout() {
      this.logout()
      this.$router.push({ name: 'home' })
    }
  },
  mounted() {
    this.listenTokenChange()
  }
}
</script>
