<template>
  <div>
    <Navbar />
    <div class="container-fluid">
      <AisInstantSearch :search-client="searchClient" index-name="banks" class="row">
        <!-- :routing="routing" -->
        <AisConfigure :hitsPerPage="50" :distinct="true" />
        <BanksFilter class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" />
        <BanksList class="col-12 col-sm-6 col-md-6 col-lg-8 col-xl-9 bg-gradient mvh100" />
      </AisInstantSearch>
    </div>
  </div>
</template>

<script>
import BanksList from '@/components/banks/BanksList/BanksList.vue'
import BanksFilter from '@/components/banks/BanksFilter/BanksFilter.vue'
import Navbar from '../components/Navbar.vue'
import { history as historyRouter } from 'instantsearch.js/es/lib/routers'
import { simple as simpleMapping } from 'instantsearch.js/es/lib/stateMappings'
import metadata from '../mixins/metadata.js'
import algoliaInit from '../algolia.js'

export default {
  name: 'home',
  data() {
    return {
      searchClient: algoliaInit,
      routing: {
        router: historyRouter(),
        stateMapping: simpleMapping()
      }
    }
  },
  mixins: [metadata],
  components: {
    BanksList,
    BanksFilter,
    Navbar
  }
}
</script>
