<template>
  <aside class="sidebar h-100">
    <div class="row h-100">
      <div
        class="sidebar__content col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 h-100 d-flex flex-column justify-content-between"
      >
        <div class="px-lg-3">
          <h5 class="my-4 type-mono">Open Banking APIs</h5>
          <AisSearchBox class="mb-3" :show-loading-indicator="true" />
          <div :class="['sidebar__filters', {'sidebar__filters--hidden': !isShowFilterMobile}]">
            <BanksFilterCountry class="mb-3" />
            <BanksFilterImplementation class="mb-3" />
            <BanksFilterPsuTypes class="mb-3" />
            <aisClearRefinements>
              <a
                slot-scope="{ canRefine, refine, createURL }"
                :href="createURL()"
                @click.prevent="refine"
                v-if="canRefine"
                class="btn btn-light btn-sm mb-3"
              >Clear filters</a>
            </aisClearRefinements>
          </div>
          <a
            href="#"
            class="d-inline d-sm-none dashed-link"
            v-on:click.prevent="isShowFilterMobile = !isShowFilterMobile"
          >{{isShowFilterMobile ? 'Hide filter' : 'Show filter'}}</a>
        </div>
        <div class="py-3 px-lg-3">
          <div class="pb-2">Enable Banking Oy © {{year}}</div>
          <div class="pb-3" style="line-height: 1em;">
            <small>
              <router-link
                to="/trademark-disclaimer"
                class="text-muted"
              >All trademarks, logos and brand names are the property of their respective owners.</router-link>
            </small>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import BanksFilterCountry from './BanksFilterCountry.vue'
import BanksFilterImplementation from './BanksFilterImplementation.vue'
import BanksFilterPsuTypes from './BanksFilterPsuTypes.vue'

export default {
  data() {
    return {
      searchKey: '',
      isShowFilterMobile: false,
      year: new Date().getFullYear()
    }
  },
  components: {
    BanksFilterCountry,
    BanksFilterImplementation,
    BanksFilterPsuTypes
  }
}
</script>
