<template>
  <div>
    <AisInfiniteHits :transform-items="transformItems">
      <div
        slot-scope="{ items, isLastPage, refine }"
        class="px-md-4 py-4 d-flex flex-wrap justify-content-center relative w-100"
      >
        <BanksListItem v-for="(bank, index) in items" :key="`bank-${index}`" :info="bank" />
        <div class="text-center w-100" v-if="!isLastPage">
          <a href="#" v-on:click.prevent="refine" class="mx-auto dashed-link text-white" :disabled="isLastPage"
            >Show more</a
          >
        </div>
        <AisStats v-else class="text-center w-100">
          <p slot-scope="{ nbHits }" class="text-center pt-4">
            <span v-if="nbHits" class="text-white">Count of banks: {{ nbHits }}</span>
            <span v-else>
              <img src="../../../assets/sad.svg" alt="not found" class="my-4" />
              <span class="d-block lead text-white">No banks found. Try to change search criteria</span>
            </span>
          </p>
        </AisStats>
      </div>
    </AisInfiniteHits>

    <b-modal
      ref="bankInfoModalRef"
      size="lg"
      hide-header
      hide-footer
      centered
      lazy
      no-close-on-backdrop
      title="Bank information"
      class="modal-transform-origin"
    >
      <BanksListItemBack
        :info="defaultBankInfo"
        v-on:close="hideModal"
        v-if="defaultBankInfo && defaultBankInfo.objectID"
      />
    </b-modal>
  </div>
</template>

<script>
import BanksListItem from './BanksListItem.vue'
import BanksListItemBack from './BanksListItemBack'
import algoliaInit from '../../../algolia'
import { BModal } from 'bootstrap-vue/src/components/modal'

export default {
  name: 'BanksList',
  data() {
    return {
      defaultBankInfo: {}
    }
  },
  components: {
    BanksListItem,
    BanksListItemBack,
    BModal
  },
  mounted() {
    const index = algoliaInit.initIndex('banks')
    const paramId = this.$route.params.id

    if (paramId) {
      index.getObjects([paramId, paramId.toUpperCase(), paramId.toLowerCase()], (err, content) => {
        if (!err && content.results) {
          switch (true) {
            case !!content.results[0]:
              this.defaultBankInfo = content.results[0]
              break
            case !!content.results[1]:
              this.defaultBankInfo = content.results[1]
              break
            case !!content.results[2]:
              this.defaultBankInfo = content.results[2]
              break
            default:
              this.hideModal()
          }
        } else {
          this.hideModal()
        }
      })
      this.showModal()
    }
  },
  methods: {
    transformItems(items) {
      return items.filter((item) => item.name)
    },
    showModal() {
      document.body.dataset.modalOpenCount = 0 // In of case prerendered modal
      this.$refs.bankInfoModalRef.show()
    },
    hideModal() {
      this.$refs.bankInfoModalRef.hide()
      this.$router.push({ name: 'banks' })
    }
  }
}
</script>
