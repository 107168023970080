<template>
  <section class="container py-4">
    <router-link :to="{name: 'applications'}" class="mb-3 d-inline-flex text-decoration-none">
      <span class="material-icons-outlined">chevron_left</span> All applications
    </router-link>
    <div v-if="loading || app === null"><LoaderPic />Loading...</div>
    <div v-else-if="app">
      <h5 class="mb-4">
        <span class="mr-2">Payment settings {{ app.kid }} ({{ app.environment }})</span>
      </h5>
    <h6 class="mb-3">Linked Payment Accounts</h6>
    <div v-if="accounts === null">
      <LoaderPic />Loading...
    </div>
    <div v-else>
      <div v-if="accounts.length === 0">
        <p class="mb-3"> No linked accounts </p>
      </div>
      <div v-else>
        <table class="table mb-3">
          <thead>
            <tr>
              <th scope="col">UID</th>
              <th scope="col">Account ID</th>
              <th scope="col">Name</th>
              <th scope="col">Type</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="account in accounts" :key="account.uid">
              <td>{{ account.uid }}</td>
              <td>{{ convertAccountId(account) }}</td>
              <td>{{ account.name }}</td>
              <td>{{ account.account_type }}</td>
              <td>
                <button class="btn btn-link btn-sm ml-5" :disabled="removing" @click="removeAccount(account.uid)">
                  <font-awesome-icon icon='fa-solid fa-x' />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <PaymentAccountsLinking :app=app @linking-finished="fetchAccounts" />
  </div>
  </section>
</template>

<script>
import MockTable from '../components/MockTable.vue'
import PaymentAccountsLinking from '../components/applications/PaymentAccountsLinking.vue'
import LoaderPic from '../components/elements/Loader/LoaderPic.vue'
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ApplicationPaymentSettings',
  components: {
    MockTable,
    PaymentAccountsLinking,
    LoaderPic
  },
  data() {
    return {
      accounts: null,
      app: null,
      appId: '',
      removing: false
    }
  },
  async mounted() {
    if (!this.applications.length) {
      await this.getApplications()
    }
    const appId = this.$route.params.id
    this.appId = appId
    this.app = this.applications.find(app => app.kid === appId)
    await this.fetchAccounts()
  },
  computed: {
    ...mapGetters(['loading', 'applications', 'idToken']),
  },
  methods: {
    ...mapActions(['pushAlertMessage', 'setLoading', 'getApplications']),
    convertAccountId(account) {
      let account_id = `UID ${account.uid}`
      const accIds = account.account_ids
      if(accIds.length > 0) {
        account_id = `${accIds[0].scheme_name} ${accIds[0].identification}`
      }
      return account_id;
    },
    async fetchAccounts() {
      try {
        const res = await axios({
          method: 'get',
          url: `/api/payment_accounts?appId=${this.appId}`,
          headers: {
            Authorization: 'Bearer ' + this.idToken.token
          }
        })
        this.accounts = res.data.paymentAccounts
      } catch (e) {
        this.pushAlertMessage({ message: `Something went wrong: ${e}` })
      }
    },
    async removeAccount(uid) {
      try {
        this.removing = true
        await axios({
          method: 'delete',
          url: `/api/payment_accounts?appId=${this.appId}&uid=${uid}`,
          headers: {
            Authorization: 'Bearer ' + this.idToken.token
          }
        })
        await this.fetchAccounts(this.appId)
      } catch (e) {
        this.pushAlertMessage({ message: `Something went wrong: ${e}` })
      } finally {
        this.removing = false
      }
    }
  }
}
</script>
