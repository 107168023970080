<template>
  <div>
    <Navbar />
    <main>
      <section class="container pb-5">
        <h1 class="py-3 py-md-5">Privacy Notice</h1>
        <p>{{ 'Last updated:' }} {{ new Date("2025-03-17").toLocaleDateString("EN", { year: 'numeric', month: 'long', day: 'numeric' }) }}</p>
        <vue-markdown class="job-description">
        <!-- eslint-disable -->
This Privacy Notice explains how personal information is processed when you interact with the Enable Banking website and the Enable Banking Control Panel available there. Particularly when you register new applications, manage existing applications, monitor the usage of the Enable Banking API and use other functionality of the Enable Banking Control Panel.

Enable Banking Website and the Enable Banking Control Panel are owned and operated by Enable Banking Oy, located in Otakaari 5, 02150 Espoo, Finland, company ID 2988499-7 (“Enable Banking”, “the company", “our", “we” or “us”).

This notice is aligned with Enable Banking's Privacy and Personal Data Protection Policy which is built to enforce our commitment to data protection and compliance with applicable legislation relating to processing of personal data and data protection and especially the EU General Data Protection Regulation (EU 2016/679).

## Nature and Purpose of Processing

The purpose of processing your personal data is to:

* Allow you to interact with us, for instance by submitting a contact form or reserving a meeting;
* Maintain your user accounts of the Enable Banking Control Panel;
* Provide you with the ability to register new applications using the Enable Banking API, activate and modify your applications, as well as monitor the usage of the Enable Banking API by applications;
* Send you information about us and updates about our products, including Enable Banking API and Enable Banking Control Panel.

The legal basis for processing your data is:

* Visitors to the Enable Banking website accept the privacy notice by interacting with it. They are informed of this through a notification bar displayed on every page until they explicitly dismiss it. Additionally, visitors are reminded of the privacy notice when submitting any form, including contact forms, meeting booking forms, and user registration forms. By submitting a form, they acknowledge and accept the privacy notice.

## Type of Personal Data and Categories of Data Subjects

Enable Banking processes the following categories of personal data when you use the Enable Banking website and Control Panel:

* **Contact Information**: Email address, phone number, first name, last name.
* **Organisation Information**: Organisation name.
* **Uploaded Data**: Unstructured information submitted through the contact forms, as well as mock data uploaded to the Enable Banking Control Panel.

The categories of data subjects include personnel from current and prospective customers of Enable Banking, as well as users of the Enable Banking API who seek information about Enable Banking or wish to review or terminate their Data Sharing Consents.

## Applicable Data Protection Measures

Enable Banking implements appropriate technical and organisational measures to ensure the security of your personal data, including protection against unauthorised or unlawful processing and against accidental loss, destruction, or damage. Enable Banking has assessed relevant risks and documented the measures it takes to mitigate the risks. Internally this is communicated through Data Protection Impact Assessments. Password-less authentication is used (one-time authentication links sent via email) for the Enable Banking Control Panel. Also two factor authentication via one-time SMS codes can be enabled for access to the Enable Banking Control Panel.

## Your Rights and Responsibilities

As a data subject, you have the following rights:

* **The right to be informed**: You have the right to know what personal data is collected and how it is used.
* **The right of access**: You have the right to access the personal data held about you.
* **The right to rectification**: You have the right to correct any inaccurate or incomplete personal data held about you.
* **The right to erasure**: You have the right to have your personal data deleted under certain circumstances.
* **The right to restrict processing**: You have the right to restrict the processing of your personal data under certain circumstances.
* **The right to data portability**: You have the right to receive your personal data in a structured, commonly used, and machine-readable format and to transmit it to another controller.
* **The right to object**: You have the right to object to the processing of your personal data under certain circumstances.

## Object and Duration of Data Processing

Your personal information is used for the following purposes:

* **Communication to you**: To allow Enable Banking personnel to send you information about Enable Banking API and other products, answer your questions and to provide you with technical support.
* **Access to the Enable Banking Control Panel**: To register, activate, and modify applications using the Enable Banking API track and analyse how the Enable Banking API is being used by applications, including status of integrations towards Account Servicing Payment Service Providers (ASPSPs).

Personal data is retained indefinitely.

## Sharing Your Personal Information

We may share your Personal Information with the following parties:

* **Cloud service providers**: To make the Enable Banking website including the Enable Banking Control Panel available to you, we cloud services provided by the following companies:
  * Google Cloud EMEA Limited
    70 Sir John Rogerson’s Quay, Dublin 2, Ireland
    Location of data processing: EEA
    *The provider's services are used for authentication of Enable Banking Control Panel users and the API enabling functionality of the Enable Banking Website.*
  * Amazon Web Services EMEA SARL
    Avenue John F. Kennedy 38, Luxembourg, 1855, Luxembourg
    Location of data processing: EEA
    *The provider's services are used for backing up data stored by the Google Cloud EMEA Limited.*
  * Netlify, Inc.
    512 2nd Street, Suite 200, San Francisco, CA 94107, USA
    Location of data processing: EEA and USA
    *The provider's services are used for hosting of the static content used by the Enable Banking Website, as well as routing other requests made to enablebanking.com domain to other services. However, none of your personal data is stored using the provider's services.*
  * HubSpot Ireland Ltd
    1 Sir John Rogerson's Quay, Dublin, 2, Ireland
    Location of data processing: EEA
    *The provider's services are used for customer relationship management and service desk operations.*
  * Squarespace Ireland Limited
    Squarespace House, Ship Street Great, D08 N12C, Ireland
    Location of data processing: USA
    *The provider's services are used for Enable Banking Website content management, meaning that most of the pages on the website (excluding pages of the Enable Banking Control Panel) are built using the provider's tools and server by the provider. However, none of your personal data is stored using the provider's services.*
  * Algolia SAS
    55 Rue d'Amsterdam, 75008 Paris, France
    Location of data processing: EEA
    *The provider's services are used for making search functionality of the Enable Banking Website, particularly in the documentation and the list of integrated APIs.*
  * Google LLC
    1600 Amphitheatre Parkway, Mountain View, CA 94043, USA
    Location of data processing: EEA and USA
    *The provider's Google Analytics service is used for analytics on how the Enable Banking Website including the Enable Banking Control Panel is used by its visitors.*
  * Twilio Ireland Limited
    70 Sir John Rogerson's Quay, Dublin 2 D02 R296, Ireland
    Location of data processing: EEA and USA
    *The provider's service SendGrid is used for Enable Banking customer communication platform for transactional and marketing email.*

  Enable Banking carefully assesses cloud service providers and how they process your personal information.

* **Authorities**: If required by law, your information may be disclosed to comply with legal obligations.

## Contact Information

If you have any questions or concerns about this Privacy Notice or our data processing practices, please contact us at [support@enablebanking.com](mailto:support@enablebanking.com).

## Changes

This Privacy Notice may be updated from time to time to reflect changes to data processing practices or legal obligations. Any changes will be posted on this webpage. If you registered as a user of the Enable Banking Control Panel, we will notify you about changes in the Privacy Notice by sending you a message to the email address used during the registration.
        </vue-markdown>
        </section>

      <AsideFooter />

    </main>
  </div>
</template>

<script>
import VueMarkdown from '@adapttive/vue-markdown'
import AsideFooter from '../components/AsideFooter.vue'
import Navbar from '../components/Navbar.vue'
import metadata from '../mixins/metadata.js'

export default {
  name: 'privacy',
  mixins: [metadata],
  components: {
    AsideFooter,
    Navbar,
    VueMarkdown
  }
}
</script>
