<template>
  <div>
    <div class="bg-light rounded p-3 mb-3">
      <div class="mb-3 d-flex align-items-start justify-content-between">
        <h6>
          <b-dropdown variant="link" class="settings mr-2" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <span class="material-icons-outlined">more_vert</span>
            </template>
            <b-dropdown-item :to="{ path: `/cp/applications/${app.kid}/requests` }">Requests</b-dropdown-item>
            <b-dropdown-item :to="{ path: `/cp/applications/${app.kid}/statistics` }">Statistics<sup
                class="ml-1">β</sup></b-dropdown-item>
            <b-dropdown-item :to="{ path: `/cp/applications/${app.kid}/edit` }">Edit</b-dropdown-item>
            <b-dropdown-item :to="{ path: `/cp/applications/${app.kid}/share` }">Share</b-dropdown-item>
            <b-dropdown-item :to="{ path: `/cp/applications/${app.kid}/remove` }">Remove</b-dropdown-item>
            <b-dropdown-item v-if="isPaymentSettingsVisible"
              :to="{ path: `/cp/applications/${app.kid}/payments/settings` }">Payment
              settings</b-dropdown-item>
          </b-dropdown>
          <b class="mr-2">{{ app.name }}</b>
          <small>({{ app.kid }})</small>
        </h6>
        <span>
          <small class="badge badge-success" v-if="app.active">Active</small>
          <small class="badge badge-secondary" v-else>Inactive</small>
        </span>
      </div>
      <div class="mb-1">
        <div>
          <strong>Environment:</strong>
          {{ app.environment }}
        </div>
        <div>
          <strong>Services:</strong>
          <ul>
            <li v-for="(service, index) in app.services" :key="`${app.kid}-service-${index}`">
              <div v-if="service == 'AIS'">
                Account Information <small class="badge badge-secondary ml-1"
                  v-if="app.whitelisted_accounts">Restricted</small>
                  <small class="badge badge-warning ml-1"
                  v-if="isPendingActivation">Pending {{ !app.active ? 'activation' : 'unrestriction' }}</small>
              </div>
              <div v-else-if="service == 'PIS'">
                Payment Initiation <small class="badge badge-secondary ml-1"
                  v-if="app.payment_restrictions">Restricted</small>
              </div>
            </li>
          </ul>
        </div>
        <div v-if="app.broker_origin">
          <strong>Broker:</strong>
          {{ brokerName }}
        </div>
        <div v-if="app.description">
          <strong>Description:</strong>
          {{ app.description }}
        </div>
        <div v-if="app.gdpr_email">
          <strong>Data protection email:</strong>
          {{ app.gdpr_email }}
        </div>
        <div v-if="app.privacy_url">
          <strong>Privacy policy URL:</strong>
          {{ app.privacy_url }}
        </div>
        <div v-if="app.terms_url">
          <strong>Terms of service URL:</strong>
          {{ app.terms_url }}
        </div>
      </div>
      <div class="mb-1">
        <strong>Redirect URLs:</strong>
        <ul>
          <li v-for="(url, index) in app.redirect_urls" :key="`${app.kid}-url-${index}`">{{ url }}</li>
        </ul>
      </div>
      <div class="mb-3" v-if="app.whitelisted_accounts && app.whitelisted_accounts.length">
        <strong>Linked accounts:</strong>
        <ul class="mb-0">
          <li v-for="acc in app.whitelisted_accounts" :key="acc.identification_hash">
            {{ acc.aspsp.name }} ({{ countries[acc.aspsp.country] }}): {{ acc.title }}
            <button class="btn btn-sm btn-link p-0" :disabled="unlinking" v-on:click="unlink(acc)">Unlink</button>
          </li>
        </ul>
        <em class="small">Only linked accounts can be accessed. For general
          availability please request unrestriction.</em>
      </div>
      <div class="mb-3" v-else-if="!app.active">
        <em class="small">
          You can link own accounts and use the application with these accounts.
          The application will be activated after account linking is complete.
          Only linked accounts can be accessed.
          For general availability please request activation.
        </em>
      </div>
      <div class="d-flex align-items-center" v-if="(!app.active || app.whitelisted_accounts)">
        <AccountsLinking :app="app" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters, mapState } from 'vuex'
import AccountsLinking from './AccountsLinking.vue'
import { environment } from '../../config'
import { BDropdown, BDropdownItem } from 'bootstrap-vue/src/components/dropdown'

export default {
  data() {
    return {
      environment,
      unlinking: false
    }
  },
  props: {
    app: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters(['idToken']),
    ...mapState({
      countries: (state) => state.countriesList,
      brokerNamesMap: (state) => state.brokerOriginsCodeMap
    }),
    brokerName() {
      if (!this.app.broker_origin) {
        return null
      }
      // getting 3s3jkildwa part from https://broker-3s3jkildwa-ez.a.run.app
      const brokerId = this.app.broker_origin.split('broker-')[1].split('-')[0]
      return this.brokerNamesMap[brokerId]
    },
    isPisEnabled() {
      if (this.app.services && this.app.services.includes('PIS')) {
        return true
      }
      return false
    },
    isPaymentSettingsVisible() {
      return this.isPisEnabled && !this.app.broker_origin
    },
    isPendingActivation() {
      return this.app.pendingActionType == 'activation'
    }
  },
  components: {
    AccountsLinking,
    BDropdown,
    BDropdownItem
  },
  methods: {
    ...mapActions(['pushAlertMessage', 'updateApplication']),
    async unlink(acc) {
      this.unlinking = true
      const query = new URLSearchParams()
      query.set('appId', this.app.kid)
      query.set('identificationHash', acc.identification_hash)
      try {
        await axios.post('/api/unlink_accounts', query.toString(), {
          headers: { Authorization: `Bearer ${this.idToken.token}` }
        })
      } catch (e) {
        this.pushAlertMessage({ message: `Unable to unlink account ${acc.title}` })
      } finally {
        await this.updateApplication(this.app.kid)
        this.unlinking = false
      }
    }
  }
}
</script>

<style lang="scss">
.dropdown.settings button {
  padding: 0;
  line-height: 0;
}
</style>
