<template>
  <div>
    <Navbar getStarted="#get-started" />
    <main>
      <div class="bg-light">
        <article class="container main-section">
          <div class="row align-items-center mb-4">
            <div class="col-12 col-lg-5 col-xl-5">
              <h1 class="pt-4">Enable Banking aggregation core</h1>
              <p>Get direct connection to banks and most of your TPP license with our on-premise aggregation core.</p>
              <a href="#get-started" class="btn btn-primary mr-1" v-if="!user">Get started</a>
              <button class="btn btn-outline-dark" @click="showContactForm">Contact us</button>
            </div>
            <div class="col-12 col-lg-7 col-xl-7 text-right">
              <img
                src="../assets/sdk-architecture.svg"
                class="main-section__image"
                alt
                height="430"
              />
            </div>
          </div>
        </article>
      </div>

      <section class="container py-3 py-md-5" id="why-sdk">
        <h4 class="mb-3 title-pic">Why to choose?</h4>
        <div class="row">
          <div class="col-sm-6 col-md-3">
            <div class="feature">
              <img src="../assets/circle.svg" alt class="feature__pic mb-3" />
              <h6 class="mb-3">Flexibility</h6>
              <ul class="pl-3">
                <li>fine-tune for your environment</li>
                <li>
                  available in
                  <br />different languages
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="feature">
              <img src="../assets/cloud.svg" alt class="feature__pic mb-3" />
              <h6 class="mb-3">Compliance</h6>
              <ul class="pl-3">
                <li>direct access to bank APIs</li>
                <li>users see who accesses their data</li>
              </ul>
            </div>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="feature">
              <img src="../assets/security.svg" alt class="feature__pic mb-3" />
              <h6 class="mb-3">Security</h6>
              <ul class="pl-3">
                <li>
                  no data exposed to
                  <br />3rd parties
                </li>
                <li>available in source code</li>
              </ul>
            </div>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="feature">
              <img src="../assets/piggy-bank.svg" alt class="feature__pic mb-3" />
              <h6 class="mb-3">Predictable costs</h6>
              <ul class="pl-3">
                <li>
                  automatic updates
                  <br />and new APIs
                </li>
                <li>low transactional costs</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section class="container py-3 py-md-5" id="examples">
        <h4 class="mb-3 title-pic">Examples</h4>
        <p>
          We believe that integrating Open Banking should be easy and painless, so we put a lot of effort into making
          our aggregation core developer-friendly. You don’t need to worry about difference of bank APIs, our library automatically
          converts all data and interaction logic to one simple interface.
        </p>
        <MainCodeNav />
      </section>

      <section class="container pt-4 mb-5" id="get-started" ref="getStartedRef">
        <div
          style="position: relative; top: -1.5rem"
          v-for="(opt, index) in Object.keys(getStartedOptions)"
          :id="opt"
          :key="`nav-${index}`"
        ></div>
        <nav class="title-pic">
          <ul class="nav nav-pills mt-2 mb-3 d-inline-flex">
            <a
              class="nav-link"
              v-for="(opt, index) in Object.keys(getStartedOptions)"
              :key="`option-${index}`"
              v-on:click="getStarted = opt"
              :class="{ active: getStarted === opt }"
              :href="`#${opt}`"
              :unselectable="true"
            >{{ getStartedOptions[opt] }}</a>
          </ul>
        </nav>

        <div class="rounded p-4 bg-light" v-if="getStarted === 'connector'">
          <h5 class="mb-4">Test with one sandbox connector</h5>
          <MainConnector :initial-connector="initialConnector" />
        </div>

        <div class="rounded p-4 bg-light" v-if="getStarted === 'license'">
          <h5 class="mb-4">Access all available sandbox connectors</h5>
          <MainCalc />
        </div>
      </section>

      <section class="container pt-4 pb-5 mb-5" id="production">
        <h4 class="mb-3 title-pic">
          Got questions?
          <span class="gradient-text">Contact us</span> →
          <a href="mailto:info@enablebanking.com">info@enablebanking.com</a>
        </h4>
        <div>
          <p>Should you have any questions regarding testing or operating integration with live APIs?</p>
          <p class="mb-4">
            We are happy to assist whether you have a legal question, are looking for a business case or concerned about
            a security matter.
          </p>
          <button class="btn btn-primary" @click="showContactForm">Contact us</button>
          <b-modal
            ref="contactModalRef"
            size="lg"
            hide-footer
            title="Get in touch with us!"
            id="contact"
            v-on:is-form-submitted="isFormSubmitted = $event"
          >
            <p class="mb-5" v-if="!isFormSubmitted">
              Please fill in the form with details about yourself, your business and your needs.
              <br />We're happy to hear from you and we'll get back to you soon.
            </p>
            <MainContact ref="contactFormRef" />
          </b-modal>
        </div>
      </section>
    </main>
    <!-- <MainChat />  -->
    <AsideFooter />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BModal } from 'bootstrap-vue/src/components/modal'
import AsideFooter from '../components/AsideFooter.vue'
import MainCalc from '../components/Main/MainCalc.vue'
import MainCodeNav from '../components/Main/MainCode/MainCodeNav.vue'
import MainContact from '../components/Main/MainContact.vue'
import MainConnector from '../components/Main/MainConnector.vue'
import Navbar from '../components/Navbar.vue'
import metadata from '../mixins/metadata.js'

export default {
  name: 'sdk',
  data() {
    const getStartedOptions = {
      connector: 'Start quickly',
      license: 'Get developer license'
    }
    return {
      getStarted: Object.keys(getStartedOptions).find((k) => window.location.hash === `#${k}`) || 'connector',
      getStartedOptions: getStartedOptions,
      initialConnector: window.location.hash.startsWith('#connector-') ? window.location.hash.substring(11) : '',
      isFormSubmitted: false
    }
  },
  mixins: [metadata],
  methods: {
    showContactForm() {
      this.isFormSubmitted = false
      this.$refs.contactModalRef.show()
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  updated() {
    if (window.location.hash.startsWith('#connector-')) {
      const getStartedRef = this.$refs.getStartedRef
      this.$nextTick(function () {
        setTimeout(() => {
          getStartedRef.scrollIntoView()
        }, 100)
      })
    }
  },
  watch: {
    $route(to, from) {
      const getStarted = Object.keys(this.getStartedOptions).find((k) => to.hash === `#${k}`)
      if (getStarted) {
        this.getStarted = getStarted
      }
    }
  },
  components: {
    AsideFooter,
    BModal,
    MainCalc,
    MainContact,
    MainCodeNav,
    MainConnector,
    Navbar
  }
}
</script>

<style lang="scss"></style>
