<template>
  <div class="text-center loading">
    <LoaderPic />
  </div>
</template>

<script>
import LoaderPic from '../components/elements/Loader/LoaderPic.vue'

export default {
  name: 'Loading',
  components: {
    LoaderPic
  }
}
</script>

<style lang="scss" scoped>
.loading {
  padding-top: 40vh;
}
</style>
