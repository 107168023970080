<template>
  <footer class="py-3 py-md-5 footer bg-footer">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4">
          <p class="mb-4">
            Enable Banking Oy © {{ year }}
          </p>
          <p class="mb-4">Otakaari 5, 02150 Espoo, Finland</p>
          <span
            class="d-block"
          >Registered Account Information Service Provider regulated by Finnish Financial Supervisory Authority (FIN-FSA)</span>
          <p class="mt-5">
            <a href="/ISO27001_CERT_2206_SERTIKA_EN.pdf" target="_blank">
              <img src="../assets/iso27001-sertika.png" alt="certificate" class="certificate-pic" />
            </a>
          </p>
        </div>
        <div class="col-12 col-md-4">
          <p class="mb-4">
            <strong>Features</strong>
          </p>
          <p class="mb-4">
            <CustomLink isCustomElement to="/tpp-infrastructure" class="text-light">TPP Infrastructure as a Service</CustomLink>
          </p>
          <p class="mb-4">
            <CustomLink isCustomElement to="/core" class="text-light">Open Banking aggregation core</CustomLink>
          </p>
          <p class="mt-3 mb-4">
            <strong>Developers</strong>
          </p>
          <p class="mb-4">
            <a href="/docs" class="text-light">Documentation</a>
          </p>
          <p class="mb-4">
            <a href="https://github.com/enablebanking/enablebanking-api-samples" target="_blank" class="text-light">
              Code samples
              <span class="external-link"></span>
            </a>
          </p>
          <p class="mt-3 mb-4">
            <strong>Industries</strong>
          </p>
          <p class="mb-4">
            <a href="/industries/loyalty-and-rewards" class="text-light">Loyalty and Rewards</a>
          </p>
          <p class="mb-4">
            <a href="/industries/credit-and-risk" class="text-light">Credit and Risk</a>
          </p>
        </div>
        <div class="col-12 col-md-4">
          <p class="mb-4">
            <CustomLink isCustomElement to="/open-banking-apis" class="text-light">Open Banking APIs</CustomLink>
          </p>
          <p class="mb-4">
            <a href="/blog" class="text-light">Our Tech Blog</a>
          </p>
          <p class="mb-4">
            <a href="/meetings" class="text-light">Book a meeting with us</a>
          </p>
          <p class="mb-4">
            <CustomLink isCustomElement to="/accounts-api" class="text-light">Account information API</CustomLink>
          </p>
          <p class="mb-4">
            <CustomLink isCustomElement to="/privacy-policy" class="text-light">Privacy Policy</CustomLink>
          </p>
          <p class="mb-4">
            <CustomLink isCustomElement to="/trademark-disclaimer" class="text-light">Trademark Disclaimer</CustomLink>
          </p>
          <p class="mt-5 mb-1">
            <a href="https://www.linkedin.com/company/enablebanking/" target="_blank" class="mr-4"><img src="../assets/svg/linkedin.svg" alt="LinkedIn" class="social" /></a>
            <a href="https://x.com/enablebanking" target="_blank" class="mr-4"><img src="../assets/svg/x.svg" alt="X / Twitter" class="social" /></a>
            <a href="https://www.facebook.com/enablebanking" target="_blank" class="mr-4"><img src="../assets/svg/facebook.svg" alt="Facebook" class="social" /></a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import CustomLink from './elements/CustomLink.vue'
export default {
  data() {
    return {
      year: new Date().getFullYear()
    }
  },
  props: {
    isCustomElement: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CustomLink
  }
}
</script>

<style lang="scss" scoped>
.footer {
  font-size: 16px;
  line-height: 24px;
}
.footer strong {
  font-weight: 700;
}
.certificate-pic {
  width: 100%;
  max-width: 120px;
  height: auto;
}
img.social {
  width: 16px;
  height: 16px;
}
.bg-footer {
  background-color: #000;
  background-image: url(../assets/svg/circles-animated.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
</style>
