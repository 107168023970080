<template>
  <section class="container py-5">

    <div class="mb-5">
      <MockTable :idArray="BalancesTableData.idArray" :title="BalancesTableData.title" :nameArray="BalancesTableData.nameArray" :valueArray="BalancesTableData.valueArray" :detailArray="BalancesTableData.detailArray" @removeItem="removeBalance"></MockTable>
      <MockForm :playload="balancePlayload" @playload="balancePlayload = $event" :handleSubmit="createBalance"
        instuctionUrl="https://enablebanking.com/docs/api/reference/#balanceresource" />
    </div>
    <div>
      <MockTable :idArray="TransacationsTableData.idArray" :title="TransacationsTableData.title" :nameArray="TransacationsTableData.nameArray" :valueArray="TransacationsTableData.valueArray" :detailArray="TransacationsTableData.detailArray" @removeItem="removeTransaction"></MockTable>
      <MockForm :playload="transactionPlayload" @playload="transactionPlayload = $event"
        :handleSubmit="createTransactions"
        instuctionUrl="https://enablebanking.com/docs/api/reference/#transaction" />
    </div>
  </section>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import metadata from '../mixins/metadata.js'
import MockTable from '../components/MockTable.vue'
import MockForm from '../components/MockForm.vue'
import axios from 'axios'

export default {
  name: 'MockAccountData',
  mixins: [metadata],
  data() {
    return {
      balances: null,
      balancePlayload: this.getBalanceJson(),
      transactions: null,
      transactionPlayload: this.getTransactionJson()
    }
  },
  async mounted() {
    const resourceId = this.$route.params.id
    await this.fetchBalances(resourceId)
    await this.fetchTransactions(resourceId)
  },
  computed: {
    ...mapGetters(['loading', 'idToken']),
    BalancesTableData() {
      let valueArray = null
      let idArray = []
      let detailArray = []
      if (this.balances != null) {
        valueArray = []
        this.balances.forEach(balance => {
          var temp = []
          temp.push(balance.playload.name)
          temp.push(balance.playload.balanceType)
          temp.push(balance.playload.balanceAmount.amount)
          temp.push(balance.playload.balanceAmount.currency)
          idArray.push(balance.balanceId)
          valueArray.push(temp)
          detailArray.push(balance.playload)
        })
      }
      return {
        title: 'Balances',
        nameArray: ['Balance Name', 'Balance Type', 'Amount', 'Currency'],
        valueArray: valueArray,
        idArray: idArray,
        detailArray: detailArray
      }
    },
    TransacationsTableData() {
      let valueArray = null
      let idArray = []
      let detailArray = []
      if (this.transactions != null) {
        valueArray = []
        this.transactions.forEach(transaction => {
          var temp = []
          temp.push(transaction.playload.bookingDate)
          temp.push(transaction.playload.status)
          temp.push(transaction.playload.creditDebitIndicator)
          temp.push(transaction.playload.transactionAmount.amount)
          temp.push(transaction.playload.transactionAmount.currency)
          idArray.push(transaction.transactionId)
          valueArray.push(temp)
          detailArray.push(transaction.playload)
        })
      }
      return {
        title: 'Transactions',
        nameArray: ['Booking date', 'Status', 'Credit/Debit', 'Amount', 'Currency'],
        valueArray: valueArray,
        idArray: idArray,
        detailArray: detailArray
      }
    }
  },
  components: {
    MockTable,
    MockForm
  },
  methods: {
    ...mapActions(['pushAlertMessage', 'setLoading']),
    async fetchBalances(resourceId) {
      try {
        const res = await axios({
          method: 'get',
          url: `/api/mock_balances/${resourceId}`,
          headers: {
            Authorization: 'Bearer ' + this.idToken.token
          }
        })
        this.balances = res.data.balances
      } catch (e) {
        this.pushAlertMessage({ message: `Something went wrong: ${e}` })
      }
    },
    async fetchTransactions(resourceId) {
      try {
        const res = await axios({
          method: 'get',
          url: `/api/mock_transactions/${resourceId}`,
          headers: {
            Authorization: 'Bearer ' + this.idToken.token
          }
        })
        this.transactions = res.data.transactions
      } catch (e) {
        this.pushAlertMessage({ message: `Something went wrong: ${e}` })
      }
    },
    pickRandom(arr) {
      return arr[(Math.random() * arr.length) | 0]
    },
    getBalanceJson() {
      return JSON.stringify({
        name: 'Closing accounting balance',
        balance_amount: {
          currency: 'EUR',
          amount: ((Math.random() * (100) + 0.02).toFixed(2)).toString()
        },
        balance_type: this.pickRandom(['CLBD', 'ITAV', 'ITBD'])
      }, null, 2)
    },
    getTransactionJson() {
      let credit_debit_indicator = this.pickRandom(['CRDT', 'DBIT']);
      let entry_reference = Math.random().toString(36).substring(2, 7);
      let transaction_aoumt = ((Math.random() * (10) + 0.02).toFixed(2)).toString();
      let yesterdayTimeStamp = new Date() - 1000 * 60 * 60 * 24 * 1;
      let randomDate = new Date(yesterdayTimeStamp - Math.random()*(1000 * 60 * 60 * 24 * 3)); // random date in 3 days
      let creditor_name = this.pickRandom(['Aino', 'Akseli', 'Ella', 'Onni', 'Oliver']) + ' ' + this.pickRandom(['Virtanen', 'Korhonen', 'Mäkinen', 'Nieminen', 'Hämäläinen']);
      return JSON.stringify({
        entry_reference: entry_reference,
        transaction_amount: {
          currency: 'EUR',
          amount: transaction_aoumt
        },
        creditor: {
          name: creditor_name
        },
        credit_debit_indicator: credit_debit_indicator,
        status: 'BOOK',
        booking_date: new Date(yesterdayTimeStamp).toISOString().slice(0, 10),
        value_date: randomDate.toISOString().slice(0, 10),
        remittance_information: [
          `${creditor_name}-${credit_debit_indicator}-${transaction_aoumt}-${entry_reference}`
        ]
      }, null, 2)
    },
    async removeBalance(balanceId) {
      const resourceId = this.$route.params.id
      this.setLoading(true)
      try {
        await axios({
          method: 'delete',
          url: `/api/mock_balances/${resourceId}/${balanceId}`,
          headers: {
            Authorization: 'Bearer ' + this.idToken.token
          }
        })
        this.pushAlertMessage({ message: 'Balance has been removed', type: 'info' })
        await this.fetchBalances(resourceId)
      } catch (e) {
        this.pushAlertMessage({ message: `Something went wrong: ${e}` })
      } finally {
        this.setLoading(false)
      }
    },
    async createBalance() {
      const resourceId = this.$route.params.id
      var bodyFormData = new FormData()
      bodyFormData.append('playload', this.balancePlayload)
      try {
        await axios({
          method: 'post',
          url: `/api/mock_balances/${resourceId}`,
          data: bodyFormData,
          headers: {
            Authorization: 'Bearer ' + this.idToken.token
          }
        })
        this.balancePlayload = this.getBalanceJson()
        this.pushAlertMessage({ message: 'Balance has been added', type: 'info' })
      } catch (e) {
        this.pushAlertMessage({ message: e.response.data.error.message })
      }
      await this.fetchBalances(resourceId)
    },
    async removeTransaction(transactionId) {
      console.log(transactionId)
      const resourceId = this.$route.params.id
      this.setLoading(true)
      try {
        await axios({
          method: 'delete',
          url: `/api/mock_transactions/${resourceId}/${transactionId}`,
          headers: {
            Authorization: 'Bearer ' + this.idToken.token
          }
        })
        this.pushAlertMessage({ message: 'Transaction has been removed', type: 'info' })
        await this.fetchTransactions(resourceId)
      } catch (e) {
        this.pushAlertMessage({ message: `Something went wrong: ${e}` })
      } finally {
        this.setLoading(false)
      }
    },
    async createTransactions() {
      const resourceId = this.$route.params.id
      var bodyFormData = new FormData()
      bodyFormData.append('playload', this.transactionPlayload)
      try {
        await axios({
          method: 'post',
          url: `/api/mock_transactions/${resourceId}`,
          data: bodyFormData,
          headers: {
            Authorization: 'Bearer ' + this.idToken.token
          }
        })

        this.transactionPlayload = this.getTransactionJson()
        this.pushAlertMessage({ message: 'Transaction has been added', type: 'info' })
      } catch (e) {
        this.pushAlertMessage({ message: e.response.data.error.message })
      }
      await this.fetchTransactions(resourceId)
    }
  }
}
</script>
