<template>
  <section class="container py-5">
    <div v-if="brokerOrigins.length > 1" class="form-inline d-flex justify-content-center">
      <select v-model="brokerOrigin" name="brokerOrigin" id="brokerOrigin" class="form-control">
        <option v-for="origin in brokerOrigins" :key="origin.value" :value="origin.value">
          {{ origin.name }}
        </option>
      </select>
    </div>
    <div v-if="loading"><LoaderPic />Loading...</div>
    <div v-else-if="allStatuses && Object.keys(allStatuses).length">
      <div v-for="cc in Object.keys(allStatuses).sort((a, b) => countriesList[a].localeCompare(countriesList[b]))" :key="cc">
        <div class="navbar">
          <a :href="`#${cc}`" @click.prevent="toggleCountry(cc)" class="navbar-brand col-4">
            <img :src="require(`../assets/countries/${cc}.svg`)" alt="" class="flag mr-2" />
            {{ countriesList[cc] }}
          </a>
          <span>
            {{ (aspspsCount = Object.keys(allStatuses[cc]).length) }} ASPSP{{ aspspsCount != 1 ? 's' : '' }} monitored
          </span>
          <span>
            {{ (issuesCount = getIssuesCount(cc)) }} issue{{ `${issuesCount != 1 ? 's' : ''}` }} detected
          </span>
        </div>
        <ul class="list-group" v-if="expendedCountries[cc] === true">
          <li v-for="brand in Object.keys(allStatuses[cc]).sort()" :key="brand" class="list-group-item">
            <h5 class="mb-2">{{ brand }}</h5>
            <div v-for="service in Object.keys(allStatuses[cc][brand]).sort()" :key="service">
              <div v-for="psuType in Object.keys(allStatuses[cc][brand][service]).sort()" :key="psuType" class="row">
                <div class="col-2">
                  {{ `${psuType} ${serviceNames[service]}` }}
                </div>
                <div class="col-1">
                  <font-awesome-icon class="traffic-icon" :icon="getTrafficStatusesIcon(cc, brand, psuType)" v-b-popover.hover.focus.top="`${getTrafficStatuses(cc, brand, psuType)} traffic`.toLocaleLowerCase()">
                  </font-awesome-icon>
                </div>
                <div class="col-6" v-if="getHistoricStatuses(cc, brand, service, psuType)">
                  <template v-for="(s, d) in getHistoricStatuses(cc, brand, service, psuType).byDate">
                    <span
                      class="badge mr-1 historic-status"
                      :key="`${cc}-${brand}-${service}-${psuType}-${d}-span`"
                      :class="`bg-${statusClasses[s.status]}`"
                      :title="d"
                      :id="`historic-status-${cc}-${brand}-${service}-${psuType}-${d}`"
                      @mouseover="handleMouseEnter(`${cc}-${brand}-${service}-${psuType}-${d}`)"
                      @mouseleave="handleMouseLeave"
                      tabindex="0" 
                    >&nbsp;</span>
                    <b-popover
                      v-if="hoveredItem === `${cc}-${brand}-${service}-${psuType}-${d}`"
                      :key="`${cc}-${brand}-${service}-${psuType}-${d}-popover`"
                      :target="`historic-status-${cc}-${brand}-${service}-${psuType}-${d}`"
                      triggers="hover focus"
                      placement="top"
                      :title="d"
                    >
                      {{ statusDescriptions[s.status] }}
                    </b-popover>
                  </template>
                </div>
                <div class="col-3">
                  <span class="badge" :class="`bg-${statusClasses[allStatuses[cc][brand][service][psuType].status]}`">{{
                    statusDescriptions[allStatuses[cc][brand][service][psuType].status]
                  }}</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-else-if="allStatuses && !Object.keys(allStatuses).length" class="mt-5">No data</div>
    <div v-else class="mt-5">Unable to fetch statuses</div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import metadata from '../mixins/metadata.js'
import LoaderPic from '../components/elements/Loader/LoaderPic.vue'
import { BPopover } from 'bootstrap-vue/src/components/popover'

export default {
  name: 'Aspsps',
  mixins: [metadata],
  data() {
    return {
      allStatuses: null,
      allTrafficStatuses: null,
      allHistoricStatuses: null,
      expendedCountries: {},
      hoveredItem: null, 
      statusDescriptions: {
        green: 'no problems detected',
        yellow: 'possible problems',
        red: 'major disruption',
        grey: 'no data'
      },
      statusClasses: {
        green: 'success',
        yellow: 'warning',
        red: 'danger',
        grey: 'secondary'
      },
      serviceNames: {
        AIS: 'accounts',
        PIS: 'payments'
      },
      statusOrder: {
        grey: 0,
        green: 1,
        yellow: 2,
        red: 3
      },
      brokerOrigin: localStorage.getItem('selected_broker') || ''
    }
  },
  watch: {
    brokerOrigin: {
      handler: function (brokerOrigin) {
        localStorage.setItem('selected_broker', brokerOrigin)
        this.updateStatuses(brokerOrigin)
      },
      immediate: true
    }
  },
  components: {
    LoaderPic,
    BPopover
  },
  computed: {
    ...mapGetters(['loading', 'idToken']),
    ...mapState({
      countriesList: (state) => state.countriesList,
      brokerOriginsNameMap: (state) => state.brokerOriginsNameMap
    }),
    selectedBroker() {
      // get selected broker from local storage
      return localStorage.getItem('selected_broker') || ''
    },
    brokerOrigins() {
      const userOriginsStr = this.idToken.claims.brokerOrigins
      if (!userOriginsStr) {
        return []
      }
      const defaultBrokerOrigin = ''
      const allowedOrigins = [
        {
          name: 'Default broker',
          value: defaultBrokerOrigin,
          selected: defaultBrokerOrigin === this.selectedBroker
        }
      ]
      const userOrigins = userOriginsStr.split(',')
      if (this.selectedBroker && !userOrigins.includes(this.selectedBroker)) {
        // If the user lost access to the selected broker, reset the selection
        localStorage.setItem('selected_broker', '')
      }
      userOrigins.forEach((origin) => {
        if (this.brokerOriginsNameMap[origin]) {
          allowedOrigins.push({
            name: this.brokerOriginsNameMap[origin],
            value: origin,
            selected: origin === this.selectedBroker
          })
        }
      })
      return allowedOrigins
    }
  },
  methods: {
    ...mapActions(['getAspspStatuses', 'getAspspHistoricStatuses', 'getAspspTrafficStatuses']),
    toggleCountry(cc) {
      this.$set(this.expendedCountries, cc, !this.expendedCountries[cc])
    },
    handleMouseEnter(itemKey) {
      this.hoveredItem = itemKey;
    },
    handleMouseLeave() {
      this.hoveredItem = null;
    },
    getTrafficStatuses(cc, brand, psuType) {
      if (
        !this.allTrafficStatuses ||
        !this.allTrafficStatuses[cc] ||
        !this.allTrafficStatuses[cc][brand] ||
        !this.allTrafficStatuses[cc][brand][psuType]
      ) {
        return "LOW"
      }
      return this.allTrafficStatuses[cc][brand][psuType].status
    },
    getTrafficStatusesIcon(cc, brand, psuType) {
      switch (this.getTrafficStatuses(cc, brand, psuType)) {
        case "HIGH":
          return "fa-solid fa-battery-full"
        case "MEDIUM":
          return "fa-solid fa-battery-half"
        default:
          return "fa-solid fa-battery-quarter"
      }
    },
    getIssuesCount(cc) {
      if (!this.allStatuses[cc]) {
        return 0
      }
      var issuesCount = 0
      for (const brand of Object.keys(this.allStatuses[cc])) {
        for (const service of Object.keys(this.allStatuses[cc][brand])) {
          for (const psuType of Object.keys(this.allStatuses[cc][brand][service])) {
            if (['red', 'yellow'].findIndex(v => v === this.allStatuses[cc][brand][service][psuType].status) !== -1) {
              issuesCount += 1
            }
          }
        }
      }
      return issuesCount
    },
    getHistoricStatuses(cc, brand, service, psuType) {
      if (
        !this.allHistoricStatuses ||
        !this.allHistoricStatuses[cc] ||
        !this.allHistoricStatuses[cc][brand] ||
        !this.allHistoricStatuses[cc][brand][service] ||
        !this.allHistoricStatuses[cc][brand][service][psuType]
      ) {
        return null
      }
      return this.allHistoricStatuses[cc][brand][service][psuType]
    },
    async updateStatuses(brokerOrigin) {
      // Groupping current statuses
      const statuses = (await this.getAspspStatuses(brokerOrigin)).data
      const allStatuses = {}
      for (const status of statuses) {
        if (!(status.country in allStatuses)) {
          allStatuses[status.country] = {}
        }
        if (!(status.brand in allStatuses[status.country])) {
          allStatuses[status.country][status.brand] = {}
        }
        if (!(status.service in allStatuses[status.country][status.brand])) {
          allStatuses[status.country][status.brand][status.service] = {}
        }
        allStatuses[status.country][status.brand][status.service][status.psu_type] = status
      }
      // Preparing array of dates for the last 30 days starting from yesterday
      const last30days = []
      for (const d in [...Array(30).keys()]) {
        const date = new Date(new Date().setDate(new Date().getDate() - 30 + parseInt(d)))
        last30days.push(date)
      }
      // Groupping historic statuses
      const historicStatuses = (await this.getAspspHistoricStatuses(brokerOrigin)).data
      const allHistoricStatuses = {}
      for (const status of historicStatuses) {
        if (!(status.country in allHistoricStatuses)) {
          allHistoricStatuses[status.country] = {}
        }
        if (!(status.brand in allHistoricStatuses[status.country])) {
          allHistoricStatuses[status.country][status.brand] = {}
        }
        if (!(status.service in allHistoricStatuses[status.country][status.brand])) {
          allHistoricStatuses[status.country][status.brand][status.service] = {}
        }
        status.byDate = {}
        for (const date of last30days) {
          status.byDate[date.toLocaleDateString('lt')] = {
            status: 'grey'
          }
        }
        for (const m of status.markers) {
          if (!status.byDate[m.date.value]) {
            continue
          }
          if (this.statusOrder[m.status] > this.statusOrder[status.byDate[m.date.value].status]) {
            status.byDate[m.date.value].status = m.status
          }
        }
        allHistoricStatuses[status.country][status.brand][status.service][status.psu_type] = status
        // filling in current status as grey if missing
        if (!(status.country in allStatuses)) {
          allStatuses[status.country] = {}
        }
        if (!(status.brand in allStatuses[status.country])) {
          allStatuses[status.country][status.brand] = {}
        }
        if (!(status.service in allStatuses[status.country][status.brand])) {
          allStatuses[status.country][status.brand][status.service] = {}
        }
        if (!(status.psu_type in allStatuses[status.country][status.brand][status.service])) {
          allStatuses[status.country][status.brand][status.service][status.psu_type] = {
            country: status.country,
            brand: status.brand,
            service: status.service,
            psu_type: status.psu_type,
            status: 'grey'
          }
        }
      }

      const allTrafficStatusesData = (await this.getAspspTrafficStatuses(brokerOrigin)).data
      const trafficStatuses = {}
      for (const status of allTrafficStatusesData) {
        if (!(status.country in trafficStatuses)) {
          trafficStatuses[status.country] = {}
        }
        if (!(status.brand in trafficStatuses[status.country])) {
          trafficStatuses[status.country][status.brand] = {}
        }
        trafficStatuses[status.country][status.brand][status.psu_type] = status
      }
      this.allTrafficStatuses = trafficStatuses
      this.allStatuses = allStatuses
      this.allHistoricStatuses = allHistoricStatuses
    }
  }
}
</script>
<style lang="scss" scoped>
.historic-status {
  cursor: pointer;
}

.flag {
  $flag-size: 1.6rem;

  width: $flag-size;
  height: $flag-size;
}

.traffic-icon {
  color: #8c8c8c;
  font-size: 1.3rem;
  vertical-align: middle;
}

</style>
