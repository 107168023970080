<template>
    <a v-if="isCustomElement" :href="to"><slot></slot></a>
    <router-link v-else :to="to"><slot></slot></router-link>
</template>

<script>
export default {
  props: {
    isCustomElement: {
      type: Boolean,
      default: false
    },
    to: {
        type: String,
        required: true
    }
  }
}
</script>
  