<template>
  <div>
    <Navbar />
    <section class="container py-5">
      <div class="text-center">
        <h4>{{ $t('title') }}</h4>
        <p class="text-justify mb-2">{{ $t('description') }}</p>
      </div>
      <div v-if="loadingASPSPs" class="text-center">
        <LoaderPic />Loading...
      </div>
      <div v-else>
        <div class="form-row">
          <div class="col-md mb-1">
            <select class="form-control" required :disabled="searching" v-model="country"
              v-on:change="aspsp = ''; usage = ''">
              <option value disabled selected>Country</option>
              <option
                v-for="country in Object.keys(countries).sort((a, b) => countriesList[a].localeCompare(countriesList[b]))"
                :value="country" :key="`country-${country}`">{{ countriesList[country] }}</option>
            </select>
          </div>
          <div class="col-md mb-1">
            <select class="form-control" required :disabled="!country || searching" v-model="aspsp"
              v-on:change="usage = ''">
              <option value disabled selected>ASPSP</option>
              <option v-for="aspsp in countries[country]" :value="aspsp"
                :key="`aspsp-${country}-${aspsp.name.replace(/\s+/g, '-')}`">{{ aspsp.name }}</option>
            </select>
          </div>
          <div class="col-md mb-1">
            <select class="form-control" required :disabled="!aspsp || searching" v-model="usage">
              <option value disabled selected>Usage type</option>
              <option v-for="usage in aspsp ? aspsp.psu_types : []" :value="usage"
                :key="`aspsp-${country}-${aspsp.name.replace(/\s+/g, '-')}-${usage}`">{{ usage }}</option>
            </select>
          </div>
          <div class="col-md mb-1">
            <button class="form-control btn btn-primary overflow-hidden text-truncate" :disabled="!usage || searching" @click="searchSessions()">
              <span v-if="searching" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {{ $t('submit_button')}}
            </button>
          </div>
        </div>
      </div>
    </section>
    <AsideFooter />
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters, mapState } from 'vuex'
import LoaderPic from '../components/elements/Loader/LoaderPic.vue'
import AsideFooter from '../components/AsideFooter.vue'
import Navbar from '../components/Navbar.vue'
import VueI18n from 'vue-i18n'
import moment from 'moment'
import Vue from 'vue'
import en from '../locales/en.json'
import fr from '../locales/fr.json'

Vue.use(VueI18n)

let locale = 'en'
locale = moment.locale(navigator.language)

export default {
  name: 'ConsentRevocation',
  i18n: new VueI18n({
    locale: locale,
    messages: {
      en,
      fr
    }
  }),
  data() {
    return {
      loadingASPSPs: false, // when fetching ASPSPs list
      searching: false,
      countries: null,
      country: '',
      aspsp: '',
      usage: ''
    }
  },
  components: {
    LoaderPic,
    AsideFooter,
    Navbar
  },
  computed: {
    ...mapGetters(['idToken']),
    ...mapState({
      countriesList: state => state.countriesList
    })
  },
  async mounted() {
    this.loadingASPSPs = true
    try {
      const aspspsResponse = await axios.get(
        '/api/aspsps', {}
      )
      const countries = {}
      for (const aspsp of aspspsResponse.data.aspsps) {
        const countryASPSPs = countries[aspsp.country] || []
        aspsp.psu_types = aspsp.psu_types.sort()
        countryASPSPs.push(aspsp)
        countries[aspsp.country] = countryASPSPs.sort((a, b) => a.name.localeCompare(b.name))
      }
      this.countries = countries
    } catch (e) {
      this.pushAlertMessage({
        message: 'Unable to get ASPSPs'
      })
    } finally {
      this.loadingASPSPs = false
    }
    if (window.location.hash) {
      const hashParams = new URLSearchParams(window.location.hash.substring(1))
      if (hashParams && hashParams.has('error')) {
        const error = hashParams.get('error')
        let message = 'Unknown error occurred'
        if (error === 'authorization_failure') {
          message = 'Unable to authorize session'
        }
        this.pushAlertMessage({ message: message })
        hashParams.delete('error')
        window.location.hash = hashParams.toString()
      }
    }
  },
  methods: {
    ...mapActions(['pushAlertMessage']),
    async searchSessions() {
      this.searching = true
      const query = new URLSearchParams()
      query.set('country', this.country)
      query.set('aspsp', this.aspsp.name)
      query.set('psuType', this.usage)
      query.set('redirectUrl', `${window.location.origin}/api/consent_revocation_redirect`)
      try {
        const searchSessionsResponse = await axios.post(
          '/api/get_consents',
          query.toString()
        )
        window.location.href = searchSessionsResponse.data.url
        localStorage.aspsp = this.aspsp.name
      } catch (e) {
        this.pushAlertMessage({ message: 'Unable to start authorization' })
        this.searching = false
      }
    }
  }
}
</script>
