<template>
  <section class="container py-5 text-center">
    <Navbar class="mb-5" ref="navbarRef" />

    <div v-if="showNotificatioin">
      <h2 class="font-weight-bold">{{ this.errorMessage }}</h2>
    </div>
    <div v-else>
      <div v-if="loading || accounts === null">
        <LoaderPic />Loading...
      </div>
      <div v-else>
        <div class="mb-4" v-if="accounts.length === 0">
          <h2 class="mb-6"> No Account </h2>
          <router-link :to="{ name: 'mock-connector' }"> <button class="btn btn-primary">Create Account </button>
          </router-link>
        </div>
        <div class="align-self-center" v-else>
          <h3 class="mb-5">Please select the account(s) you want to grant access to:</h3>
          <table class="table mb-5" >
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Name</th>
                <th scope="col">Currency</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="account in accounts" :key="account.playload.resourceId">
                <th scope="row"><input class="form-check-input" type="checkbox" :id="account.playload.resourceId"
                    :value="account.playload.resourceId" v-model="selectedAccounts"></th>
                <td>{{ account.title }}</td>
                <td>{{ account.playload.currency }}</td>
              </tr>
            </tbody>
          </table>
          <button class="btn btn-primary" @click="handleSubmit">Authorize</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Navbar from '../components/Navbar.vue'
import LoaderPic from '../components/elements/Loader/LoaderPic.vue'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
export default {
  name: 'MockAuthorisation',
  components: {
    Navbar,
    LoaderPic
  },
  data() {
    return {
      accounts: null,
      selectedAccounts: [],
      showNotificatioin: false,
      errorMessage: 'Missing parameters: '
    }
  },
  computed: {
    ...mapGetters(['loading', 'idToken'])
  },
  async mounted() {
    if (!this.$route.query.valid_until) {
      this.showNotificatioin = true
      this.errorMessage = this.errorMessage + '(valid_until) '
    }
    if (!this.$route.query.redirect_url) {
      this.showNotificatioin = true
      this.errorMessage = this.errorMessage + '(redirect_url)'
    }

    try {
      const res = await axios({
        method: 'get',
        url: '/api/mock_accounts',
        headers: {
          Authorization: 'Bearer ' + this.idToken.token
        }
      })
      this.accounts = res.data.accounts
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    ...mapActions(['pushAlertMessage']),

    async handleSubmit() {
      if (this.selectedAccounts.length === 0) {
        this.pushAlertMessage({ message: 'No account is selected' })
      } else {
        try {
          const body = { valid_until: this.$route.query.valid_until, redirect_url: this.$route.query.redirect_url, selected_accounts: this.selectedAccounts }
          const res = await axios({
            method: 'post',
            url: '/api/mock_access_token',
            data: body,
            headers: { Authorization: 'Bearer ' + this.idToken.token }
          })

          window.location.href = res.data.redirect
        } catch (e) {
          console.log(e.response)
          this.pushAlertMessage({ message: 'Authorisation Failed' })
        }
      }
    }
  }
}
</script>
