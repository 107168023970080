<template>
  <div>
    <h3 class="mb-3">{{ title }}</h3>
    <div v-if="valueArray == null">
      <LoaderPic /> Loading...
    </div>
    <div v-else>
      <div class="mb-3" v-if="valueArray.length === 0">
        <p class="mb-3"> No {{ title.toLowerCase() }} </p>
      </div>
      <div v-else>
        <table class="table mb-3 table-hover">
          <thead>
            <tr>
              <th v-for="(name, i) in nameArray" :key="i">{{ name }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          <template v-for="(item, i) in valueArray">
            <tr>
              <td v-for="(v, j) in item" :key="'value-' + j" @click="toggleRow(i)"  style="cursor: pointer;">
                  {{ v }}
              </td>
              <td>
                <button class="btn btn-link btn-sm float-right" :disabled="loading" @click="removeItem(idArray[i])">
                  <font-awesome-icon icon="fa-solid fa-x" />
                </button>
              </td>
            </tr>
            <tr v-if="selectedRows.includes(i)" class="no-hover">
              <td :colspan="nameArray.length + 2" class="small" >
                <MainCodeBlock language="JSON">{{detailArray[i]}}</MainCodeBlock>
              </td>
            </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoaderPic from '../components/elements/Loader/LoaderPic.vue'
import MainCodeBlock from '../components/Main/MainCode/MainCodeBlock.vue'

export default {
  name: 'MockTable',
  props: {
    idArray: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    nameArray: {
      type: Array
    },
    valueArray: { type: Array },
    detailArray: { type: Array }
  },
  components: {
    LoaderPic,
    MainCodeBlock
  },
  computed: {
    ...mapGetters(['loading'])
  },
  data() {
    return {
      selectedRows: []
    }
  },
  methods: {
    removeItem(id) {
      this.$emit('removeItem', id)
    },
    toggleRow(rowIndex) {
      if (this.selectedRows.includes(rowIndex)) {
        const index = this.selectedRows.indexOf(rowIndex)
        this.selectedRows.splice(index, 1)
      } else {
        this.selectedRows.push(rowIndex)
      }
    }
  }
}
</script>

<style scoped>
.no-hover {
  background-color: transparent !important;
}

</style>
