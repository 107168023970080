<template>
  <div>
   <TreeNode v-for="node in results" :key="node.id" :node="node" />
  </div>
</template>

<script>
import TreeNode from '../components/TreeNode.vue'

export default {
  name: 'DataStructure',
  components: {
    TreeNode
  },
  props: {
    results: {
      type: Array
    }
  }
}
</script>
