<template>
  <form name="suggestion" netlify v-if="!isFormSent" v-on:submit.prevent="handleForm">
    <h5 class="mb-3">Open Banking information change</h5>
    <div class="row">
      <div class="col-12 col-md-6 mb-3">
        <label class="d-block">Full name</label>
        <input
          type="text"
          class="form-control"
          v-model="displayName"
          placeholder="Bank name in the list"
          required
        />
      </div>
      <div class="col-12 col-md-6 mb-3">
        <label class="d-block">
          Short name
          <small>(if applicable)</small>
        </label>
        <input type="text" class="form-control" v-model="shortName" placeholder="Bank short name" />
      </div>
    </div>
    <label class="d-block">Home page</label>
    <div class="mb-3">
      <input type="text" class="form-control" v-model="homepage" />
    </div>
    <!--
    <label class="d-block">BIC</label>
    <div class="mb-3">
      <input type="text" class="form-control" v-model="bic">
    </div>
    -->
    <div class="mb-3">
      <label class="d-block">Sandbox status</label>
      <ChipsItem
        v-for="(status, index) in statuses"
        :key="`status-${index}`"
        class="mr-2 mb-2 text-capitalize"
        :text="status"
        :tooltip="sandboxTooltips[index]"
        v-on:change="sandboxStatus = status"
        :checked="sandboxStatus === status"
      />
    </div>
    <div class="mb-3">
      <label class="d-block">Production status</label>
      <ChipsItem
        v-for="(status, index) in statuses"
        :key="`status-${index}`"
        class="mr-2 mb-2 text-capitalize"
        :text="status"
        :tooltip="productionTooltips[index]"
        v-on:change="productionStatus = status"
        :checked="productionStatus === status"
      />
    </div>
    <div class="mb-3">
      <label class="d-block">Available PSD2 APIs</label>
      <ChipsItem
        v-for="(title, api, index) in apis"
        :key="`api-${index}`"
        class="mr-2 mb-2 text-capitalize"
        :text="title"
        v-on:change="selectApi(api)"
        :checked="selectedApis.includes(api)"
      />
    </div>
    <label class="d-block">
      Other available APIs
      <small>(if any)</small>
    </label>
    <div class="mb-3">
      <input type="text" class="form-control" v-model="otherApis" placeholder />
    </div>
    <label class="d-block">Open Banking home page</label>
    <div class="mb-3">
      <input type="text" class="form-control" v-model="obHomepage" placeholder="https://" />
    </div>
    <label class="d-block">Developer portal address</label>
    <div class="mb-5">
      <input type="text" class="form-control" v-model="devPortal" placeholder="https://" />
    </div>
    <p>
      <b>Please introduce yourself</b>
    </p>
    <div class="row">
      <div class="col-12 col-md-6 mb-3">
        <input type="text" class="form-control" v-model="name" placeholder="Name" required />
      </div>
      <div class="col-12 col-md-6 mb-3">
        <input type="email" class="form-control" v-model="email" placeholder="Email" />
      </div>
    </div>
    <div class="mb-3">
      <ChipsItem
        v-for="(role, index) in roles"
        :key="`role-${index}`"
        class="mr-2 mb-2 text-capitalize"
        :text="role"
        v-on:change="selectedRole = role"
        :checked="selectedRole === role"
      />
    </div>
    <div class="mb-5">
      <BFormCheckbox v-model="subscribeUpdates">
        <span class="ml-2">
          Subscribe for updates
          <small>(optional)</small>
        </span>
      </BFormCheckbox>
    </div>
    <button class="btn btn-primary mr-3" type="submit">Suggest changes</button>
    <button class="btn btn-link" type="button" v-on:click="$emit('close')">Close</button>
  </form>
  <div v-else>
    <h4 class="mb-4">
      <Success class="mr-2" />Thank you for the suggested changes!
    </h4>
    <button v-on:click="$emit('close')" class="btn btn-primary mr-3">Close</button>
  </div>
</template>

<script>
import axios from 'axios'
import ChipsItem from '../../elements/Chips/ChipsItem'
import Success from '../../elements/Success'
import { BFormCheckbox } from 'bootstrap-vue/src/components/form-checkbox'

export default {
  name: 'BanksListItemEdit',
  data() {
    return {
      displayName: '',
      shortName: '',
      homepage: '',
      bic: '',
      productionStatus: '',
      sandboxStatus: '',
      selectedApis: [],
      statuses: ['unknown', 'development', 'restricted', 'available'],
      sandboxTooltips: [
        'No information about the APIs',
        'Development of the APIs is ongoing, some information is published',
        'Access to the APIs can be granted on request',
        'The APIs are available for everyone'
      ],
      productionTooltips: [
        'No information about the APIs or production environment is not available',
        'Development of the APIs is ongoing',
        'Access to the APIs can be granted on request',
        'The APIs are available for all licensed TPPs'
      ],
      apis: {
        ais: 'account information',
        pis: 'payment initiation',
        cof: 'confirmation of funds'
      },
      isFormSent: false,
      name: '',
      email: '',
      roles: ['bank representative', 'open banking enthusiast'],
      selectedRole: '',
      otherApis: '',
      obHomepage: '',
      devPortal: '',
      subscribeUpdates: false
    }
  },
  props: {
    info: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    ChipsItem,
    Success,
    BFormCheckbox
  },
  mounted() {
    this.displayName = this.info.name || ''
    this.shortName = this.info.shortname || ''
    this.homepage = this.info.homepage || ''
    this.bic = this.info.bic || ''
    this.productionStatus = this.info.open_banking.status.production || 'unknown'
    this.sandboxStatus = this.info.open_banking.status.sandbox || 'unknown'
    this.selectedApis = this.info.open_banking.api ? Object.keys(this.info.open_banking.api) : []
    this.obHomepage = this.info.open_banking.homepage || ''
    this.devPortal = this.info.open_banking.dev_portal || ''
    this.otherApis = ''
  },
  methods: {
    encode(data) {
      return Object.keys(data)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
        .join('&')
    },
    handleForm() {
      const comp = this
      if (!comp.displayName || !comp.name) {
        alert('Please fill in all required fields')
        return
      }
      axios
        .post(
          '/',
          comp.encode({
            'form-name': 'suggestion',
            name: comp.displayName,
            short_name: comp.shortName,
            homepage: comp.homepage,
            'open_banking-status-sandbox': comp.sandboxStatus,
            'open_banking-status-production': comp.productionStatus,
            'open_banking-api': comp.selectedApis.join(','),
            'open_banking-api-other': comp.otherApis,
            'open_banking-homepage': comp.obHomepage,
            'open_banking-dev_portal': comp.devPortal,
            'user-name': comp.name,
            'user-email': comp.email,
            'user-role': comp.selectedRole,
            'user-subscription': comp.subscribeUpdates.toString()
          }),
          { header: { 'Content-Type': 'application/x-www-form-urlencoded' } }
        )
        .then((response) => {
          comp.isFormSent = true
        })
        .catch((err) => {
          alert('Something went wrong. Please try again', err)
        })
    },
    selectApi(value) {
      const selectedApisSet = new Set(this.selectedApis)

      if (selectedApisSet.has(value)) {
        selectedApisSet.delete(value)
      } else {
        selectedApisSet.add(value)
      }
      this.selectedApis = [...selectedApisSet]
    }
  }
}
</script>
