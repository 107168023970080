const metadata = {
  beforeCreate() {
    const routerMeta = this.$route.meta

    if (routerMeta && routerMeta.title && routerMeta.description) {
      document.title = routerMeta.title
      document.head.querySelector('meta[name=description]').content = routerMeta.description
    }
  }
}

export default metadata
