import Vue from 'vue'
import Router from 'vue-router'
import store from './store/'
import router from './router'
import vueCustomElement from 'vue-custom-element'
import Footer from './widgets/FooterWidget.vue'
import Header from './widgets/HeaderWidget.vue'
import Cookie from './widgets/CookieWidget.vue'
import Integrations from './widgets/IntegrationsWidget.vue'
import BootstrapVue from 'bootstrap-vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fas)


Vue.use(Router)
Vue.use(BootstrapVue)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(vueCustomElement)

Vue.customElement('enablebanking-header', {
  router,
  store,
  render: h => h(Header)
})

Vue.customElement('enablebanking-footer', Footer)

Vue.customElement('enablebanking-cookie', Cookie)

Vue.customElement('enablebanking-integrations', {
  store,
  render: h => h(Integrations)
})
