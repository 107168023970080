<template>
  <div>
    <main>
      <section class="container py-5">
        <div class="row justify-content-md-center mt-4">
          <div class="col-md-4 text-center text-danger" v-if="error">
            {{ error }}
          </div>
          <div class="col-md-4 text-center" v-else-if="user">
            Redirecting to {{ redirect_url }}
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import metadata from '../mixins/metadata.js'

export default {
  name: 'sign-in',
  mixins: [metadata],
  data() {
    return {
      jwt: null,
      redirect_url: null,
      error: null
    }
  },
  computed: {
    ...mapGetters(['user', 'idToken'])
  },
  async mounted() {
    const params = new URLSearchParams(window.location.search)
    this.redirect_url = params.get('redirect_url')
    const url = new URL(this.redirect_url)
    await this.getSSOJWT()
    if (this.parseJwt(this.jwt).aud !== url.origin) {
      this.error = `Redirect to ${this.redirect_url} is not allowed`
      return
    }
    const path = url.pathname.startsWith('/_hcms/') ? url.pathname : '/_hcms/mem/jwt'
    window.location.href = `${url.origin}${path}?redirect_url=${encodeURI(this.redirect_url)}&jwt=${encodeURI(this.jwt)}`
  },
  methods: {
    async getSSOJWT() {
      const res = await axios({
        method: 'get',
        url: '/api/get_sso_jwt',
        headers: {
          Authorization: 'Bearer ' + this.idToken.token
        }
      })
      this.jwt = res.data.jwt
    },
    parseJwt(token) {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
      return JSON.parse(jsonPayload)
    }
  }
}
</script>
