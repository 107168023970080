<template>
  <div>
    <div
      class="d-flex flex-column justify-content-between bank-card py-3 px-4 m-2 m-xl-3"
      v-on:click.prevent="handleClick"
    >
      <div>
        <div
          class="bank-card__logo mt-3 mb-4"
          :class="{ 'bank-card__logo--empty': !logo }"
          v-bind:style="{ 'background-image': `url(${logo})` }"
        ></div>
        <h6 class="my-1" :title="info.legal_name">
          <small class="d-block" v-if="info.shortname">{{ info.name }}</small>
          {{ info.shortname || info.name }}
        </h6>
        <div class="d-flex my-1 align-items-center mb-4">
          <span class="text-secondary mr-2" v-if="info.country">{{ countriesList[info.country] }}</span>
          <span class="text-secondary" v-if="info.head && info.head.country"
            >(HQ: {{ countriesList[info.head.country] }})</span
          >
        </div>
        <div class="d-flex my-1 align-items-center" v-if="info.scopes && info.scopes.length">
          <small v-if="info.scopes && info.scopes.length" class="text-capitalize mr-2">
            Integrated APIs:
            <span class="text-success mr-1" v-if="info.scopes.includes('aisp')">AISP</span>
            <span class="text-success" v-if="info.scopes.includes('pisp')">PISP</span>
          </small>
          <small v-if="info.environments && info.environments.length" class="mr-2">
            Integrated environments:
            <span class="text-success mr-1" v-if="info.environments.includes('sandbox')">Sandbox</span>
            <span class="text-success" v-if="info.environments.includes('production')">Production</span>
          </small>
        </div>
        <div class="d-flex my-1 align-items-center" v-else-if="info.wip && info.wip.connectors">
          <small :class="{'text-success': info.wip.connectors == 'ongoing'}">{{ integrationStatuses[info.wip.connectors] }}</small>
        </div>
        <div class="d-flex my-1 align-items-center" v-else>
          <small
            v-if="
              info.open_banking &&
              info.open_banking.status &&
              info.open_banking.status.sandbox == 'absent' &&
              info.open_banking.status.production == 'absent'
            "
            class="mr-2"
          >
            Open banking APIs are not provided
          </small>
        </div>
      </div>
      <!--
      <span>
        <span v-on:click.prevent="isShowInfo = !isShowInfo" v-if="info.updates && info.updates.modificationDate" class="small">
          Last update was {{new Date(info.updates.modificationDate).toLocaleDateString()}}
        </span>
        <span v-else class="small text-muted">No updates recently</span>
      </span>
      -->
    </div>
    <b-modal
      ref="bankInfoModalRef"
      size="lg"
      hide-header
      hide-footer
      centered
      lazy
      no-close-on-backdrop
      title="Bank information"
      :style="{ 'transform-origin': transformOrigin }"
      class="modal-transform-origin"
    >
      <BanksListItemBack :info="info" v-on:close="hideModal" />
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BanksListItemBack from './BanksListItemBack'
import { BModal } from 'bootstrap-vue/src/components/modal'

export default {
  name: 'BanksListItem',
  data() {
    return {
      isShowInfo: true,
      transformOrigin: '',
      statusColors: {
        unknown: 'text-muted',
        development: 'text-primary',
        restricted: 'text-info',
        available: 'text-success'
      }
    }
  },
  props: {
    info: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState({
      countriesList: (state) => state.countriesList,
      integrationStatuses: (state) => state.integrationStatuses
    }),
    logo() {
      if (this.info && this.info.logos && this.info.logos[0] && this.info.logos[0].startsWith('logos/')) {
        return require(`../../../assets/${this.info.logos[0]}`)
      }
      return ''
    }
  },
  components: {
    BanksListItemBack,
    BModal
  },
  methods: {
    showModal(id) {
      this.$refs.bankInfoModalRef.show()
      this.$router.push({ name: 'banks-item', params: { id } })
    },
    hideModal() {
      this.$refs.bankInfoModalRef.hide()
      this.$router.push({ name: 'banks' })
    },
    handleClick(event) {
      this.transformOrigin = `${event.clientX - window.innerWidth / 2 + 250}px ${event.clientY}px!important`
      this.showModal(this.info.objectID)
    }
  }
}
</script>
