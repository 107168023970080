<template>
  <div>
    <label class="small d-block">Country</label>
    <AisRefinementList attribute="country" :sort-by="['name:asc']" class="list-unstyled" :isRefined="true" :limit="40">
      <div slot-scope="{ items, refine }">
        <ChipsItem
          v-for="(country, index) in items.sort((a, b) => countriesList[a.label].localeCompare(countriesList[b.label]))"
          :key="`country-${index}`"
          class="mr-2 mb-2"
          :text="countriesList[country.label]"
          v-on:change="refineUrl(country, refine)"
          :checked="country.isRefined"
          v-on:loaded="refineDefault(country, refine)"
        ><img :src="require(`../../../assets/countries/${country.label}.svg`)" alt="" class="bank-card__flag mr-2"></ChipsItem>
        <span v-if="!items.length">No results</span>
      </div>
    </AisRefinementList>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ChipsItem from '../../elements/Chips/ChipsItem.vue'

export default {
  components: {
    ChipsItem
  },
  computed: {
    ...mapState({
      countriesList: state => state.countriesList
    })
  },
  methods: {
    refineDefault(country, refine) {
      const countryParam = new URLSearchParams(window.location.search).get('country')
      const countries = countryParam ? countryParam.split('|') : []
      if (countries.includes(country.label)) {
        refine(country.label)
      }
    },
    refineUrl(country, refine) {
      refine(country.label)
      history.pushState({}, '', window.location.pathname)
    }
  }
}
</script>
