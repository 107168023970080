<template>
  <div>
        <div class="nav nav-pills mb-3">
          <a
            class="nav-link"
            v-for="e in Object.keys(examples)"
            :key="`nav-link-${e}`"
            :class="{'active': e === example}"
            href="#"
            v-on:click.prevent="example = e"
          >{{examples[e]}}</a>
          <a href="/docs/api/latest/" class="nav-link text-primary font-weight-bold">Full API reference &rarr;</a>
        </div>
        <div class="p-1" :class="{ 'd-none': example !== 'auth' }">
          <div class="p-2 text-light bg-secondary m-0">cURL</div>
          <MainCodeBlock language="bash" class="bg-white">
curl -X POST \
     -H "Authorization: Bearer $ENABLEBANKING_API_JWT" \
     -H "Content-Type: application/json" \
     -d @auth.json \
     https://api.enablebanking.com/auth
          </MainCodeBlock>
          <div class="p-2 text-light bg-info m-0">auth.json</div>
          <MainCodeBlock language="json" class="bg-white">
{
  "access": {
    "balances": true,
    "transactions": true,
    "valid_until": "{{ validUntil }}"
  },
  "aspsp": {
    "country": "FI",
    "name": "Nordea"
  },
  "psu_type": "personal",
  "redirect_url": "http://your-domain.com/redirect-callback",
  "state": "3a57e2d3-2e0c-4336-af9b-7fa94f0606a3"
}
          </MainCodeBlock>
          <div class="p-2 text-light bg-success m-0">200 Response</div>
          <MainCodeBlock language="json" class="bg-white">
{
  "url": "https://tilisy.enablebanking.com/ais/start?sessionid=73100c65-c54d-46a1-87d1-aa3effde435a",
  "authorization_id": "73100c65-c54d-46a1-87d1-aa3effde435a",
  "psu_id_hash": "db10bfbbb9ca86a78ce42ba0c21c2870824d9b9056e259c1ab1df50813bd7781"
}
          </MainCodeBlock>
        </div>
        <div class="p-1" :class="{ 'd-none': example !== 'session' }">
          <div class="p-2 text-light bg-secondary m-0">cURL</div>
          <MainCodeBlock language="bash" class="bg-white">
curl -X POST \
     -H "Authorization: Bearer $ENABLEBANKING_API_JWT" \
     -H "Content-Type: application/json" \
     -d @session.json \
     https://api.enablebanking.com/sessions
          </MainCodeBlock>
          <div class="p-2 text-light bg-info m-0">session.json</div>
          <MainCodeBlock language="json" class="bg-white">
{
  "code": "0f4473e7-95dd-49d2-9e48-5884d268b8d9"
}
          </MainCodeBlock>
          <div class="p-2 text-light bg-success m-0">200 Response</div>
          <MainCodeBlock language="json" class="bg-white">
{
  "accounts": [
    {
      "account_id": {
        "iban": "FI8821291587733472"
      },
      "cash_account_type": "CACC",
      "currency": "EUR",
      "details": "Current account",
      "identification_hash": "WwpbCiJhY2NvdW50IiwKImFjY291bnRfaWQiLAoiaWJhbiIKXQpd.E8GzhnnsFC7K+4e3YMYYKpyM83Zx6toXrjgcvPP/Lqc=",
      "name": "Holder Name",
      "uid": "07cc67f4-45d6-494b-adac-09b5cbc7e2b5",
      "usage": "PRIV"
    }
  ],
  "session_id": "4604aa90-f8a8-4180-92d8-0c3270846f0a"
}
          </MainCodeBlock>
        </div>
        <div class="p-1" :class="{ 'd-none': example !== 'balances' }">
          <div class="p-2 text-light bg-secondary m-0">cURL</div>
          <MainCodeBlock language="bash" class="bg-white">
curl -X GET \
     -H "Authorization: Bearer $ENABLEBANKING_API_JWT" \
     https://api.enablebanking.com/accounts/07cc67f4-45d6-494b-adac-09b5cbc7e2b5/balances
          </MainCodeBlock>
          <div class="p-2 text-light bg-success m-0">200 Response</div>
          <MainCodeBlock language="json" class="bg-white">
{
  "balances": [
    {
      "balance_amount": {
        "amount": "1.23",
        "currency": "EUR"
      },
      "balance_type": "CLBD",
      "last_change_date_time": "2019-08-24T14:15:22Z",
      "name": "Booked balance",
      "reference_date": "2019-08-24"
    }
  ]
}
          </MainCodeBlock>
        </div>
        <div class="p-1" :class="{ 'd-none': example !== 'transactions' }">
          <div class="p-2 text-light bg-secondary m-0">cURL</div>
          <MainCodeBlock language="bash" class="bg-white">
curl -X GET \
     -H "Authorization: Bearer $ENABLEBANKING_API_JWT" \
     https://api.enablebanking.com/accounts/07cc67f4-45d6-494b-adac-09b5cbc7e2b5/transactions
          </MainCodeBlock>
          <div class="p-2 text-light bg-success m-0">200 Response</div>
          <MainCodeBlock language="json" class="bg-white">
{
  "continuation_key": "E8GzhnnsFC7K+4e3YMYYKpyM",
  "transactions": [
    {
      "bank_transaction_code": {
        "code": "12",
        "description": "Utlandsbetalning",
        "sub_code": "32"
      },
      "booking_date": "2020-01-01",
      "credit_debit_indicator": "CRDT",
      "creditor": {
        "name": "Company Name"
      },
      "debtor_account": {
        "iban": "FI8821291587733472"
      },
      "entry_reference": "5561990681",
      "remittance_information": ["Gift for Debtor"],
      "status": "BOOK",
      "transaction_amount": {
        "amount": "1.23",
        "currency": "EUR"
      },
      "transaction_date": "2020-01-01",
      "value_date": "2020-01-01"
    }
  ]
}
          </MainCodeBlock>
        </div>
  </div>
</template>

<script>
import MainCodeBlock from './Main/MainCode/MainCodeBlock.vue'

export default {
  name: 'ApiSamples',
  data() {
    return {
      example: 'auth',
      examples: {
        auth: 'Initiate authentication',
        session: 'Confirm user session',
        balances: 'Retrieve balances',
        transactions: 'Retrieve transactions'
      }
    }
  },
  computed: {
    validUntil() {
      return (new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)).toISOString()
    }
  },
  components: {
    MainCodeBlock
  }
}
</script>
