<template>
  <section class="container py-4">
    <router-link :to="{name: 'applications'}" class="mb-3 d-inline-flex text-decoration-none">
      <span class="material-icons-outlined">chevron_left</span> All applications
    </router-link>
    <div v-if="loading || app === null"><LoaderPic />Loading...</div>
    <div v-else-if="app">
      <h5 class="mb-4">
        <span class="mr-2">Sharing application {{ app.kid }} ({{ app.environment }})</span>
      </h5>
      <p>
        Please enter the email of the user you would like to share the application with.
      </p>
      <form class="mb-5" @submit.prevent="shareApp">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="Email of address of target user"
                v-model="email"
                required
                :disabled="sharing"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <button class="btn btn-primary" type="submit" :disabled="sharing">
              <span
                v-if="sharing"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Share
            </button>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoaderPic from '../components/elements/Loader/LoaderPic.vue'

const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}

export default {
  name: 'ApplicationShare',
  data() {
    return {
      app: null,
      email: '',
      sharing: false
    }
  },
  components: {
    LoaderPic
  },
  computed: {
    ...mapGetters(['applications', 'loading'])
  },
  async mounted() {
    if (!this.applications.length) {
      await this.getApplications()
    }
    const appId = this.$route.params.id
    this.app = this.applications.find(app => app.kid === appId)
    if (!this.app) {
      this.$router.push({ path: '/cp/applications' })
    }
  },
  methods: {
    ...mapActions(['getApplications', 'getIdTokenResult', 'pushAlertMessage', 'shareApplication']),
    async shareApp() {
      try {
        if (!validateEmail(this.email)) {
          this.pushAlertMessage({ message: 'The Email address you input is not valid' })
          return
        }
        this.sharing = true
        const r = await this.shareApplication({
          applicationId: this.$route.params.id,
          email: this.email
        })
        if (!r) {
          return
        }
        this.pushAlertMessage({ message: 'Application has been shared', type: 'info' })
        await this.getIdTokenResult(true)
        this.$router.push({ path: '/cp/applications' })
      } finally {
        this.sharing = false
      }
    }
  }
}
</script>
