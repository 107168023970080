<template>
  <section class="container py-4">
    <router-link :to="{name: 'applications'}" class="mb-3 d-inline-flex text-decoration-none">
      <span class="material-icons-outlined">chevron_left</span> All applications
    </router-link>
    <div v-if="loading || app === null"><LoaderPic />Loading...</div>
    <div v-else-if="app">
      <h5 class="mb-4">
        <span class="mr-2">Editing application {{ app.kid }} ({{ app.environment }})</span>
      </h5>
      <ApplicationsItemEdit :app="app" />
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ApplicationsItemEdit from '../components/applications/ApplicationsItemEdit'
import LoaderPic from '../components/elements/Loader/LoaderPic.vue'

export default {
  name: 'ApplicationsEdit',
  data() {
    return {
      app: null
    }
  },
  components: {
    ApplicationsItemEdit,
    LoaderPic
  },
  computed: {
    ...mapGetters(['applications', 'loading'])
  },
  async mounted() {
    if (!this.applications.length) {
      await this.getApplications()
    }
    const appId = this.$route.params.id
    this.app = this.applications.find(app => app.kid === appId)
  },
  methods: {
    ...mapActions(['getApplications'])
  }
}
</script>
