<template>
  <div>
    <Navbar />
    <main>
      <section class="brand-colors--green with-bg">
        <div class="container bg-guy">
          <div class="row align-items-center">
            <div class="col-12 col-md-6 py-4 py-md-7">
              <h1 class="type-mono">We Are Hiring</h1>
            </div>
          </div>
        </div>
      </section>
      <section class="container py-5">
        <vue-markdown class="job-description">
        <!-- eslint-disable -->
# Junior Software Engineer ・ API integrations

## Job description

As part of our team you will be implementing integrations towards Open Banking APIs of European banks, as well as monitoring their performance and improving their functionality and quality. Through day-to-day work you will gain deep knowledge about Open Banking, API security and payments and will learn how to develop and maintain API integrations at scale. You will be able to develop various skills and soon have the opportunity to contribute to various parts of the system. Your work will make essential contributions to the company’s core offering.

We expect you to:

-   be able to demonstrate coding skills in either Python, Javascript, Java or another programming language(s);
    
-   have experience in usage of web APIs;
    
-   be familiar with Git and basic software development practices;
    
-   be able to use command line interface;
    
-   be able to communicate in English;
    
-   learn new technologies and master domain expertise.

The following will be considered as an advantage:

-   experience in backend or API development;
    
-   experience in unit and/or integration testing;
    
-   basic knowledge about cryptography and secure software development.

We are looking to hire several people in this role; the position is full-time.

## About Enable Banking

Enable Banking Oy provides to its clients state of art Open Banking aggregation technology, connecting to over 2500 banks in 20+ countries.

We develop high quality, secure software at a very fast pace. To be able to achieve this we rely on continuous integration and delivery, server less cloud infrastructure, as well as automated testing and monitoring. We are ISO 27001 certified and are regulated by the Finnish supervisory authority (Finanssivalvonta).

Our customers are forerunners of Open Banking ranging from accounting software to neobanks core technology providers. Public references include Kreditz, Smart Valor, Aura Cloud, Riskrate, Zervant, eTasku and Crosskey.

We are venture funded, and fast growing fintech, operating in Espoo Finland, Vilnius Lithuania, and in Stockholm Sweden.

## Got interested? Apply now → [hr@enablebanking.com](mailto:hr@enablebanking.com)
        </vue-markdown>
      </section>
    </main>
    <AsideFooter />
  </div>
</template>

<script>
import VueMarkdown from '@adapttive/vue-markdown'
import AsideFooter from '../components/AsideFooter.vue'
import Navbar from '../components/Navbar.vue'
import metadata from '../mixins/metadata.js'

export default {
  name: 'careers-junior-software-engineer',
  mixins: [metadata],
  components: {
    AsideFooter,
    Navbar,
    VueMarkdown
  }
}
</script>
<style lang="scss">
.job-description h2 {
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.bg-guy {
  background-image: url('../assets/img/dude.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right center;
}
</style>
