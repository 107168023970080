import Router from 'vue-router'
import Applications from './views/Applications.vue'
import ApplicationEdit from './views/ApplicationEdit.vue'
import ApplicationRemove from './views/ApplicationRemove.vue'
import ApplicationPaymentSettings from './views/ApplicationPaymentSettings.vue'
import ApplicationRequests from './views/ApplicationRequests.vue'
import ApplicationShare from './views/ApplicationShare.vue'
import Aspsps from './views/Aspsps.vue'
import Packages from './views/Packages.vue'
import Personal from './views/Personal.vue'
import Banks from './views/Banks.vue'
import Home from './views/Home.vue'
import Sdk from './views/Sdk.vue'
import TilisyApi from './views/TilisyApi.vue'
import ConnectorsTerms from './views/ConnectorsTerms.vue'
import Loading from './views/Loading.vue'
import Meetings from './views/Meetings.vue'
import PrivacyPolicy from './views/PrivacyPolicy.vue'
import SignIn from './views/SignIn.vue'
import TestServiceTerms from './views/TestServiceTerms.vue'
import TrademarkDisclaimer from './views/TrademarkDisclaimer.vue'
import BrandGuidelines from './views/BrandGuidelines.vue'
import CareersJuniorSoftwareEngineer from './views/CareersJuniorSoftwareEngineer.vue'
import CareersTechnicalSupportSpecialist from './views/CareersTechnicalSupportSpecialist.vue'
import UserProfile from './views/UserProfile.vue'
import DataInsights from './views/DataInsights.vue'
import MockASPSP from './views/MockASPSP.vue'
import MockAuthorisation from './views/MockAuthorisation.vue'
import MockAccountData from './views/MockAccountData.vue'
import SSO from './views/SSO.vue'
import store from './store'
import ConsentRevocation from './views/ConsentRevocation.vue'
import ActiveDataSharingConsents from './views/ActiveDataSharingConsents.vue'
import ApplicationStatistics from './views/ApplicationStatistics.vue'
import MFAUnenrollment from './views/MFAUnenrollment.vue'

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.meta.disableScrollTop || from.meta.disableScrollTop) {
      return
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: 'Enable Banking - The Connectivity Engine that Puts You in Charge',
        description:
          'We are your open banking partner that enables scalable, secure, & transparent solutions that put you in charge of your service offering.'
      }
    },
    {
      path: '/core',
      name: 'core',
      component: Sdk,
      meta: {
        title: 'Enable Banking - Open Banking aggregation core',
        description:
          'Enable Banking SDK makes it easy to use Open Banking APIs provided by European banks under PSD2 regulation. The aggregation core libraries for Java, Javascript and Python provide unified interface for making direct connection to account information (AIS) and payment initiation (PIS) APIs.'
      }
    },
    {
      path: '/accounts-api',
      name: 'accounts-api',
      component: TilisyApi,
      meta: {
        title: 'Enable Banking API - Account information',
        description:
          'Get instant access to account transactions and pay-as-you-go – we deliver account information where you need it.'
      }
    },
    {
      path: '/loading',
      name: 'loading',
      component: Loading,
      meta: {
        title: 'Enable Banking - Loading...',
        description: ''
      }
    },
    {
      path: '/meetings',
      name: 'meetings',
      component: Meetings,
      meta: {
        title: 'Enable Banking - Schedule a meeting with us',
        description: 'Get to know Enable Banking people and technology in the 30 minutes discovery call.'
      }
    },
    {
      path: '/data-sharing-consents',
      name: 'data-sharing-consents',
      component: ConsentRevocation,
      meta: {
        title: 'Data sharing - Enable Banking',
        description: 'Access to the list of consented sessions'
      }
    },
    {
      path: '/data-sharing-consents/active',
      name: 'data-sharing-consents-active',
      component: ActiveDataSharingConsents,
      meta: {
        title: 'Consent revocation - Enable Banking',
        description: 'Revoke consent to previously authorized sessions'
      }
    },
    {
      path: '/cp/mock-aspsp/auth', // outside of base /cp to avoid control panel navigation menu
      name: 'mock-authorisation',
      component: MockAuthorisation,
      meta: {
        requiresAuth: true,
        title: 'Authorisation - Mock ASPSP - Enable Banking',
        description: 'Mock ASPSP authorisation'
      }
    },
    {
      path: '/cp/sso',
      name: 'sso',
      component: SSO,
      meta: {
        requiresAuth: true,
        title: 'SSO Redirect - Enable Banking',
        description: 'SSO Redirect'
      }
    },
    {
      path: '/cp', // cp stands for control panel
      name: 'personal',
      component: Personal,
      redirect: { name: 'profile' },
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'aspsps',
          name: 'aspsps',
          component: Aspsps,
          meta: {
            title: 'ASPSP integrations - Enable Banking',
            description: 'ASPSP integrations'
          }
        },
        {
          path: 'data-insights',
          name: 'data-insights',
          component: DataInsights,
          meta: {
            title: 'Data insights - Enable Banking',
            description: 'Data insights'
          }
        },
        {
          path: 'mock-aspsp',
          name: 'mock-aspsp',
          component: MockASPSP,
          meta: {
            title: 'Mock ASPSP - Enable Banking',
            description: 'Mock ASPSP'
          }
        },
        {
          path: 'mock-aspsp/:id',
          name: 'mockAccountData',
          component: MockAccountData,
          meta: {
            title: 'Account details - Mock ASPSP - Enable Banking',
            description: 'Mock ASPSP account details'
          }
        },
        {
          path: 'applications',
          name: 'applications',
          component: Applications,
          meta: {
            title: 'API applications - Enable Banking',
            description: 'API applications'
          }
        },
        {
          path: 'applications/:id/edit',
          name: 'application-edit',
          component: ApplicationEdit,
          meta: {
            title: 'API applications - Enable Banking',
            description: 'API application editing'
          }
        },
        {
          path: 'applications/:id/remove',
          name: 'application-remove',
          component: ApplicationRemove,
          meta: {
            title: 'API applications - Enable Banking',
            description: 'API applications removal'
          }
        },
        {
          path: 'applications/:id/share',
          name: 'application-share',
          component: ApplicationShare,
          meta: {
            title: 'API applications - Enable Banking',
            description: 'API applications share'
          }
        },
        {
          path: 'applications/:id/requests',
          name: 'application-requests',
          component: ApplicationRequests,
          meta: {
            title: 'API applications - Enable Banking',
            description: 'API application requests'
          }
        },
        {
          path: 'applications/:id/statistics',
          name: 'application-statistics',
          component: ApplicationStatistics,
          meta: {
            title: 'API applications - Enable Banking',
            description: 'API application statistics'
          }
        },
        {
          path: 'applications/:id/payments/settings',
          name: 'application-payments-settings',
          component: ApplicationPaymentSettings,
          meta: {
            title: 'API applications - Enable Banking',
            description: 'API application payment settings'
          }
        },
        {
          path: 'packages',
          name: 'packages',
          component: Packages,
          meta: {
            title: 'SDK packages - Enable Banking',
            description: 'SDK packages'
          }
        },
        {
          path: 'profile',
          name: 'profile',
          component: UserProfile,
          meta: {
            title: 'User profile - Enable Banking',
            description: 'User profile'
          }
        }
      ]
    },
    {
      path: '/cp/mfa-unenrollment',
      name: 'mfa-unenrollment',
      component: MFAUnenrollment,
      meta: {
        title: 'User profile - Enable Banking',
        description: 'MFA unenrollment'
      }
    },
    {
      path: '/connectors/terms',
      name: 'connectors-terms',
      component: ConnectorsTerms,
      meta: {
        title: 'Terms of Service and e-mail verification for access to connectors - Enable Banking',
        description: 'Terms of Service and e-mail verification for access to connectors'
      }
    },
    {
      path: '/open-banking-apis',
      name: 'banks',
      component: Banks,
      meta: {
        title: 'Open Banking APIs availability and integration status - Enable Banking',
        description:
          'Information about availability status of PSD2 APIs provided by the European banks and their integration in the open banking aggregation SDK.'
      }
    },
    {
      path: '/open-banking-apis/:id',
      name: 'banks-item',
      component: Banks,
      meta: {
        title: 'Open Banking APIs availability and integration status - Enable Banking',
        description:
          'Information about availability status of PSD2 APIs provided by the European banks and their integration in the open banking aggregation SDK.',
        disableScrollTop: true
      }
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: PrivacyPolicy,
      meta: {
        title: 'Privacy policy - Enable Banking',
        description: 'Privacy policy'
      }
    },
    {
      path: '/sign-in',
      name: 'sign-in',
      component: SignIn,
      meta: {
        title: 'Sign In - Enable Banking',
        description: 'Sign In'
      }
    },
    {
      path: '/test-service-terms',
      name: 'test-service-terms',
      component: TestServiceTerms,
      meta: {
        title: 'Test service terms and conditions - Enable Banking',
        description:
          'The test service refered in this Terms and Conditions is only for internal use in Enable Banking Oy, such as development and testing of Open Banking API integrations included into Enable Banking aggregation SDK.'
      }
    },
    {
      path: '/trademark-disclaimer',
      name: 'trademark-disclaimer',
      component: TrademarkDisclaimer,
      meta: {
        title: 'Trademark disclaimer - Enable Banking',
        description: 'Trademark disclaimer'
      }
    },
    {
      path: '/brand-guidelines',
      name: 'brand-guidelines',
      component: BrandGuidelines,
      meta: {
        title: 'Brand guidelines - Enable Banking',
        description: 'Brand guidelines'
      }
    },
    {
      path: '/careers/junior-software-engineer',
      name: 'careers-junior-software-engineer',
      component: CareersJuniorSoftwareEngineer,
      meta: {
        title: 'Junior Software Engineer - Careers - Enable Banking',
        description: 'Open position for Junior Software Engineer at Enable Banking Oy'
      }
    },
    {
      path: '/careers/technical-support-specialist',
      name: 'careers-technical-support-specialist',
      component: CareersTechnicalSupportSpecialist,
      meta: {
        title: 'Technical Support Specialist - Careers - Enable Banking',
        description: 'Open position for Technical Support Specialist at Enable Banking Oy'
      }
    }
  ]
})

const checkIsAuth = async (to, next) => {
  const user = await store.dispatch('getUser').catch((error) => console.log(error))
  
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const token = user && (await store.dispatch('getIdTokenResult'))

    if (token) {
      next()
    } else {
      next({
        name: 'sign-in',
        query: { next: to.fullPath }
      })
    }
  } else {
    next()
  }
}

router.beforeEach((to, _from, next) => {
  checkIsAuth(to, next)
})

export default router
