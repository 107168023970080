<template>
  <section class="container py-4">
    <router-link :to="{name: 'applications'}" class="mb-3 d-inline-flex text-decoration-none">
      <span class="material-icons-outlined">chevron_left</span> All applications
    </router-link>
    <div v-if="loading || app === null"><LoaderPic />Loading...</div>
    <div v-else-if="app">
      <h5 class="mb-4">
        <span class="mr-2">Removing application {{ app.kid }} ({{ app.environment }})</span>
      </h5>
      <p>
        Are you sure you want to remove application "{{ app.name }}"? This action can not be undone.
      </p>
      <p>
        In order to confirm application removal, please enter application id below.
      </p>
      <form class="mb-5" @submit.prevent="removeApp">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="Application ID"
                v-model="appId"
                required
                :disabled="removing"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <button class="btn btn-danger" type="submit" :disabled="removing">
              <span
                v-if="removing"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Remove
            </button>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoaderPic from '../components/elements/Loader/LoaderPic.vue'

export default {
  name: 'ApplicationsRemove',
  data() {
    return {
      app: null,
      appId: '',
      removing: false
    }
  },
  components: {
    LoaderPic
  },
  computed: {
    ...mapGetters(['applications', 'loading'])
  },
  async mounted() {
    if (!this.applications.length) {
      await this.getApplications()
    }
    const appId = this.$route.params.id
    this.app = this.applications.find(app => app.kid === appId)
    if (!this.app) {
      this.$router.push({ path: '/cp/applications' })
    }
  },
  methods: {
    ...mapActions(['getApplications', 'getIdTokenResult', 'pushAlertMessage', 'removeApplication']),
    async removeApp() {
      if (this.appId !== this.app.kid) {
        this.pushAlertMessage({ message: 'Application ID you entered does not match with ID of the application to be removed.' })
        return
      }
      try {
        this.removing = true
        const r = await this.removeApplication({ appId: this.appId })
        if (!r) {
          return
        }
        this.pushAlertMessage({ message: 'Application has been removed', type: 'info' })
        await this.getIdTokenResult(true)
        this.$router.push({ path: '/cp/applications' })
      } finally {
        this.removing = false
      }
    }
  }
}
</script>
